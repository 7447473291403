import { Button, Grid, Modal, Typography, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMemberStyles } from "./styles";
import AddMember from "./modal";
import { Delete, Edit } from "@material-ui/icons";
import { fetchTapMember, TapRoles, getBranch, disableMember } from "./logic";

/**
 *
 * @returns
 */
const TapMember = () => {
  const classes = useMemberStyles();
  const [modal, setModal] = useState(false);
  const [tapMember, setTapMember] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [initValue, setInitvalue] = useState({
    id: 0,
    email: "",
    phone: "",
    role: "TCC",
    branch: "NA",
    name: "",
    course: "NA",
  });

  /**
   * @methods
   */

  /**
   * @description handles modal close action
   * @param {*} e
   * @returns void
   */
  const handleModalClose = (e) => {
    setModal(false);
    return;
  };

  /**
   * @description handle modal open action
   * @param {*} e
   * @returns void
   */
  const handleModalOpen = (e) => {
    setInitvalue({
      id: 0,
      email: "",
      phone: "",
      role: "TCC",
      branch: "NA",
      name: "",
      course: "NA",
    });
    setModal(true);
    return;
  };

  /**
   * @description opens modal in edit mode
   * @param {*} idx
   * @returns
   */
  const handleEdit = (idx) => (e) => {
    const { id, email, name, course, branch, role, phone } = tapMember[idx];
    console.log({ id, email, name, course, branch, role, phone });
    setInitvalue({ id, email, phone, role, branch, name, course });
    setModal(true);
  };

  /**
   * @effects
   */

  useEffect(() => {
    fetchTapMember(setTapMember);
  }, []);

  console.log(tapMember);

  /**
   * @view
   */
  return (
    <>
      <div>
        <Typography component="p">
          <strong>TAP Members</strong>
        </Typography>
        <div>
          {tapMember?.map((tm, idx) => (
            <div key={tm?.id}>
              <Grid container className={classes.tapMemberCard} direction="row">
                <Grid xs={4} item>
                  <Typography>{tm?.name}</Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography>
                    <span>{TapRoles[tm?.role]}</span>
                  </Typography>
                  <Typography>
                    {tm?.role === "TCC" && (
                      <span>{getBranch(tm?.course, tm?.branch)}</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Grid item>
                      <IconButton onClick={handleEdit(idx)} disabled={apiCall}>
                        <Edit />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={apiCall}
                        onClick={disableMember(
                          tm?.id,
                          setTapMember,
                          setApiCall
                        )}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
        <Grid container direction="column">
          <Grid item>
            <Button
              disabled={apiCall}
              onClick={handleModalOpen}
              fullWidth
              variant="outlined"
              color="primary"
            >
              +&nbsp; Add new member
            </Button>
          </Grid>
        </Grid>
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        open={modal}
        className={classes.modal}
      >
        <div>
          <AddMember
            initValue={initValue}
            handleClose={handleModalClose}
            dataHandler={setTapMember}
          />
        </div>
      </Modal>
    </>
  );
};

export default TapMember;
