import styled from 'styled-components';

export const TagWrapper = styled.div`
    padding-inline: 4px;
    margin-inline: 2%;
`;

export const Tag = styled.div`
    padding: 2px 6px;
    border-style:'solid';
    border-width:0px;
    background-color:grey;
    border-radius:16px;
    color:#ffffff;
    font-size:12px;
`;

export const SuggestionWrapper = styled.div`
    min-width:128px;
    position: absolute;
    z-index: 1;
`;

export const tagIconStyle = {
    cursor:'pointer'
}