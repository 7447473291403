import React from "react";
// import logo from './logo.svg';
// import './App.css';
import LoginForm from "./Pages/LoginForm";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Admin from "./Pages/Admin/Admin";
import Faculty from "./Pages/Faculty/Faculty";
import Verify from "./Pages/Faculty/Verify";
import Reset from "./Pages/Faculty/Reset";
import { Lines } from "react-preloaders";

//for stylish alert box
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

function App() {
  return (
    <React.Fragment>
      <AlertProvider template={AlertTemplate} {...options}>
        <Router>
          <Switch>
            <Route exact path="/" component={LoginForm} />
            <Route path="/admin" component={Admin} />
            <Route exact path="/verify/:token" component={Verify} />
            <Route exact path="/reset/:token" component={Reset} />
            <Route path="/faculty" component={Faculty} />
          </Switch>
        </Router>
      </AlertProvider>
      <Lines />
    </React.Fragment>
  );
}

export default App;
