import React, { useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { Form, Button } from "semantic-ui-react";
import client from "../../helpers/Client";

function Responsibilities() {
  const [, setResult] = useState({
    success: 0,
    failure: 0,
  });
  const [, setMessage] = useState("");

  const [request] = useState(false);
  const [, setFetching] = useState(false);

  const [responsibilities, setResponsibilities] = useState("");
  const cookies = new Cookies();
  const token = cookies.get("token");
  const { uid } = JwtDecode(token);

  useEffect(() => {
    setFetching(true);
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { uid } = JwtDecode(token);
    let dest_url = `/api/people//responsibility?id=${uid}`;
    client
      .get(dest_url)
      .then((res) => {
        console.log(res.data);
        setResponsibilities(res.data[0].ds);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  // function handleSave() {
  //     console.log(responsibilities);
  //     console.log(uid)
  // }

  const handleSave = (e) => {
    e.preventDefault();
    var edited_data = {
      responsibilities: responsibilities,
      uid: uid,
    };
    let dest_url = "/faculty/responsibilities/edit";

    client
      .post(dest_url, edited_data)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0)
          setResult({
            failure: 1,
            success: 0,
          });
        else
          setResult({
            failure: 0,
            success: 1,
          });
        setMessage(res.data.message);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        alert("success");
      });
  };

  return (
    <div>
      <span>Write Content</span>
      <Form>
        <JoditEditor
          name="info"
          onChange={(newContent) => {
            setResponsibilities(newContent);
          }}
          value={responsibilities}
          tabIndex={1}
        />
        <Button onClick={handleSave} positive type="submit" fluid>
          Save
        </Button>
      </Form>
    </div>
  );
}

export default Responsibilities;
