import React, { useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Header, Modal } from "semantic-ui-react";

import {
  Grid,
  Form,
  Input,
  Icon,
  Select,
  Button,
  Card,
  Message,
} from "semantic-ui-react";
import { DateInput, YearInput } from "semantic-ui-calendar-react";
import {
  Card as AntCard,
  Button as AntButton,
  Skeleton,
  Tag,
  Result,
} from "antd";

import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

const publicationOptions = [
  { key: "bk", text: "Book", value: "Book" },
  {
    key: "bkc",
    text: "Book Chapters",
    value: "Book Chapters",
  },
  {
    key: "icp",
    text: "International Conference Paper",
    value: "International Conference Paper",
  },
  {
    key: "ijp",
    text: "International Journal Paper",
    value: "International Journal Paper",
  },
];

const emptyFormData = {
  author: "",
  date: "",
  journal_conference_name: "",
  link: "",
  title: "",
  type: "",
};

export const MySuccessMessage = ({ header, message }) => (
  <Message positive>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

const MyErrorMessage = ({ header, message }) => (
  <Message negative>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);
const Loading = () => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>Just one second</Message.Header>
      We are uploading data provided by you.
    </Message.Content>
  </Message>
);

const Upload = () => {
  const [data, setData] = useState(emptyFormData);
  const [visible, setVisible] = useState(false);
  const [flag, setFlag] = useState(false); // flag = true then Edit Article else Add new article
  // const [currentArticle, setCurrentArticle] = useState(null);
  const [articleContent, setArticleContent] = useState(null);

  const [result, setResult] = useState({
    success: 0,
    failure: 0,
  });
  const [message, setMessage] = useState("");
  const [publication, setPublication] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [attempted, setAttempted] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState("");
  const [Id, setId] = useState(null); // for Edit Publication
  const [image, setImage] = useState(null);
  const [imageCaption, setImageCaption] = useState("");

  const checkError = (data) => {
    if (!data.type) {
      // eslint-disable-next-line
      setError({ ["type"]: "Select Publication Type" });
      return true;
    }
    return false;
  };

  useEffect(() => {
    setFetching(true);
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { uid } = JwtDecode(token);
    let dest_url = "/api/publications?id=" + uid;

    client
      .get(dest_url)
      .then((res) => {
        console.log(res.data.result);
        setPublication(res.data.result);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const FetchArticleContent = (currentArticle) => {
    if (currentArticle) {
      setArticleContent(null);
      var id = currentArticle.id;
      let dest_url = "/api/publications/view?id=" + id;
      client
        .get(dest_url, publication)
        .then((res) => {
          console.log(res);
          console.log(res.data.result);
          setArticleContent(res.data.result);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setFetching(false);
          setRequest(!request);
        });
    }
  };
  const deletePublication = (publication) => {
    setFetching(true);
    let dest_url = "/faculty/publications/delete";
    client
      .post(dest_url, publication)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        console.log("DONE");
        setFetching(false);
        setRequest(!request);
      });
  };

  const setFieldValues = () => {
    //console.log(articleContent);
    var field_values = {
      author: articleContent.authors,
      date: articleContent.pub_date,
      journal_conference_name: articleContent.journal,
      link: articleContent.link,
      title: articleContent.title,
      type: articleContent.type,
      page_no: articleContent.page_no,
      volume: articleContent.volume,
    };
    setInfo(articleContent.info);
    setData(field_values);
    setId(articleContent.id);
  };

  const EditPublication = (data) => {
    if (!checkError(data)) {
      var edited_data = {
        author: data.author,
        link: data.link,
        date: data.date,
        title: data.title,
        type: data.type,
        journal_conference_name: data.journal_conference_name,
        page_no: data.page_no,
        volume: data.volume,
        info: info,
        id: Id,
      };
      setLoading(true);
      setAttempted(true);
      let dest_url = "/faculty/publications/edit";

      client
        .post(dest_url, edited_data)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0)
            setResult({
              failure: 1,
              success: 0,
            });
          else
            setResult({
              failure: 0,
              success: 1,
            });
          setMessage(res.data.message);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
          setAttempted(false);
          setData(emptyFormData);
          setInfo("");
          setFlag(false);
          setRequest(!request);
        });
    }
  };

  const handleUpload = async (image) => {
    const dest = `/api/publications/image-upload`;
    const body = new FormData();
    body.append("file", image);
    const data = await client.post(dest, body);
    return data.data.path;
  };

  const submit = async (data) => {
    let filePath;
    if (image) {
      filePath = await handleUpload(image);
    }
    console.log(filePath);
    if (!checkError(data)) {
      var submitting_data = {
        author: data?.author,
        link: data?.link,
        date: data?.date,
        title: data?.title,
        type: data?.type,
        journal_conference_name: data?.journal_conference_name,
        page_no: data?.page_no,
        volume: data?.volume,

        info: info,
        image: filePath,
        caption: imageCaption,
      };
      console.log(submitting_data);
      setLoading(true);
      setAttempted(true);
      let dest_url = "/faculty/publications";
      client
        .post(dest_url, submitting_data)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0)
            setResult({
              failure: 1,
              success: 0,
            });
          else
            setResult({
              success: 1,
              failure: 0,
            });
          setMessage(res.data.message);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
          setAttempted(false);
          setData(emptyFormData);
          setInfo("");
          setRequest(!request);
        });
      // console.log(submitting_data);
    }
  };

  return (
    <Grid columns={2} relaxed="very" stackable divided>
      <Grid.Column>
        <h1>{flag ? "Edit" : "Add"} Publication</h1>
        <Form
          onSubmit={() => (flag ? EditPublication(data) : submit(data))}
          loading={loading}
        >
          <Form.Group widths="equal">
            <Form.Field
              required
              control={Select}
              options={publicationOptions}
              label={{
                children: "Publication Type",
                htmlFor: "publication-type",
              }}
              placeholder="Publication Type"
              error={error.type}
              value={data.type}
              name="type"
              onChange={(e) => {
                setData({
                  ...data,
                  type: e.target.childNodes[0].textContent,
                });
                setError({});
              }}
            />
            <Form.Field
              required
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
            >
              <label>Authors</label>
              <Input
                name="author"
                value={data.author}
                placeholder="All authors"
                required
              />
            </Form.Field>
          </Form.Group>
          <Form.Field
            required
            control={Input}
            name="title"
            value={data.title}
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
            label={{ children: "Publication Title", htmlFor: "title" }}
            searchInput={{ id: "publication-type" }}
          />
          <Form.Field
            control={Input}
            name="link"
            value={data.link}
            placeholder="http://"
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
            label={{ children: "Link", htmlFor: "title" }}
            required
          />
          <Form.Group widths="equal">
            <Form.Field
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
            >
              <label>
                Journal/Conference Name<b style={{ color: "red" }}>*</b>
              </label>
              <Input
                name="journal_conference_name"
                value={data.journal_conference_name}
                placeholder="Journal/Conference Name"
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Publication Year</label>
              {data.type.includes("Conference") && (
                <DateInput
                  name="date"
                  iconPosition="left"
                  value={data.date}
                  onChange={(event, { name, value }) =>
                    setData({ ...data, [name]: value })
                  }
                />
              )}
              {!data.type.includes("Conference") && (
                <YearInput
                  name="date"
                  placeholder="Year"
                  iconPosition="left"
                  value={data.date}
                  onChange={(event, { name, value }) =>
                    setData({ ...data, [name]: value })
                  }
                />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Page No</label>
              <Input
                name="page_no"
                value={data.page_no}
                placeholder="Page No"
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Volume No</label>
              <Input
                name="volume"
                value={data.volume}
                placeholder="Volume"
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label htmlFor="image">Upload Image</label>
            <input
              type="file"
              name="image"
              id="image"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setImage(e.target.files[0]);
              }}
            />
            {image && (
              <img
                src={URL.createObjectURL(image)}
                alt="Publication"
                style={{ width: "100%", marginTop: "10px" }}
              ></img>
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="image-caption">Image Caption</label>
            <Input
              name="image-caption"
              value={data.caption}
              placeholder="Enter image caption"
              onChange={(e) => {
                setImageCaption(e.target.value);
              }}
            />
          </Form.Field>
          <span>Details</span>
          <JoditEditor
            name="info"
            value={info}
            tabIndex={1}
            onChange={(newContent) => {
              setInfo(newContent);
            }}
          />
          {attempted ? <Loading /> : null}
          {result.success ? (
            <MySuccessMessage header="Publication" message={message} />
          ) : null}
          {result.failure ? (
            <MyErrorMessage header="Publication" message={message} />
          ) : null}
          <Button positive type="submit" fluid>
            Save
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        {fetching && (
          <>
            <AntCard style={{ width: "100%", marginTop: 16 }}>
              <Skeleton loading={true} avatar active></Skeleton>
            </AntCard>
            <AntCard style={{ width: "100%", marginTop: 16 }}>
              <Skeleton loading={true} avatar active></Skeleton>
            </AntCard>
          </>
        )}
        {!fetching && (
          <>
            {publication.length ? (
              <h1 className="text-center mb-5">My Publications</h1>
            ) : (
              <Result title="No Publication Added" />
            )}
            <Card.Group>
              {publication.map((pub) => (
                <AntCard
                  className="ant-col ant-col-xs-24 ant-col-xl-8"
                  title={pub.journal}
                  key={pub.id}
                  style={{ width: 300 }}
                >
                  <p className="text-center">
                    <AntButton
                      type="primary"
                      className="fluid"
                      onClick={() => {
                        setVisible(true);
                        FetchArticleContent(pub);
                      }}
                    >
                      <i className="fad fa-eye"></i> Read Article
                    </AntButton>
                    {/* <AntButton
                      type="danger"
                      shape="circle"
                      style={{ float: "right" }}
                      onClick={() => {
                        if (window.confirm("Are You Sure?")) {
                          deletePublication(pub);
                        }
                      }}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </AntButton> */}
                    <br />
                    <br />
                    <b>Title:</b> {pub.title}
                    <br />
                    <b>Authors:</b> {pub.authors}
                    <br />
                    <b>Journal:</b> {pub.journal}
                    <br />
                    <b>Journal Type:</b> {pub.type}
                    <Button
                      basic
                      color="green"
                      fluid
                      compact
                      style={{ margin: "10px auto" }}
                      //style={{ position: "absolute", bottom: "15px" }}
                      onClick={() => {
                        setFlag(true);
                        setData(emptyFormData);
                        FetchArticleContent(pub);
                        if (articleContent) {
                          setFieldValues();
                        }
                      }}
                    >
                      <i class="fas fa-edit"></i> Edit
                    </Button>
                    <Button
                      negative
                      fluid
                      compact
                      onClick={() => {
                        if (window.confirm("Are You Sure?")) {
                          deletePublication(pub);
                        }
                      }}
                    >
                      <i class="fal fa-trash-alt"></i> Delete
                    </Button>
                  </p>
                </AntCard>
              ))}

              <Modal
                onClose={() => setVisible(false)}
                onOpen={() => setVisible(true)}
                open={visible}
                size="small"
              >
                <Header icon>
                  {articleContent ? articleContent.type : "------------"}
                </Header>
                <Modal.Content>
                  <Modal.Description>
                    {articleContent ? (
                      <>
                        <b>Title:</b> {articleContent.title}
                        <br />
                        <b>Date:</b> {articleContent.pub_date}
                        <br />
                        <b>Journal:</b> {articleContent.journal}
                        <br />
                        <b>Authors:</b>{" "}
                        {articleContent
                          ? articleContent.authors
                              .split(",")
                              .map((element) => (
                                <Tag color="default">{element}</Tag>
                              ))
                          : null}
                        <br />
                        {articleContent.link ? (
                          <a
                            href={articleContent.link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <b>Link:</b>{" "}
                            <i className="fal fa-external-link-alt"></i>
                          </a>
                        ) : null}
                        <hr />
                        <p
                          style={{
                            backgroundColor: "white",
                            maxWidth: "100%",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: articleContent.info,
                          }}
                        ></p>
                      </>
                    ) : (
                      <>
                        <AntCard style={{ width: "100%", marginTop: 16 }}>
                          <Skeleton loading={true} avatar active></Skeleton>
                        </AntCard>
                        <AntCard style={{ width: "100%", marginTop: 16 }}>
                          <Skeleton loading={true} avatar active></Skeleton>
                        </AntCard>
                      </>
                    )}
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="green"
                    inverted
                    onClick={() => setVisible(false)}
                  >
                    <Icon name="checkmark" /> Close
                  </Button>
                </Modal.Actions>
              </Modal>
            </Card.Group>
          </>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Upload;
