import React, { useState, useEffect } from "react";
import {
  Form,
  Grid,
  Input,
  Card,
  Loader,
  Button,
} from "semantic-ui-react";
import { Card as AntCard, Button as AntButton } from "antd";
import { DownSquareOutlined } from "@ant-design/icons";

//import moment from "moment";

import client from "../../helpers/Client";

const emptyData = {
  file_title: "",
  file: "",
};

export const Manual_Forms = () => {
  const [manualData, setManualData] = useState(emptyData);
  const [formData, setFormData] = useState(emptyData);
  const [type, setType] = useState(""); //is it manual or form
  const [publication, setPublication] = useState([]);
  // const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setFetching(true);
    let dest_url = "/api/manuals_forms/all";

    client
      .get(dest_url)
      .then((res) => {
        setPublication(res.data?.manuals);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const submit = (data) => {
    // if (!checkError(data)) {
    setLoading(true);

    let dest_url = `/user/manuals_forms/add`;
    const body = new FormData();
    body.append("file_title", data.file_title);
    body.append("file", data["file"]);
    body.append("type", type);

    client
      .post(dest_url, body)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setManualData(emptyData);
        setFormData(emptyData);
        setRequest(!request);
      });

    console.log(data);
    // }
  };

  const archivePublication = (publication) => {
    setLoading(true)
    let dest_url = "user/manuals_forms/archive"
    //console.log(banner)
    client
    .post(dest_url, publication)
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err))
    .finally(() => {
      setLoading(false)
      setRequest(!request)
    })
  }

  return (
    <div>
    <Grid columns={2} stackable divided>
      <Grid.Column padded>
        <h1>Add Manual</h1>
        <Form onSubmit={() => submit(manualData)} loading={loading}>
          <Form.Field
            required
            onChange={(e) => {
              setManualData({ ...manualData, [e.target.name]: e.target.value });
            }}
          >
            <label>Manual Title</label>
            <Input
              name="file_title"
              value={manualData.file_title}
              placeholder=""
              required
            />
          </Form.Field>

          <Form.Field
            onChange={(e) =>
              setManualData({ ...manualData, [e.target.name]: e.target.files[0] })
            }
          >
            <label>File</label>
            <Input
              type="file"
              value={manualData.link}
              name="file"
              placeholder="Manual File"
              required
            />
          </Form.Field>

          <Button onClick={() => setType("Manual")} positive type="submit" fluid>
            <i className="far fa-upload"></i> Upload Manual
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        {fetching && <Loader active inline="centered" size="massive" />}
        <h2>Manuals</h2>
        {!fetching && publication && (
          <Card.Group>
            {publication?.filter(file => file.type==="Manual").map((pub) => (
              <AntCard
                key={pub.id}
                title={pub.title}
                extra={
                  <a href={pub.file_path} target="_blank" rel="noopener noreferrer">
                    <i className="fal fa-external-link-alt"></i> View Manual
                  </a>
                }
                style={{
                  width: "100%",
                  marginTop: 5,
                  borderColor: "blue",
                  borderStyle: "dotted",
                }}
              >
              <AntButton
                type="primary"
                onClick={() => archivePublication(pub)}
                icon={<DownSquareOutlined />}
                size={"large"}
                >
                Archive Manual
                </AntButton>
              </AntCard>
            ))}
          </Card.Group>
        )}
      </Grid.Column>
    </Grid>
    <Grid columns={2} stackable divided style={{marginTop: "50px"}}>
      <Grid.Column padded>
        <h1>Add Form</h1>
        <Form onSubmit={() => submit(formData)} loading={loading}>
          <Form.Field
            required
            onChange={(e) => {
              setFormData({ ...formData, [e.target.name]: e.target.value });
            }}
          >
            <label>Form Title</label>
            <Input
              name="file_title"
              value={formData.file_title}
              placeholder=""
              required
            />
          </Form.Field>

          <Form.Field
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.files[0] })
            }
          >
            <label>File</label>
            <Input
              type="file"
              value={formData.link}
              name="file"
              placeholder="Form File"
              required
            />
          </Form.Field>
          {/* <Form.Field>
            <label>Form For:</label>
            <Input type="radio" name="entr" />
          </Form.Field> */}

          <Button onClick={() => setType("Form")} positive type="submit" fluid>
            <i className="far fa-upload"></i> Upload Form
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        {fetching && <Loader active inline="centered" size="massive" />}
        <h2>Forms</h2>
        {!fetching && publication && (
          <Card.Group>
            {publication?.filter(file => file.type==="Form").map((pub) => (
              <AntCard
                key={pub.id}
                title={pub.title}
                extra={
                  <a href={pub.file_path} target="_blank" rel="noopener noreferrer">
                    <i className="fal fa-external-link-alt"></i> View Form
                  </a>
                }
                style={{
                  width: "100%",
                  marginTop: 5,
                  borderColor: "blue",
                  borderStyle: "dotted",
                }}
              >
              <AntButton
                type="primary"
                onClick={() => archivePublication(pub)}
                icon={<DownSquareOutlined />}
                size={"large"}
                >
                Archive Form
                </AntButton>
              </AntCard>
            ))}
          </Card.Group>
        )}
      </Grid.Column>
    </Grid>
    </div>
  );
};
