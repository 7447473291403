import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Segment, Header, Icon, Button } from "semantic-ui-react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackBar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { sizeList } from "./const";
import { handleUpload, removeTag } from "./logic";
import Tags from "./tags";
import Image from "../../../components/Image/index";
import { Tag, tagIconStyle } from "./styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "flex",
    flexDirection: "row",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tags: {
    display: "flex",
    flexDirection: "row",
    marginLeft: theme.spacing(1),
  },
}));

const Gallery = () => {
  const classes = useStyles();
  const [size, setSize] = useState("banner");
  const [file, setFile] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    text: " ",
    type: "info",
  });
  const [backdrop, setBackdrop] = useState(false);
  const [tags, setTags] = useState([{ id: "gallery", text: "gallery" }]);
  const fileRef = React.useRef();

  const handleFileSelect = () => {
    const files = fileRef.current.files;

    if (files && files.length > 10) {
      setSnackbar({
        open: true,
        text: "Can't upload more than 10 files at once",
        type: "error",
      });
      fileRef.current.value = ""; // reset the file input
    }
    setFile(files);
    // console.log(file)
  };

  return (
    <Container disableGutters={true}>
      {/* {console.log(size)} */}
      <Segment>
        <Header as="h2">
          <Icon name="image outline" />
          <Header.Content>
            Gallery upload
            <Header.Subheader>Upload an image...</Header.Subheader>
          </Header.Content>
        </Header>
        <Segment>
          <FormControl className={classes.formControl}>
            <div>
              <InputLabel id="size_label">Image size</InputLabel>
              <Select
                id="select_size"
                value={size}
                labelId="size_label"
                onChange={(event) => setSize(event.target.value)}
              >
                {sizeList.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <Tags tags={tags} setTags={setTags} />
            </div>
            <Grid container spacing={1} className={classes.tags}>
              {tags.map((tag, index) => (
                <Grid item key={tag.id}>
                  <Tag>
                    {tag.text}&nbsp;
                    <Icon
                      onClick={removeTag(tags, setTags, index)}
                      style={tagIconStyle}
                      name="x"
                    />
                  </Tag>
                </Grid>
              ))}
            </Grid>
          </FormControl>

          <Segment placeholder>
            {/* {file && <Image alt="your image here"  src={URL.createObjectURL(file)}/>} */}
            <Header icon>
              {file === null && <Icon name="image" />}
              {file ? file.name : "no image selected"}
            </Header>
            <input
              hidden
              ref={fileRef}
              type="file"
              id="file"
              name="file"
              multiple
              onChange={handleFileSelect}
            />
            <Button
              primary
              type="button"
              onClick={() => {
                fileRef.current.click();
              }}
            >
              {file ? `change image` : `select image`}
            </Button>
          </Segment>
          <Button
            onClick={() => {
              handleUpload(file, size, tags, { setSnackbar, setBackdrop });
            }}
            primary
            fluid
          >
            Upload
          </Button>
        </Segment>
      </Segment>
      <SnackBar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbar.open}
        onClose={() => {
          setSnackbar({ open: false, text: " ", type: snackbar.type });
        }}
      >
        <Alert severity={snackbar.type}>{snackbar.text}</Alert>
      </SnackBar>
      <Backdrop
        className={classes.backdrop}
        open={backdrop}
        onClick={() => {
          setSnackbar({
            open: true,
            text: "please wait till image uploads!",
            type: "info",
          });
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Container>
  );
};

export default Gallery;
