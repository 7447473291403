import React, { Component } from "react";
import { Table, Container, Button } from "semantic-ui-react";
import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { Skeleton } from "antd";
const cookies = new Cookies();

const LogCard = (props) => {
  return (
    <Table.Row>
      <Table.Cell>{props.user.ip}</Table.Cell>
      <Table.Cell>{props.user.email}</Table.Cell>
      <Table.Cell>{props.user.message}</Table.Cell>
      <Table.Cell>{props.user.change_type}</Table.Cell>
      <Table.Cell>{props.user.timestamp}</Table.Cell>
    </Table.Row>
  );
};

export default class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_log_count: "",
      new_email: "",
      log_data: [],
      loading: true,
      button_loader: false,
    };
    this.fetch_admin_log = this.fetch_admin_log.bind(this);
    this.fetch_user_log = this.fetch_user_log.bind(this);
  }

  componentDidMount() {
    this.fetch_admin_log();
  }
  async fetch_admin_log() {
    try {
      var { user } = JwtDecode(cookies.get("token"));
      const response = await client.post("/user/log", {
        email: user,
        log_count: "50",
      });

      if (response.data.status) {
        this.setState({ log_data: response.data.response });
      } else {
        alert(response.data.message);
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
      alert("Loading Failed");
    }

    return false;
  }
  fetch_user_log = async () => {
    try {
      var email = this.state.new_email;
      var new_log_count = this.state.new_log_count;
      if (!email || !new_log_count) {
        alert("Missing Required Field");
        return false;
      }
      this.setState({ loading: true, log_data: [] });
      const response = await client.post("/user/log", {
        email: email,
        log_count: new_log_count,
      });
      console.log(response.data.status);
      if (response.data.status) {
        this.setState({ log_data: response.data.response });
      } else {
        alert(response.data.message);
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
      alert("Loading Failed");
    }

    return false;
  };
  render() {
    return (
      <Container>
        <br />
        <br />
        <Table celled fixed color="green">
          <Table.Header style={{ maxWidth: "150px" }}>
            <Table.Row>
              <Table.HeaderCell>
                <div className="ui fluid input">
                  <input
                    type="number"
                    placeholder="No. Of Logs"
                    onChange={(e) => {
                      this.setState({ new_log_count: e.target.value });
                    }}
                    value={this.state.new_faculty_id}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                <div className="ui fluid input">
                  <input
                    type="text"
                    placeholder="Email"
                    onChange={(e) => {
                      this.setState({ new_email: e.target.value });
                    }}
                    value={this.state.new_faculty_id}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button
                  color="blue"
                  loading={this.state.button_loader}
                  onClick={this.fetch_user_log}
                >
                  View Log
                </Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>IP</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {!this.state.loading ? (
            <Table.Body>
              {this.state.log_data &&
                this.state.log_data.map(function (log_data, i) {
                  return <LogCard user={log_data} key={i} />;
                })}
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="5">
                  <Skeleton />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Container>
    );
  }
}
