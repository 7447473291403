import React from "react";
import Cookies from "universal-cookie";
import Box from "@material-ui/core/Box";

import { Redirect, Route, Switch } from "react-router-dom";

import Sidebar from "../../components/Sidebar/faculty_sidebar";
import { Profile } from "./Profile";
import Log from "./log";
import JwtDecode from "jwt-decode";
import useStyles from "../Admin/Style";
import Header from "../../components/Header/Header";
import Copyright from "../../components/Copyright/Copyright";
// import Upload from "./Notes";
import Publication from "./Publication";
import Responsibilities from "./Responsibilities";
import WorkExperience from "./WorkExperience";
import Course from "./Course";
import { DashBoard } from "./DashBoard";
import { PhoneBook } from "./PhoneBook";
import OtherActivities from "./OtherActivities";
import Awards from "./Awards";
import ChangePassword from "./ChangePassword";
import ThesisSupervised from "./ThesisSupervised";
const cookies = new Cookies();

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!cookies.get("token")) {
    return <Redirect to="/" />;
  }
  var { groupName } = JwtDecode(cookies.get("token"));
  if (groupName === "admin") {
    return <Redirect to="/admin" />;
  }

  return (
    <div className={classes.root}>
      <Header drawerOpen={handleDrawerOpen} open={open} />
      <Sidebar close={handleDrawerClose} open={open} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route path="/faculty/" exact component={DashBoard} />
          <Route path="/faculty/profile" component={Profile} />
          {/* <Route path="/faculty/upload" component={Upload} /> */}
          <Route path="/faculty/publication" component={Publication} />
          <Route
            path="/faculty/thesissupervised"
            component={ThesisSupervised}
          />
          <Route path="/faculty/workexperience" component={WorkExperience} />
          <Route path="/faculty/course" component={Course} />
          <Route path="/faculty/phonebook" component={PhoneBook} />
          <Route path="/faculty/awards" component={Awards} />
          <Route path="/faculty/changePassword" component={ChangePassword} />

          <Route
            path="/faculty/responsibilities"
            component={Responsibilities}
          />

          <Route path="/faculty/otherActivities" component={OtherActivities} />
        </Switch>
        <Switch>
          <Route path="/faculty/logs" component={Log} />
        </Switch>
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}
