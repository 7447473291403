import React, { useState, useEffect } from 'react'
import {
    Segment,
    Form, Grid, Input, Button, Card, Loader, Dropdown, TextArea
} from 'semantic-ui-react'

import client from '../../helpers/Client';

const emptyFormData = {
    prename:"",
    fname:"",
    lname:"",
    batch:"",
    description:"",
    designation:"",
    image:"",
    dept:"",
}

const branches = [
    { key: '1', text: 'mca', value: 'CA' },
    { key: '2', text: 'chem', value: 'CH' },
    { key: '3', text: 'civil', value: 'CE' },
    { key: '4', text: 'cs', value: 'CS' },
    { key: '5', text: 'ece', value: 'EC' },
    { key: '6', text: 'eee', value: 'EE' },
    { key: '7', text: 'humanities', value: 'HU' },
    { key: '8', text: 'maths', value: 'MA' },
    { key: '9', text: 'mech', value: 'ME' },
    { key: '10', text: 'meta', value: 'MM' },
    { key: '11', text: 'phys', value: 'PH' },
    { key: '12', text: 'prod', value: 'PI' },
];

const Testimonial = () => {
    const [data, setData] = useState(emptyFormData);
    const [testimonial, setTestimonial] = useState([]);
    // const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(false);
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        setFetching(true);
        let dest_url = `/api/testimonial`;

        client.get(dest_url)
            .then((res) => {
                setTestimonial(res.data.data);
                console.log(res.data.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setFetching(false);
            });
    }, [request]);

    const submit = (data) => {
        setLoading(true);

        let dest_url = `/user/testimonial`;

        const body = new FormData();
        body.append('prename', data.prename);
        body.append('fname', data.fname);
        body.append('lname', data.lname);
        body.append('dept',data.branch);
        body.append('description', data.description);
        body.append('batch',data.batch);
        body.append('designation',data.designation);
        body.append('file',data['image']);

        console.log('testimonial',data)
        client.post(dest_url, body)
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
                setData(emptyFormData);
                setRequest(!request);
            });

        console.log(data);
    }

    return (
        <Segment>
            <Segment>
                <Grid columns={2} padded>
                    <Grid.Column padded >
                        <h1>Add Testimonial</h1>
                        <Form onSubmit={() => submit(data)} loading={loading}>

                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>Prename</label>
                                <Input name="prename" value={data.prename} placeholder='' required />
                            </Form.Field>

                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>First Name</label>
                                <Input name="fname" value={data.fname} placeholder='' required />
                            </Form.Field>
                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>Last Name</label>
                                <Input name="lname" value={data.lname} placeholder='' required />
                            </Form.Field>
                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>Designation</label>
                                <Input name="designation" value={data.designation} placeholder='' required />
                            </Form.Field>

                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>Batch</label>
                                <Input name="batch" value={data.batch} placeholder='' required />
                            </Form.Field>
                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>Description</label>
                                <TextArea rows={5} name="description" value={data.description} placeholder='Write Here ...' required />
                            </Form.Field>
                            <Form.Field
                                required>
                                <label>Branch</label>
                                <Dropdown onChange={(e,val) => {
                                    setData({ ...data, 'branch': val.value })
                                }}
                                    name="dept"
                                    placeholder='Branch' search selection options={branches} />
                            </Form.Field>
                            

                            <Form.Field
                                onChange={e => {
                                    let file_type = e.target.files[0].type
                                    if(file_type === "image/jpeg" || file_type === "image/jpg" || file_type === "image/png")
                                    setData({ ...data, [e.target.name]: e.target.files[0] })
                                    else
                                    alert("The Image should end with jpg/jpeg/png extension")
                                }}
                            >
                                <label>Image</label>
                                <Input type="file" value={data.link} name="image" placeholder='Image' required />
                            </Form.Field>

                            <Button positive type="submit" fluid><i className="far fa-upload"></i> Add Testimonial</Button>
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        {
                            fetching && <Loader active inline='centered' size="massive" />
                        }
                        {
                            !fetching && testimonial && (
                                <Card.Group>
                                    {testimonial.map(pub => (
                                        <Card key={pub.id} fluid>
                                            <Card.Content>
                                                <Card.Header>Testimonial</Card.Header>
                                                <Card.Description style={{display:'flex'}}>
                                                    <img src={pub.profile} width="100" height="100" alt={pub.profile} />
                                                    <div style={{paddingLeft:'10px'}}>
                                                        <p>{pub.prename}{" "}{pub.fname}{" "}{pub.lname}</p>
                                                        <p>Batch : {pub.batch}</p>
                                                        <p>Department: {pub.department}</p>
                                                        <p>Description: {pub.description}</p>
                                                    </div>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    ))}
                                </Card.Group>
                            )
                        }
                    </Grid.Column>
                </Grid>
                {/* <Divider vertical><i className="far fa-2x fa-book"></i></Divider> */}
            </Segment>
        </Segment>
    )
}

export default Testimonial