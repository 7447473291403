import React, { useState, useEffect } from 'react'
import {
    Segment,
    Form, Grid, Input, Button, Card, Loader
} from 'semantic-ui-react'

import client from '../../helpers/Client';

const project_type = [
    { key: '1', text: 'Externally-Funded', value: 'Externally-Funded' },
    { key: '2', text: 'NID Projects', value: 'NID Projects' },
    { key: '3', text: 'T&C Projects', value: 'T&C Projects' },
];
const status = [
    { key: '1', text: 'ongoing', value: 'ongoing' },
    { key: '2', text: 'completed', value: 'completed' },
    { key: '3', text: 'submitted', value: 'submitted' },
];
const emptyFormData = {
    project_type: "",
    title: "",
    funding_agency: "",
    period: "",
    amount: "",
    status: "",
    affiliations: "",
}

const ResearchProjects = () => {

    const [data, setData] = useState(emptyFormData);
    const [projects, setProjects] = useState([]);
    // const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(false);
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        setFetching(true);
        let dest_url = `/api/projects`;

        client.get(dest_url)
            .then((res) => {
                setProjects(res.data.result);
                console.log(res.data.result)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setFetching(false);
            });
    }, [request]);

    const submit = (data) => {
        setLoading(true);

        let dest_url = `/user/research_projects`;

        const body = new FormData();
        body.append('project_type', data['project_type'])
        body.append('title', data['title'])
        body.append('funding_agency', data['funding_agency'])
        body.append('period', data['period'])
        body.append('amount', data['amount'])
        body.append('status', data['status'])
        body.append('affiliations', data['affiliations'])


        console.log('project', data)

        client.post(dest_url, body)
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
                setData(emptyFormData);
                setRequest(!request);
            });

        console.log(data);
    }

    return (
        <Segment>
            <Grid columns={2} padded>
                <Grid.Column >
                    <h1>Add Research Project</h1>
                    <Form onSubmit={() => submit(data)} loading={loading}>

                        <Form.Select
                            fluid
                            label="Project Type"
                            name="project_type"
                            options={project_type}
                            placeholder="Project type"
                            onChange={(e) => {
                                setData({ ...data, project_type: e.target.childNodes[0].textContent });
                                console.log(data);
                            }}
                        />
                        <Form.Field
                            required
                            onChange={(e) => {
                                setData({ ...data, [e.target.name]: e.target.value })
                            }}>
                            <label>Title</label>
                            <Input name="title" value={data.title} placeholder='' required />
                        </Form.Field>

                        <Form.Field
                            required
                            onChange={(e) => {
                                setData({ ...data, [e.target.name]: e.target.value })
                            }}>
                            <label>Funding Agency</label>
                            <Input name="funding_agency" value={data['funding_agency']} placeholder='' required />
                        </Form.Field>
                        <Form.Field
                            required
                            onChange={(e) => {
                                setData({ ...data, [e.target.name]: e.target.value })
                            }}>
                            <label>Period</label>
                            <Input name="period" value={data.period} placeholder='' required />
                        </Form.Field>
                        <Form.Field
                            required
                            onChange={(e) => {
                                setData({ ...data, [e.target.name]: e.target.value })
                            }}>
                            <label>Amount</label>
                            <Input name="amount" value={data.amount} placeholder='' required />
                        </Form.Field>

                        <Form.Field
                            required
                            onChange={(e) => {
                                setData({ ...data, [e.target.name]: e.target.value })
                            }}>
                            <label>Affiliations</label>
                            <Input name="affiliations" value={data.affiliations} placeholder='' required />
                        </Form.Field>
                        <Form.Select
                            fluid
                            label="Status"
                            name="status"
                            options={status}
                            placeholder="Status"
                            onChange={(e) => {
                                setData({ ...data, [e.target.name]: e.target.childNodes[0].textContent });
                            }}
                        />
                        <Button positive type="submit" fluid><i className="far fa-upload"></i> Add Project</Button>
                    </Form>
                </Grid.Column>
                <Grid.Column style={{ maxHeight: '100vh !important', overflow: 'auto' }}>
                    {
                        fetching && <Loader active inline='centered' size="massive" />
                    }
                    {
                        !fetching && projects && (
                            <Card.Group>
                                {projects.map(p => (
                                    <Card key={p.id} fluid>
                                        <Card.Content>
                                            <Card.Header>{p.title}</Card.Header>
                                            <Card.Description style={{ display: 'flex' }}>
                                                <div style={{ paddingLeft: '10px' }}>
                                                    <p>Type : {p.project_type}</p>
                                                    <p>Affiliations: {p.affiliations}</p>
                                                    <p>Status: {p.status}</p>
                                                </div>
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                ))}
                            </Card.Group>
                        )
                    }
                </Grid.Column>
            </Grid>
            {/* <Divider vertical><i className="far fa-2x fa-book"></i></Divider> */}
        </Segment>
    )
}

export default ResearchProjects;