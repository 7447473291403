import React from "react";
import { Grid } from "@material-ui/core";
import TapMember from "./member";
import TapStats from "./stats";

const TnpCell = () => {
  return (
    <div>
      <Grid container direction="row" spacing={2}>
        <Grid xs={6} item>
          <TapMember />
        </Grid>
        <Grid xs={6} item>
          <TapStats />
        </Grid>
      </Grid>
    </div>
  );
};

export default TnpCell;
