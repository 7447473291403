import React, { useState, useEffect } from "react";
import ImageUploader from "react-images-upload";
import { Segment, Form, Grid, Button, Card, Loader } from "semantic-ui-react";
import { MyErrorMessage } from "../../components/AlertMessage";
import { Image as AntImage } from "antd";
import { BACKEND_URL } from "../../helpers/constants";
import moment from "moment";
import client from "../../helpers/Client";
import { removeDoubleSlashes } from "../../helpers/urlhelper";
const MAX_GALLERY_IMAGE = 10;


export const Banner = () => {
  const [banner, setBanner] = useState([]);
  const [allowUpload, setAllowUpload] = useState(false);
  const [pictureError, setPictureError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectedPic, setSelectedPic] = useState(null);
  const onSelect = (picture, pictureDataURLs) => {
    if (!picture.length) {
      setSelectedPic(null);
      setAllowUpload(false);
      return;
    }
    console.log(pictureDataURLs[0]);
    var image = new Image();
    // Set the Base64 string return from FileReader as source.
    image.src = pictureDataURLs[0];
    // Validate the File Height and Width.
    image.onload = function () {
      var ratio = this.width / this.height;
      if (ratio>= 1 && ratio <=3) {
        setSelectedPic(picture[0]);
        setPictureError(null);
        setAllowUpload(true);
      } else {
        setPictureError("Please Choose a Square size image");
        alert("Please Choose a Square image");
        return false;
      }
    };
  };
  useEffect(() => {
    setFetching(true);
    let dest_url = "/api/banner";
    client
      .get(dest_url)
      .then((res) => {
        console.log(res.data);
        setBanner(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const uploadBanner = () => {
    if (selectedPic) {
      setLoading(true);
      let dest_url = `/user/banner/add`;
      let body = new FormData();
      body.append("file", selectedPic);
      client
        .post(dest_url, body)
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
          setSelectedPic(null);
          setAllowUpload(false);
          setRequest(!request);
        });

      console.log(selectedPic);
      console.log(selectedPic.name);
      //setSelectedPic(null);
    } else {
      console.log("please Select Image");
    }
  };

  const deleteBanner = (banner) => {
    setLoading(true)
    let dest_url = "user/banner/delete"
    client
      .post(dest_url, banner)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false)
        setRequest(!request)
      })
  }

  return (
    <Segment>
      <Segment>
        <Grid columns={2} padded>
          <Grid.Column padded>
            <h1>Add Banner</h1>
            <Form loading={loading}>
              <Form.Field>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose image"
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={2097152} // 2MB
                  singleImage={true}
                  onChange={onSelect}
                  withPreview={allowUpload}
                  label="Max File Size: 2 MB || Allowed type is .jpg, .png, .jpeg"
                  fileSizeError="Max. File size is 1 MB"
                  fileTypeError="Invalid Image Type"
                />
              </Form.Field>
              {pictureError ? (
                <MyErrorMessage header="Error" message={pictureError} />
              ) : null}
              <Button
                positive
                type="submit"
                fluid
                disabled={!allowUpload}
                onClick={() => {
                  if (banner.length >= MAX_GALLERY_IMAGE) {
                    window.alert(`Only ${MAX_GALLERY_IMAGE} Banner Images will be displayed, delete any image to view the recently uploaded one.`);
                  } else {
                    uploadBanner()
                  }
                }}
              >
                <i className="far fa-upload"></i> Upload Banner Image
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column>
            {fetching && <Loader active inline="centered" size="massive" />}
            {!fetching && banner && (
              <Card.Group className="row mx-auto">
                {banner.filter((_, index) => index <= MAX_GALLERY_IMAGE)
                  .map(bannerImg => (
                    <Card
                      key={bannerImg.id}
                      style={{ width: 250 }}
                      className="mx-auto text-center"
                    >
                      <Card.Content>
                        <center>
                          <AntImage
                            className="mx-auto"
                            src={removeDoubleSlashes(`${BACKEND_URL}/${bannerImg.image}`)}
                            style={{height:'100px'}}
                          />
                          <Card.Description style={{ margin: "15px auto" }}>
                            <p>
                              {moment
                                .unix(bannerImg.timestamp / 1000)
                                .format("MMMM Do YYYY, h:mm:ss A")}
                            </p>
                          </Card.Description>
                          <Button
                            negative
                            fluid
                            onClick={() => {
                              if (window.confirm("Are You Sure?")) {
                                deleteBanner(bannerImg);
                              }
                            }}>
                            Delete
                          </Button>
                        </center>
                      </Card.Content>
                    </Card>
                  ))}
              </Card.Group>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  );
};
