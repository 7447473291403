import React, { Component } from 'react';
import { Table, Loader, Dimmer, Container, Dropdown, Button } from 'semantic-ui-react'
import client from '../../helpers/Client';

const department_list = [
    { text: 'Select Department', value: '' },
    { text: 'Students Welfare', value: 'Students Welfare' },
    { text: 'Academic', value: "Academic" },
    { text: 'Industry and Alumni Relations', value: 'Industry and Alumni Relations' },
    { text: 'Academic: Recognition and Accreditation', value: 'Academic: Recognition and Accreditation' },
    { text: 'Planning and Development', value: 'Planning and Development' },
    { text: 'Faculty welfare', value: 'Faculty welfare' },
    { text: 'Research and Consultancy', value: 'Research and Consultancy' },
]
const designation_list = [
    { text: 'Select Designation', value: '' },
    { text: 'Dean', value: 'Dean' },
    { text: 'Associate Dean', value: "Associate Dean" },
]

const DeanCard = (props) => {

    const { faculty_id, committee, designation, email, modified_at  } = props.user
    
    const handleDelete = async () => {
        const confirmed = window.confirm(`Are you sure you want to remove ${faculty_id} from ${designation}?`);
        if (confirmed) {
            // Perform delete operation here
            props.onDelete({
                department: committee,
                designation: designation,
                faculty_id: faculty_id
            });
            // console.log(props.user);
        }
    };

    return (
        <Table.Row>
            <Table.Cell>{(faculty_id)}</Table.Cell>
            <Table.Cell>{(committee)}</Table.Cell>
            <Table.Cell>{designation}</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell>{(modified_at).slice(0, 19)}</Table.Cell>
            <Table.Cell><Button color='red' onClick={handleDelete}>Delete</Button></Table.Cell>
        </Table.Row>

    )
}

export default class Dean extends Component {

    state = {
        new_designation: '',
        new_department: '',
        new_faculty_id: '',
        new_email: '',
        dean_data: '',
        loading: true,
        button_loader: false
    };

    async fetch_users() {
        try {
            const response = await client.post('/user/dean', {
                page: 1
            });
            this.setState({ dean_data: response.data.response })
            this.setState({ loading: false })

        } catch (err) {
            this.setState({ loading: false })
            console.log(err);
            alert('Loading Failed')
        }

        return false;
    }
    addDean = async (event, { value }) => {
        let faculty_id = this.state.new_faculty_id
        let designation = this.state.new_designation
        let email = this.state.new_email
        let department = this.state.new_department

        console.log(faculty_id)
        console.log(designation)
        console.log(email)
        console.log(department)
        if (!faculty_id || !designation || !email || !department) {
            alert("Missing Required Field");
            return false
        } else {
            this.setState({ button_loader: true })
            let new_response = await client.post('/user/dean/add', {
                "faculty_id": faculty_id,
                "designation": designation,
                "department": department,
                "email": email
            });
            if (new_response.data.status) {
                this.setState({ button_loader: false })
                alert("Dean Added")
                this.setState({ dean_data: new_response.data.response, new_faculty_id: '', new_email: '' })
            } else {
                alert(new_response.data.message)
                this.setState({ button_loader: false })
            }
            console.log(new_response);
        }

    }

    deleteDean = async (data) => {
        this.setState({ button_loader: true })
        let new_response = await client.post('/user/dean/delete', data);
        if (new_response.data.status) {
            this.setState({ button_loader: false })
            alert("Dean Deleted")
            this.setState({ dean_data: new_response.data.response, new_faculty_id: '', new_email: '' })
        } else {
            alert(new_response.data.message)
            this.setState({ button_loader: false })
        }
        console.log(new_response);
    }

    render() {
        if (this.state.loading) {
            this.fetch_users()
            return (

                <Dimmer active>
                    <Loader />
                </Dimmer>
            )
        }
        else
            return (

                <Container >
                    <br />
                    <br />
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell >
                                    <div className="ui  fluid input"><input type="text" placeholder="Faculty Id" onChange={e => { this.setState({ new_faculty_id: e.target.value }) }} value={this.state.new_faculty_id} /></div>
                                </Table.HeaderCell>
                                <Table.HeaderCell >
                                    <Dropdown  fluid placeholder='Department' clearable search selection options={department_list} onChange={(event, { value }) => { this.setState({ new_department: value }) }} />
                                </Table.HeaderCell>
                                <Table.HeaderCell >
                                    <Dropdown  fluid placeholder='Designation' clearable search selection options={designation_list} onChange={(event, { value }) => { this.setState({ new_designation: value }) }} />
                                </Table.HeaderCell>
                                <Table.HeaderCell >
                                    <div className="ui input"><input type="text" placeholder="Email" onChange={e => { this.setState({ new_email: e.target.value }) }} value={this.state.new_email} /></div>   
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>
                                    <Button color='blue' loading={this.state.button_loader} onClick={this.addDean} floated="right">Add/Edit Dean</Button>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Faculty ID</Table.HeaderCell>
                                <Table.HeaderCell>Department</Table.HeaderCell>
                                <Table.HeaderCell>Designation</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Last Modification Date</Table.HeaderCell>
                                <Table.HeaderCell>Delete</Table.HeaderCell> 
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                this.state.dean_data && this.state.dean_data.map( (dean_data, i) => {
                                    return <DeanCard user={dean_data} key={i} onDelete={this.deleteDean} />
                                })
                            }
                        </Table.Body>
                    </Table>
                </Container>

            );
    }
}

