import React, { useState } from "react";
import {
  Container,
  Header,
  Segment,
  Icon,
  Form,
  Button,
} from "semantic-ui-react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BACKEND_URL } from "../../helpers/constants";
import { useAlert } from "react-alert";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { Loading, MyErrorMessage } from "../../components/AlertMessage";
import { removeDoubleSlashes } from "../../helpers/urlhelper";

const cookies = new Cookies();

const validationSchema = Yup.object({
  email: Yup.string().required("Required").max(100, "Max 100 Characters"),
  password: Yup.string().required("Required").max(100, "Max 100 Characters"),
  uid: Yup.string().required("Required").max(100, "Max 100 Characters"),
});

const AddUser = () => {
  const alert = useAlert();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [attempted, setAttempt] = useState(0);
  const [result, setResult] = useState({
    success: 0,
    failure: 0,
  });

  function goBack() {
    history.push("users");
  }
  return (
    <Container>
      <Segment>
        <Button secondary size="small" onClick={goBack}>
          <i className="fad fa-arrow-circle-left"></i> Go Back
        </Button>
        <Header as="h2">
          <Icon name="user" />
          <Header.Content>
            Add User
            <Header.Subheader>Add A User...</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment>
        <Formik
          initialValues={{
            username: "",
            email: "",
            password: "",
            uid: "",
            mobile: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);

            var token = cookies.get("token");
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `token ${token}`);
            myHeaders.append(
              "Content-Type",
              "application/x-www-form-urlencoded"
            );

            var urlencoded = new URLSearchParams();
            urlencoded.append("email", data.email);
            urlencoded.append("password", data.password);
            urlencoded.append("uid", data.uid);
            urlencoded.append("username", data.email);
            urlencoded.append("mobile", data.mobile);
            urlencoded.append("groupname", "faculty");

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: urlencoded,
              redirect: "follow",
            };
            setAttempt(1);
            setResult({
              failure: 0,
              success: 0,
            });
            // return;
            fetch(removeDoubleSlashes(`${BACKEND_URL}/user/adduser`), requestOptions)
              .then((response) => response.json())
              .then((response) => {
                setAttempt(0);
                if (response.status === 1) {
                  alert.success(response.message);
                  history.push("/admin/users");
                } else {
                  setResult({
                    failure: 1,
                    success: 0,
                  });
                  setMessage(response.message);
                }
              })
              .then(() => setSubmitting(false))
              .catch((error) => console.log("error", error));
          }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => (
            <Form onSubmit={handleSubmit}>
              <label>User Detail</label>
              <br />
              <Segment>
                <Field
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  as={Form.Input}
                />
                <ErrorMessage
                  name="email"
                  header="Email is required"
                  message={errors.title}
                  component={MyErrorMessage}
                />
                <Field
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                  as={Form.Input}
                />
                <ErrorMessage
                  name="password"
                  header="Password is required"
                  message={errors.title}
                  component={MyErrorMessage}
                />
                <Field
                  name="uid"
                  label="Faculty ID"
                  placeholder="UID"
                  type="input"
                  as={Form.Input}
                />
                <ErrorMessage
                  name="uid"
                  header="UID is required"
                  message={errors.title}
                  component={MyErrorMessage}
                />
                <Field
                  name="mobile"
                  label="Mobile"
                  placeholder="Mobile"
                  pattern="[0-9]{10}"
                  type="input"
                  as={Form.Input}
                />
              </Segment>
              {attempted ? <Loading /> : null}
              {result.failure ? (
                <MyErrorMessage header="Error" message={message} />
              ) : null}
              <Segment>
                <Form.Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  <i className="fad fa-user-plus"></i> Add User
                </Form.Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  );
};

export default AddUser;
