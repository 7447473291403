import React, { useState } from 'react';
import { Container, Header, Icon, Segment, Form, Message } from 'semantic-ui-react';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import client from '../../helpers/Client';

const branches = [
    { key: '1', text: 'mca', value: 'CA' },
    { key: '2', text: 'chem', value: 'CH' },
    { key: '3', text: 'civil', value: 'CE' },
    { key: '4', text: 'cs', value: 'CS' },
    { key: '5', text: 'ece', value: 'EC' },
    { key: '6', text: 'eee', value: 'EE' },
    { key: '7', text: 'humanities', value: 'HU' },
    { key: '8', text: 'maths', value: 'MA' },
    { key: '9', text: 'mech', value: 'ME' },
    { key: '10', text: 'meta', value: 'MM' },
    { key: '11', text: 'phys', value: 'PH' },
    { key: '12', text: 'prod', value: 'PI' },
];


export const MySuccessMessage = ({ header, message }) => (
    <Message positive>
        <Message.Header>{header}</Message.Header>
        <p>{message}</p>
    </Message>
);

const Loading = () => (
    <Message icon>
        <Icon name='circle notched' loading />
        <Message.Content>
            <Message.Header>Just one second</Message.Header>
    We are Submitting the Notice.
    </Message.Content>
    </Message>
)

const MyErrorMessage = ({ header, message }) => (
    <Message negative>
        <Message.Header>{header}</Message.Header>
        <p>{message}</p>
    </Message>
);

export const DepartmentNotices = () => {
    const [result, setResult] = useState({
        success: 0,
        failure: 0
    });
    const [message, setMessage] = useState("");
    const [attempted, setAttempt] = useState(0);

    const validationSchema = Yup.object({
        branch: Yup.string().required('Required Field'),
        title: Yup.string().required('Required Field'),
        files: Yup.array().min(1,'You have to upload atleast one file'),
    });

    const fileInputRef = React.useRef();

    const getData = async (formData) => {
        let dest_url = `/user/department_notice`;
        let body = new FormData();

        console.log(formData);

        body.append('branch', formData.branch);
        body.append('title', formData.title);
        for(let file of formData['files']){
            body.append('file', file)
        }
        setAttempt(1);
        const data = await client.post(dest_url, body);
        console.log(data.data.status)
        setAttempt(0);
        if (data.data.status === 0)
            setResult({
                failure: 1,
                success: 0,
            });
        else
            setResult({
                success: 1,
                failure: 0
            });
        setMessage(data.data.message);
    };

    return (
        <Container>
            <Segment>
                <Header as="h2">
                    <Icon name="settings" />
                    <Header.Content>
                        Department Notice Upload
            <Header.Subheader>Upload a Notice...</Header.Subheader>
                    </Header.Content>
                </Header>
            </Segment>
            <Segment>
                <Formik
                    initialValues={{
                        branch: '',
                        title: '',
                        files: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        getData(data);
                        setSubmitting(false);
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <label>Details About Notice</label>
                            <br />
                            <Segment>
                                <Field
                                    name="title"
                                    label="Title"
                                    placeholder="Add Title about Notice...."
                                    type="input"
                                    as={Form.TextArea}
                                />
                                <ErrorMessage
                                    name="title"
                                    header="Title"
                                    message={errors.title}
                                    component={MyErrorMessage}
                                />
                            </Segment>
                            <Segment>
                                <Form.Group widths="equal">
                                    <Form.Select
                                        fluid
                                        label="Branch"
                                        name="branch"
                                        options={branches}
                                        placeholder="Branch"
                                        onChange={(e) => {
                                            setFieldValue('branch', e.target.childNodes[0].textContent);
                                        }}
                                    />
                                </Form.Group>
                                <ErrorMessage
                                    name="branch"
                                    header="Branch"
                                    message={errors.branch}
                                    component={MyErrorMessage}
                                />
                            </Segment>
                            <label>Upload Notice</label>
                            <Segment placeholder>
                                <Header icon>
                                { 
                                    values?.files < 1 ? 
                                    <>
                                    <Icon name="pdf file outline" />
                                    No documents are listed for this customer.
                                    </>
                                    : (<ul>
                                    <li style={{listStyle:'none', fontSize:'14px'}}>At max 3 files can only be uploaded at a time</li>
                                    <hr />
                                    <br />
                                    {
                                        values?.files?.map((file,i) => <div key={i} style={{display:'flex', justifyContent:'space-around'}}><div>{file.name}</div><div onClick={(e)=>{
                                        const files = values.files.filter((file,index) => index !== i);
                                        setFieldValue('files', files)
                                        }} style={{cursor:'pointer'}}>&#10060;</div></div>)
                                    }
                                    </ul>)
                                }
                                </Header>
                                <Form.Button
                                primary
                                disabled = {values.files.length === 3}
                                type="button"
                                onClick={() => fileInputRef.current.click()}
                                >
                                {!values?.files ? 'Add Document' : 'Add More'}
                                </Form.Button>
                                <input
                                ref={fileInputRef}
                                hidden
                                type="file"
                                name="files"
                                id="files"
                                onChange={(event) => {
                                    console.log([...values?.files,event.currentTarget.files[0]])
                                    setFieldValue('files', [...values.files,event.currentTarget.files[0]]);
                                }}
                                />
                                <ErrorMessage
                                    name="file"
                                    header="File"
                                    message={errors.files}
                                    component={MyErrorMessage}
                                />
                            </Segment>
                            <Segment>
                                <Form.Button
                                    fluid
                                    primary
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Form.Button>
                            </Segment>
                            {
                                attempted ? (<Loading />) : null
                            }
                            {
                                result.success ? (<MySuccessMessage
                                    header="Notice"
                                    message={message}
                                />) : null
                            }
                            {
                                result.failure ? (<MyErrorMessage
                                    header="Notice"
                                    message={message}
                                />) : null
                            }
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}