import React from "react";
import { useState } from "react";
import client from "../../helpers/Client";
import { Button, Form, Grid, Input, Loader, Segment } from "semantic-ui-react";
import {
  MyErrorMessage,
  MySuccessMessage,
} from "../../components/AlertMessage";

// import { Loader } from "semantic-ui-react";

// import { MyErrorMessage, MySuccessMessage } from "./Notification";

const emptyFormData = {
  title: "",
  file: "",
};

const AlumniPublication = () => {
  const [data, setData] = useState(emptyFormData);
  const [result, setResult] = useState({
    success: 0,
    failure: 0,
  });
  // const [calendar, setCalendar] = useState([]);
  const [loading, setLoading] = useState(false);

  const submit = (data) => {
    // setLoading(true);
    let dest_url = `/user/alumni/addPublication`;
    const body = new FormData();
    body.append("title", data.title);
    body.append("file", data.publication);
    console.log(body.get("file"));

    client
      .post(dest_url, body)
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        setResult({ success: 0, failure: 1 });
      })
      .finally(() => {
        setLoading(false);
        setResult({ success: 1, failure: 0 });
      });
    // console.log(data.link);
    setData(emptyFormData);
    // console.log(data.publication);
  };

  return loading ? (
    <Loader active inline="centered" size="massive" />
  ) : (
    <Segment>
      <Segment>
        <Grid columns={2} padded>
          <Grid.Column padded>
            <h1>Add Alumni Publication</h1>
            <Form onSubmit={() => submit(data)} loading={loading}>
              <Form.Field
                required
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                }}
              >
                <label>Title</label>
                <Input
                  name="title"
                  value={data.title}
                  placeholder=""
                  required
                />
              </Form.Field>
              <Form.Field
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.files[0] });
                  //   console.log("file calendar", e.target.files[0]);
                }}
              >
                <label>Add Data</label>
                <Input
                  type="file"
                  value={data.link}
                  name="publication"
                  placeholder="pdf"
                  required
                />
              </Form.Field>

              <Button positive type="submit" fluid>
                <i className="far fa-upload"></i> Add Publication
              </Button>
            </Form>
          </Grid.Column>

          {/* <Grid.Column>
            {fetching && <Loader active inline="centered" size="massive" />}
            {!fetching && calendar && (
              <Card.Group>
                {calendar.map((c) => (
                  <Card key={c.id} fluid>
                    <Card.Content>
                      <Card.Header>Calendar</Card.Header>
                      <Card.Description>
                        <a
                          href={removeDoubleSlashes(BACKEND_URL + "/" + c.link)}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {c.title}
                        </a>
                        <p>{c.active === 0 ? "Archived" : ""}</p>
                      </Card.Description>
                      {c.active === 1 && (
                        <Button positive onClick={() => archive(c.id)}>
                          Archive
                        </Button>
                      )}
                      <Button onClick={() => deleteCalendar(c.id)}>
                        Delete
                      </Button>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            )}
          </Grid.Column> */}
        </Grid>
      </Segment>
      {result.success === 1 && (
        <MySuccessMessage header="Successful" message="Publication Added" />
      )}
      {result.failure === 1 && (
        <MyErrorMessage header="Failed" message="Failed to add calendar" />
      )}
    </Segment>
  );
};

export default AlumniPublication;
