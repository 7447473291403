import React, { useEffect, useState } from "react";
import { Grid, Card, Loader, Dropdown } from "semantic-ui-react";
import { UpSquareOutlined } from "@ant-design/icons";
import client from "../../helpers/Client";
import { Card as AntCard, Button as AntButton } from "antd";
import moment from "moment";

const Archives = () => {
  const [archive, setArchive] = useState([]);
  const [filter, setFilter] = useState("institute_events");
  const [fetching, setFetching] = useState(false);
  const [request,setRequest] = useState(false);

  const options = [
    {
      id: 1,
      text: "Institute Events",
      value: "institute_events",
    },
    {
      id: 2,
      text: "Notices",
      value: "notice",
    },
    {
      id: 3,
      text: "tender",
      value: "tender",
    },
  ];

  useEffect(() => {
    setFetching(true);
    let dest_url = `api/archives/getArchives?filter=${filter}`;
    client
      .get(dest_url)
      .then((data) => {
        setArchive(data.data.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
      });
  }, [filter,request]);

  const archiveEvent = (event) => {
    setFetching(true);
    setRequest(true);
    let dest_url = `api/archives/unarchive?filter=${filter}`;
    client
      .post(dest_url, event)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
        setRequest(false);
      });
  };

  return (
    <Grid>
      <Grid.Column>
        <div>
          <h4>
            Choose Archive Category:
            <Dropdown
              onChange={(e, val) => {
                setFilter(val.value);
              }}
              name="filter"
              placeholder="Select Filter"
              search
              selection
              options={options}
            />
          </h4>
        </div>
        <div style={{ overflowY: "auto", height: "100vh" }}>
          {fetching && <Loader active inline="centered" size="massive" />}
          {!fetching && archive && (
            <Card.Group>
              {archive.map((pub) => (
                <AntCard
                  key={pub.id}
                  title={pub.title ? pub.title : pub.notification}
                  style={{
                    width: "100%",
                    marginTop: 5,
                    borderColor: "blue",
                    borderStyle: "dotted",
                  }}
                >
                  <center>
                    <p>
                      <b>Start Date:</b>{" "}
                      {pub.start_date
                        ? moment(pub.start_date).format(
                            "MMMM Do YYYY, h:mm:ss A"
                          )
                        : "No start date"}
                    </p>
                    <p>
                      <b>End Date:</b>{" "}
                      {pub.end_date
                        ? moment(pub.end_date).format("MMMM Do YYYY, h:mm:ss A")
                        : "No End date"}
                    </p>
                    <AntButton
                      type="primary"
                      onClick={() => archiveEvent(pub)}
                      icon={<UpSquareOutlined />}
                      size={"large"}
                      style={{ marginBottom: "5px" }}
                    >
                      Unarchive Event
                    </AntButton>{" "}
                    <br />
                  </center>
                </AntCard>
              ))}
            </Card.Group>
          )}
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default Archives;
