import axios from 'axios';
import { BACKEND_URL } from './constants';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const fetchClient = () => {
  const defaultOptions = {
    baseURL: `${BACKEND_URL}`,
      headers: {
        'device-id': "LOGGEDIN_USER",
      }
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    const token = cookies.get('token');
    if (token) config.headers['Authorization'] = `bearer ${token}`;
    return config;
  });

  return instance;
};

export default fetchClient();
