import React, { useState, useEffect } from "react";
import { Grid, Form,  Button } from "semantic-ui-react";
import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { useAlert } from "react-alert";
import JoditEditor from "jodit-react";

const OtherActivities = () => {
  const alert = useAlert();
  const [activities, setActivities] = useState("");
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { uid } = JwtDecode(token);
    let dest_url = `/faculty/get_other_activities/${uid}`;
    client
      .get(dest_url)
      .then((res) => {
        setActivities(res.data.result[0].activities);
      })
      .catch((err) => console.log(err))
  }, [request]);

  const submit = (e) => {
    e.preventDefault();
    let dest_url = "/faculty/other_activities";
    if (!OtherActivities) {
      alert.error("Missing Required field");
      return;
    }

    setLoading(true);

    client
      .post(dest_url, { activities })
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setActivities("");
        setRequest(!request);
      });
    alert.success(
      <div style={{ textTransform: "initial" }}>
        Activities Added Successfully
      </div>
    );
  };

  return (
    <Grid relaxed="very" stackable divided>
      <Grid.Column>
        <h1>Other Academic Activities</h1>
        <Form onSubmit={submit} loading={loading}>
          <Form.Field required>
            <label>Other Academic Activities</label>
            <JoditEditor
              name="otherActivities"
              value={activities}
              tabIndex={1}
              onChange={(newContent) => {
                setActivities(newContent);
              }}
            />
          </Form.Field>
          <Button positive type="submit" fluid>
            Save
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default OtherActivities;
