import React from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import { isMobile } from "react-device-detect";

import { NavLink } from "react-router-dom";
import useStyles from "./Styles";

//responsive drawer
const showItems = isMobile ? "temporary" : "permanent";

export const mainListItems = (
  <div className="mt-5">
    <NavLink to="/faculty/">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-home fa-2x"></i>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </NavLink>
    {/* <NavLink to="/faculty/upload">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fal fa-file-pdf"></i>
        </ListItemIcon>
        <ListItemText primary="Assignment/Notes" />
      </ListItem>
    </NavLink> */}
    <NavLink to="/faculty/publication">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-print"></i>
        </ListItemIcon>
        <ListItemText primary="Publication" />
      </ListItem>
    </NavLink>
    <NavLink to="/faculty/thesissupervised">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-print"></i>
        </ListItemIcon>
        <ListItemText primary="Thesis Supervised" />
      </ListItem>
    </NavLink>
    <NavLink to="/faculty/Course">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-book"></i>
        </ListItemIcon>
        <ListItemText primary="Course" />
      </ListItem>
    </NavLink>
    <NavLink to="/faculty/workexperience">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-cog"></i>
        </ListItemIcon>
        <ListItemText primary="Research Area" />
      </ListItem>
    </NavLink>

    <NavLink to="/faculty/responsibilities">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-cog"></i>
        </ListItemIcon>
        <ListItemText primary="Responsibilities" />
      </ListItem>
    </NavLink>

    <NavLink to="/faculty/profile">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user-circle"></i>
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItem>
    </NavLink>
    <NavLink to="/faculty/logs">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-th-list"></i>
        </ListItemIcon>
        <ListItemText primary="Logs" />
      </ListItem>
    </NavLink>
    <NavLink to="/faculty/awards">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-trophy fa-2x"></i>
        </ListItemIcon>
        <ListItemText primary="Awards" />
      </ListItem>
    </NavLink>
    <NavLink to="/faculty/otherActivities">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-home fa-2x"></i>
        </ListItemIcon>
        <ListItemText primary="Other academic achievements" style={{width:"100%"}} />
      </ListItem>
    </NavLink>
    {/* <NavLink to="/faculty/settings">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-cogs"></i>
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </NavLink> */}
  </div>
);

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader> */}
    <NavLink to="/faculty/phonebook">
      <ListItem button>
        <ListItemIcon>
          {/* <LayersIcon /> */}
          <i className="far fa-2x fa-phone-office"></i>
        </ListItemIcon>
        <ListItemText primary="Phonebook" />
      </ListItem>
    </NavLink>
  </div>
);

const Sidebar = ({ close, open }) => {
  const classes = useStyles();
  return (
    <Drawer
      variant={showItems}
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
      
    >
      <div className={isMobile ? classes.toolbarIconMobile : classes.toolbarIcon}>
        <IconButton onClick={close}>
          <i className="fad fa-arrow-circle-left" style={{color:'white'}}></i>
        </IconButton>
      </div>
      <Divider />
      <List onClick={isMobile ? close : null}>{mainListItems}</List>
      <Divider />
      <List onClick={isMobile ? close : null}>{secondaryListItems}</List>
    </Drawer>
  );
};

export default Sidebar;
