import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Input } from "semantic-ui-react";
import * as mime from "mime-types";
import {
  List,
  ListItem,
  makeStyles,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import client from "../../helpers/Client";
import { GrTrophy } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
const FileDownLoad = require("js-file-download");
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f0f0f0",
    },
  },
}));

/**
 * @description component
 * @param {*} props
 * @returns
 */
const Awards = (props) => {
  const [date, setDate] = useState(new Date());
  const [awardFor, setAwardFor] = useState("");
  const [awardTo, setAwardTo] = useState("");
  const [desc, setDesc] = useState("");
  const [desg, setDesg] = useState("");
  const [file, setFile] = useState(null);
  const [allAwards, setAllAwards] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [id, setId] = useState(null);
  const [cnt, setCnt] = useState(0);
  const classes = useStyles();

  const deleteHandler = async (id) => {
    try {
      await client.post("/faculty/awards/delete", { id });
      setCnt(cnt + 1);
    } catch (error) {
      alert("some error occured!!");
    }
  };

  const downLoadFile = async (e) => {
    e.preventDefault();
    if (isUpdating) {
      const downloadedFile = await client.get(`/faculty/awards/file?id=${id}`, {
        responseType: "blob",
      });
      const extension = mime.extension(downloadedFile.headers["content-type"]);
      console.log(downloadedFile);
      FileDownLoad(downloadedFile.data, `awardFile${id}.${extension}`);
    }
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();
    // console.log(awardFor, awardTo, desc, desg, date, file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("awardTo", awardTo);
    formData.append("desg", desg);
    formData.append("awardFor", awardFor);
    formData.append("info", desc);
    formData.append("date", date);
    if (isUpdating) {
      formData.append("id", id);
      try {
        const data = await client.post("/faculty/awards/update", formData);
        console.log(data);
        setDate("");
        setAwardFor("");
        setAwardTo("");
        setDesc("");
        setDesg("");
        setId("");
        setFile("");
        setIsUpdating(false);
        setCnt(cnt + 1);
      } catch (error) {
        alert("some error occured!!");
      }
      setId(" ");
    } else {
      try {
        const data = await client.post("/faculty/awards/add", formData);
        console.log(data);
        setDate("");
        setAwardFor("");
        setAwardTo("");
        setDesc("");
        setDesg("");
        setId("");
        setFile("");
        setIsUpdating(false);
        setCnt(cnt + 1);
      } catch (error) {
        alert("some error occured!!");
      }
    }
  };

  useEffect(() => {
    const [award] = allAwards.filter((awd) => awd.id === id);
    if (award) {
      setDate(award.award_date);
      setAwardFor(award.award_for);
      setAwardTo(award.award_to);
      setDesc(award.info);
      setDesg(award.desg);
      setIsUpdating(true);
    } else {
      setDate("");
      setAwardFor("");
      setAwardTo("");
      setDesc("");
      setDesg("");
      setIsUpdating(false);
    }
  }, [id, allAwards]);

  useEffect(() => {
    client
      .post("/faculty/awards/read")
      .then((res) => {
        if (res.data.status === 1) {
          setAllAwards(res.data.data);
        }
      })
      .catch((err) => {
        alert("some error occured!!");
      });
  }, [cnt]);

  console.log(allAwards);
  //jsx
  return (
    <Grid columns={2} relaxed="very" stackable divided>
      <Grid.Column>
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Award for</label>
              <Input
                name="award for"
                value={awardFor}
                onChange={(e) => setAwardFor(e.target.value)}
                placeholder="Award for"
                required
              />
            </Form.Field>
            <Form.Field required>
              <label>Awardee</label>
              <Input
                name="awardee"
                value={awardTo}
                onChange={(e) => setAwardTo(e.target.value)}
                placeholder="Awardee"
                required
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Description</label>
              <textarea
                rows="3"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                name="description"
                placeholder="description"
                required
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field required>
              <label>Designation</label>
              <Input
                name="designation"
                value={desg}
                onChange={(e) => setDesg(e.target.value)}
                placeholder="Designation"
                required
              />
            </Form.Field>
            <Form.Field required>
              <label>Date of award</label>
              <Input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field required>
              <label>Upload document</label>
              <Input
                type="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
                placeholder="Award document"
                accept=".doc,.docx,.pdf,.ppt,.pptx,.png,.jpeg,.jpg"
              />
            </Form.Field>
          </Form.Group>
          {isUpdating && (
            <Button onClick={downLoadFile} size="medium" color="teal" fluid>
              Download file
            </Button>
          )}
          <br />
          <Button onClick={submitFormHandler} positive fluid>
            {isUpdating ? "Update" : "Save"}
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        <List className={classes.root}>
          {allAwards.map((award) => {
            return (
              <ListItem
                onClick={(e) => setId(award.id)}
                className={classes.item}
                key={award.id}
              >
                <ListItemAvatar>
                  <GrTrophy size="1.3em" />
                </ListItemAvatar>
                <ListItemText>
                  {award.award_to}&nbsp;for&nbsp;{award.award_for}
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={(e) => deleteHandler(award.id)}>
                    <MdDelete size="1.1em" color="tomato" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid.Column>
    </Grid>
  );
};

export default Awards;
