import React, { useState } from "react";
import { Grid, Form, Input, Button, Message } from "semantic-ui-react";
import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

export const MySuccessMessage = ({ header, message }) => (
  <Message positive>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

const MyErrorMessage = ({ header, message }) => (
  <Message negative>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState(null);

  const submit = async () => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { user } = JwtDecode(token);

    if (newPassword === cPassword) {
      let path = "/user/changePassword";
      client
        .post(path, { email: user, password, newPassword })
        .then((res) => {
          if (res.data.status === 1) {
            setSuccess(true);
            setPassword("");
            setNewPassword("");
            setCPassword("");
          } else {
            setSuccess(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSuccess(false);
        });
    } else {
      setSuccess(false);
    }
  };

  return (
    <Grid columns={3} relaxed="very" stackable divided centered>
      <Grid.Column>
        <h1>Change Password</h1>
        <Form onSubmit={submit}>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Enter current Password</label>
              <Input
                name="password"
                placeholder="Current Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Enter New Password</label>
              <Input
                name="newPassword"
                value={newPassword}
                placeholder="New Password"
                required
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Confirm New Password</label>
              <Input
                name="cNewPassword"
                value={cPassword}
                placeholder="Current Password"
                required
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Button positive type="submit" fluid>
            Change
          </Button>
          {success && (
            <MySuccessMessage
              header="Change Password"
              message="Passwords changed successfully!"
            />
          )}
          {success === false && (
            <MyErrorMessage
              header="Change Password"
              message="Passwords changed Failed!"
            />
          )}
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default ChangePassword;
