import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  // Responsive
} from "semantic-ui-react";
import logo from "../assets/logo.jpg";
import client from "../helpers/Client";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { useAlert } from "react-alert";
import JwtDecode from "jwt-decode";

import {
  isMobile,
  mobileModel,
  mobileVendor,
  browserName,
  osName,
  osVersion,
} from "react-device-detect";
import {
  Loading,
  MyErrorMessage,
  MySuccessMessage,
} from "../components/AlertMessage";
if (isMobile) {
  var device =
    mobileVendor + " " + mobileModel + ", " + browserName + " (" + osName + ")";
} else {
  device = browserName + " (" + osName + " - " + osVersion + ")";
}

const cookies = new Cookies();
function LoginForm() {
  const alert = useAlert();
  const [result, setResult] = useState({
    success: 0,
    failure: 0,
  });
  const [message, setMessage] = useState("");
  const [attempted, setAttempt] = useState(0);

  // state for login form
  const [form, setState] = useState({
    username: "",
    password: "",
    radioGroup: "",
  });

  // handling form data on submission
  const HandleValues = async (e) => {
    e.preventDefault();
    let dest_url = "";
    if (form.radioGroup === "faculty") {
      dest_url = `/user/login`;
    } else if (form.radioGroup === "admin") {
      dest_url = `/user/loginAdmin`;
    }

    if (form.username && form.password && form.radioGroup) {
      setAttempt(1);
      setResult({
        failure: 0,
        success: 0,
      });
      try {
        const facultyLogin = await client.post(dest_url, {
          username: form.username.toLowerCase().trim(),
          password: form.password,
          device: device,
        });
        if (facultyLogin.data.status) {
          setResult({
            failure: 0,
            success: 1,
          });
          setMessage("Login Successful");
          alert.success("Successfully logged in");
          cookies.set("token", facultyLogin.data.accessToken, { path: "/" });
          setAttempt(0);
        } else {
          setResult({
            failure: 1,
            success: 0,
          });
          setMessage(facultyLogin.data.message);
          setAttempt(0);
          alert.error(facultyLogin.data.message);
        }
      } catch (err) {
        setResult({
          failure: 1,
          success: 0,
        });
        setMessage(err.message);
        setAttempt(0);
        console.log(err);
        alert.error("Login Failed");
      }
    } else {
      alert.error("All fields are Required");
    }
  };

  // update form data
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  if (cookies.get("token") != null) {
    var { user, session_id, groupName } = JwtDecode(cookies.get("token"));
    let dest_url = "/api/sessions/checkSession";
    client
      .post(dest_url, {
        user,
        session_id,
      })
      .then((res) => {
        if (!res.data.status) {
          alert.error("Session Expired");
          setTimeout(() => {
            cookies.remove("token");
            window.location = "/";
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(`groupname is ${groupName}`);
    return <Redirect to={{ pathname: `/${groupName}` }} />;
  } else {
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh", padding: "1rem" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 400 }}>
          <Header as="h2" color="teal" textAlign="center">
            <Image src={logo} /> Log-In to your account
          </Header>
          <Form>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <input
                    type="radio"
                    id="faculty"
                    name="radioGroup"
                    value="faculty"
                    onChange={updateField}
                    // checked={true}
                    checked={form.radioGroup === "faculty"}
                  />
                  <label htmlFor="faculty"> Faculty</label>
                </Grid.Column>
                <Grid.Column>
                  <input
                    type="radio"
                    id="admin"
                    name="radioGroup"
                    value="admin"
                    onChange={updateField}
                    checked={form.radioGroup === "admin"}
                  />
                  <label htmlFor="admin"> Admin</label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <br />

          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={form.username}
                name="username"
                onChange={updateField}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                value={form.password}
                name="password"
                onChange={updateField}
              />
              {attempted ? <Loading /> : null}
              {result.failure ? (
                <MyErrorMessage header="Failed" message={message} />
              ) : null}
              {result.success ? (
                <MySuccessMessage header="Successful" message={message} />
              ) : null}

              <Button
                color="teal"
                fluid
                size="large"
                onClick={HandleValues}
                disabled={attempted}
              >
                Login
              </Button>
            </Segment>
          </Form>
          <Message>
            Cannot login? Contact{" "}
            <span style={{ color: "Red" }}> Adminstrator @CC </span>.
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

export default LoginForm;
