import React, { Component } from "react";
import {
  Table,
  Loader,
  Dimmer,
  Container,
  Dropdown,
  Button,
} from "semantic-ui-react";
import client from "../../helpers/Client";

const branch_list = [
  { text: "Select Department", value: "" },
  { text: "CA", value: "CA" },
  { text: "CH", value: "CH" },
  { text: "CE", value: "CE" },
  { text: "CS", value: "CS" },
  { text: "EC", value: "EC" },
  { text: "EE", value: "EE" },
  { text: "HU", value: "HU" },
  { text: "MA", value: "MA" },
  { text: "ME", value: "ME" },
  { text: "MM", value: "MM" },
  { text: "PH", value: "PH" },
  { text: "PI", value: "PI" },
];

const HodCard = (props) => {
  return (
    <Table.Row>
      <Table.Cell>{props.user.faculty_id}</Table.Cell>
      <Table.Cell>{props.user.branch}</Table.Cell>
      <Table.Cell>{props.user.email}</Table.Cell>
      <Table.Cell>{props.user.modified_date.slice(0, 19)}</Table.Cell>
    </Table.Row>
  );
};

export default class Hod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_designation: "",
      new_branch: "",
      new_faculty_id: "",
      new_email: "",
      hod_data: "",
      loading: true,
      button_loader: false,
    };
    this.fetch_hod = this.fetch_hod.bind(this);
    this.addHod = this.addHod.bind(this);
  }

  async fetch_hod() {
    try {
      const response = await client.post("/user/hod", {
        page: 1,
      });
      this.setState({ hod_data: response.data.response, loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
      alert("Loading Failed");
    }
  }
  componentDidMount() {
    this.fetch_hod();
  }
  addHod = async () => {
    let faculty_id = this.state.new_faculty_id;
    let email = this.state.new_email;
    let branch = this.state.new_branch;

    if (!faculty_id || !email || !branch) {
      alert("Missing Required Field");
      return false;
    } else {
      this.setState({ button_loader: true });
      let new_response = await client.post("/user/hod/add", {
        faculty_id: faculty_id,
        branch: branch,
        email: email,
      });
      if (new_response.data.status) {
        this.setState({ button_loader: false });
        alert("HOD Added");
        this.setState({
          hod_data: new_response.data.response,
          new_faculty_id: "",
          new_branch: "",
          new_email: "",
          new_designation: "",
        });
      } else {
        alert(new_response.data.message);
        this.setState({ button_loader: false });
      }
      console.log(new_response);
    }
  };
  render() {
    return !this.state.loading ? (
      <Container>
        <br />
        <br />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <div className="ui  fluid input">
                  <input
                    type="text"
                    placeholder="Faculty Id"
                    onChange={(e) => {
                      this.setState({ new_faculty_id: e.target.value });
                    }}
                    value={this.state.new_faculty_id}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  fluid
                  placeholder="Department"
                  clearable
                  search
                  selection
                  options={branch_list}
                  onChange={(event, { value }) => {
                    this.setState({ new_branch: value });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <div className="ui fluid input">
                  <input
                    type="text"
                    placeholder="Email"
                    onChange={(e) => {
                      this.setState({ new_email: e.target.value });
                    }}
                    value={this.state.new_email}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button
                  color="blue"
                  loading={this.state.button_loader}
                  onClick={this.addHod}
                >
                  Add/Edit Hod
                </Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Faculty ID</Table.HeaderCell>
              <Table.HeaderCell>Branch</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.hod_data &&
              this.state.hod_data.map(function (hod_data, i) {
                return <HodCard user={hod_data} key={i} />;
              })}
          </Table.Body>
        </Table>
      </Container>
    ) : (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }
}
