import React, { useState, useEffect } from "react";
import {
  Form,
  Grid,
  Input,
  Button,
  Card,
  Loader,
  Dropdown,
  TextArea,
} from "semantic-ui-react";
import moment from "moment";
import { useAlert } from "react-alert";
import client from "../../helpers/Client";
import { Card as AntCard, Button as AntButton, Image } from "antd";
import { DownSquareOutlined } from "@ant-design/icons";
import { removeDoubleSlashes } from "../../helpers/urlhelper";
import { BACKEND_URL } from "../../helpers/constants";

const emptyFormData = {
  assigned_date: "",
  file_title: "",
  last_date: "",
  branch: "",
  image: "",
  brochure: null,
  website_link: "",
  desc: ""
};

const branches = [
  { key: "1", text: "mca", value: "CA" },
  { key: "2", text: "chem", value: "CH" },
  { key: "3", text: "civil", value: "CE" },
  { key: "4", text: "cs", value: "CS" },
  { key: "5", text: "ece", value: "EC" },
  { key: "6", text: "eee", value: "EE" },
  { key: "7", text: "humanities", value: "HU" },
  { key: "8", text: "maths", value: "MA" },
  { key: "9", text: "mech", value: "ME" },
  { key: "10", text: "meta", value: "MM" },
  { key: "11", text: "phys", value: "PH" },
  { key: "12", text: "prod", value: "PI" },
];

export const Event = () => {
  const alert = useAlert()
  const [data, setData] = useState(emptyFormData);
  const [event, setEvent] = useState([]);
  const [eventError, setEventError] = useState("");
  const [flag, setFlag] = useState(false); // flag = true then Edit event else Add new event
  const [Id, setId] = useState(null); // for Edit event
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);
  // const [isBrochure, setIsBrochure] = useState(false);

  useEffect(() => {
    setFetching(true);
    let dest_url = "/api/events/upcoming";

    client
      .get(dest_url)
      .then((res) => {
        setEvent(res.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const archiveEvent = (event) => {
    setFetching(true);
    let dest_url = "/user/institute_event/archive";
    client
      .post(dest_url, event)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setRequest(!request);
      });
  };

  const deleteEvent = (event) => {
    setFetching(true);
    let dest_url = "/user/institute_event/delete";
    client
      .post(dest_url, event)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setRequest(!request);
      });
  };

  const setFieldValues = (currentEvent) => {
    //console.log(currentEvent);
    var field_values = {
      assigned_date: (currentEvent.start_date).slice(0, 16),
      file_title: currentEvent.title,
      website_link: currentEvent.link,
      last_date: (currentEvent.end_date).slice(0, 16),
      branch: switchBranch(currentEvent.dept),
      desc: currentEvent.desc,
      image: currentEvent.image_link,
      brochure: currentEvent.brochure
    };
    setData(field_values);
    console.log(field_values);
    setId(currentEvent.id);
  };

  const switchBranch = (branch) => {
    switch (branch) {
      case 'CA': {
        branch = 'mca';
        break;
      }

      case 'CH': {
        branch = 'chem';
        break;
      }

      case 'CE': {
        branch = 'civil';
        break;
      }

      case 'CS': {
        branch = 'cs';
        break;
      }

      case 'EC': {
        branch = 'ece';
        break;
      }

      case 'EE': {
        branch = 'eee';
        break;
      }

      case 'HU': {
        branch = 'humanities';
        break;
      }

      case 'MA': {
        branch = 'maths';
        break;
      }

      case 'ME': {
        branch = 'mech';
        break;
      }

      case 'MM': {
        branch = 'meta';
        break;
      }

      case 'PH': {
        branch = 'phys';
        break;
      }

      case 'PI': {
        branch = 'prod';
        break;
      }

      default: {
        break;
      }
    }
    return branch;
  }

  const EditEvents = (data) => {
    setLoading(true);
    let dest_url = `/user/institute_event/edit`;

    const body = new FormData();
    body.append("start_date", data.assigned_date);
    body.append("title", data.file_title);
    body.append("end_date", data.last_date);
    body.append("dept", data.branch);
    if (data.website_link === "") {
      body.append("file", data["brochure"]);
    } else {
      body.append("link", data.website_link);
    }
    body.append("file", data["image"]);
    body.append("desc", data.desc);
    body.append("brochure", data.brochure);
    body.append("id", Id);

    client
      .post(dest_url, body)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
        setFlag(false);
        setRequest(!request);
      });

  };

  const submit = (data) => {
    setLoading(true);

    let dest_url = `/user/institute_event`;
    console.log(data);

    if(data.website_link==="" && data.brochure===null) {
      console.log("event not uploaded");
      setEventError("Please provide either website link or brochure")
      //window.alert("Please provide either website link or brochure")
    }

    const body = new FormData();
    body.append("start_date", data.assigned_date);
    body.append("title", data.file_title);
    body.append("end_date", data.last_date);
    body.append("dept", data.branch);
    if (data.website_link === "") {
      body.append("file", data["brochure"]);
    } else {
      body.append("link", data.website_link);
    }
    body.append("file", data["image"]);
    body.append("desc", data.desc);

    // const body = {
    //   start_date: data.assigned_date,
    //   title: data.file_title,
    //   end_date: data.last_date,
    //   dept: data.branch,
    //   link: data.website_link,
    //   file: data.image,
    //   brochure: data.brochure
    // }

   for(let val of body.values()){
     console.log(val)
   }
    
    client
      .post(dest_url, body)
      .then((res) => {
        console.log(res)
        alert.success("Event added")
      })
      .catch((err) => {
        console.log(err)
        alert.error("Failed to add")
      })
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
        setRequest(!request);
      });

    // console.log(data);
  };

  return (
    <Grid columns={2} divided>
      <Grid.Column>
        <h1>{flag ? "Edit" : "Add"} Events</h1>
        <Form onSubmit={() => (flag ? EditEvents(data) : submit(data))} loading={loading}>
          <Form.Field
            required
            onChange={(e) => {
              setData({ ...data, [e.target.name]: e.target.value });
            }}
          >
            <label>Event Title (Do Not enter the year in the Event Title)</label>
            <Input
              name="file_title"
              placeholder="Event title"
              value={data.file_title}
              required
            />
          </Form.Field>

          <Form.Field required>
            <label>Branch</label>
            <Dropdown
              onChange={(e, val) => {
                setData({ ...data, branch: switchBranch(val.value) });
              }}
              name="branch"
              placeholder="Branch"
              search
              selection
              options={branches}
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
              name="assigned_date"
              label="Event Start Date"
              value={data.assigned_date}
              type="datetime-local"
              required
            />

            <Form.Field
              name="last_date"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
              control={Input}
              label="Event Last Date"
              value={data.last_date}
              min={data.assigned_date}
              type="datetime-local"
              placeholder="Last Date of submission"
              required
            />
          </Form.Group>

          <Form.Field
            onChange={(e) => {
              setData({ ...data, [e.target.name]: e.target.value });
            }}
          >
            <label>Event Description</label>
            <TextArea name="desc" value={data.desc} placeholder="" />
          </Form.Field>

          <Form.Field
          required
            onChange={(e) => {
              // console.log(e.target.files[0])
              let file_type = e.target.files[0].type;
              if (
                file_type === "image/jpeg" ||
                file_type === "image/jpg" ||
                file_type === "image/png"
              )
                setData({ ...data, [e.target.name]: e.target.files[0] });
              else
                alert.error("The Event Image should end with jpg/jpeg/png extension");
            }}
          >
            <label>Event Logo/Image</label>
            <Input
              type="file"
              name="image"
              value={data.link}
              placeholder="Event Image"
              required
            />
          </Form.Field>

          <Form.Field
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }>
            <label>Event Website Link</label>
            <Input
              type="text"
              name="website_link"
              value={data.website_link}
              placeholder="Website Link"
            />
          </Form.Field>
          <Form.Field required
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.files[0] })
            }>
            <label>Event Brochure</label>
            <Input
              type="file"
              name="brochure"
              value={data.link}
              placeholder="Event Brochure"
            />
          </Form.Field>
          <p style={{color:"red"}}>{eventError}</p>
          <Button positive type="submit" fluid>
            <i className="far fa-upload"></i> {flag ? "Edit" : "Upload"} Event
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        <div style={{ overflowY: 'auto', height: '100vh' }}>
          {fetching && <Loader active inline="centered" size="massive" />}
          {!fetching && event && (
            <Card.Group>
              {event.map((pub) => (
                <AntCard
                  key={pub.id}
                  title={pub.title}
                  extra={
                    <a
                      href={pub.file_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fal fa-external-link-alt"></i> View Event
                    </a>
                  }
                  style={{
                    width: "100%",
                    marginTop: 5,
                    borderColor: "blue",
                    borderStyle: "dotted",
                  }}
                >
                  <center>
                    <Image src={removeDoubleSlashes(`${BACKEND_URL}/${pub.file_link}`)} width="100px" height="100px" />
                    <p>
                      <b>Start Date:</b>{" "}
                      {moment(pub.start_date).format("MMMM Do YYYY, h:mm:ss A")}
                    </p>
                    <p>
                      <b>End Date:</b>{" "}
                      {moment(pub.end_date).format("MMMM Do YYYY, h:mm:ss A")}
                    </p>
                    <p>{pub.desc}</p>
                    <AntButton
                      type="primary"
                      onClick={() => archiveEvent(pub)}
                      icon={<DownSquareOutlined />}
                      size={"large"}
                      style={{ marginBottom: "5px" }}
                    >
                      Archive Event
                    </AntButton> <br />
                    <Button
                      positive
                      onClick={() => {
                        setFlag(true)
                        setId(pub.id)
                        //setData(emptyFormData);
                        setFieldValues(pub);
                      }}
                      // icon={<DownSquareOutlined />}
                      size="medium"
                    >
                      <i className="fas fa-edit"></i>  Edit
                    </Button>{"   "}
                    <Button
                      negative
                      size="medium"
                      onClick={() => {
                        if (window.confirm("Are You Sure?")) {
                          deleteEvent(pub);
                        }
                      }}
                    >
                      <i className="fal fa-trash-alt"></i>  Delete
                    </Button>
                  </center>
                </AntCard>
              ))}
            </Card.Group>
          )}
        </div>
      </Grid.Column>
    </Grid>
  )
};
