import React from "react";

import { Icon, Message } from "semantic-ui-react";

export const MySuccessMessage = ({ header, message }) => (
  <Message positive>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

export const Loading = () => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>Just a moment</Message.Header>
      We are processing your request.
    </Message.Content>
  </Message>
);

export const MyErrorMessage = ({ header, message }) => (
  <Message negative>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);
