import React, { useState, useEffect } from 'react'
import {
    Segment,
    Form, Grid, Input, Button, Card, Loader,
} from 'semantic-ui-react'

import client from '../../helpers/Client';
import { BACKEND_URL } from '../../helpers/constants';
import { removeDoubleSlashes } from '../../helpers/urlhelper';

const emptyFormData = {
    title: "",
    file: "",
}
function Calendar() {
    const [data, setData] = useState(emptyFormData);
    const [calendar, setCalendar] = useState([]);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(false);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);
        let dest_url = `/api/calendar`;

        client.get(dest_url)
            .then((res) => {
                setCalendar(res.data.data);
                console.log('calendars data', res.data.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setFetching(false);
            });
    }, [request]);


    const archive = (id) => {

        let dest_url = `/user/calendar/archive`;

        console.log('calendar', data)
        client.post(dest_url, { id: id })
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => {
            });
        console.log(data);
        setRequest(!request)

    }

    const deleteCalendar = (id) => {

        let dest_url = `/user/calendar/delete`;

        console.log('calendar', data)
        client.post(dest_url, { id: id })
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => {
            });
        console.log(data);
        setRequest(!request)
    }

    const submit = (data) => {
        setLoading(true);

        let dest_url = `/user/calendar`;

        const body = new FormData();

        body.append('title', data.title);
        body.append('file', data['calendar']);

        console.log('on submit', data)
        client.post(dest_url, body)
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
                setData(emptyFormData);
                setRequest(!request);
            });

    }

    return (
        <Segment>
            <Segment>
                <Grid columns={2} padded>
                    <Grid.Column padded >
                        <h1>Add Calendar</h1>
                        <Form onSubmit={() => submit(data)} loading={loading}>

                            <Form.Field
                                required
                                onChange={(e) => {
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }}>
                                <label>Title</label>
                                <Input name="title" value={data.prename} placeholder='' required />
                            </Form.Field>
                            <Form.Field
                                onChange={e => {
                                    setData({ ...data, [e.target.name]: e.target.files[0] })
                                    console.log('file calendar', e.target.files[0])
                                }}
                            >
                                <label>Calendar</label>
                                <Input type="file" value={data.link} name="calendar" placeholder='pdf' required />
                            </Form.Field>

                            <Button positive type="submit" fluid><i className="far fa-upload"></i> Add Calendar</Button>
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        {
                            fetching && <Loader active inline='centered' size="massive" />
                        }
                        {
                            !fetching && calendar && (
                                <Card.Group>
                                    {calendar.map(c => (
                                        <Card key={c.id} fluid>
                                            <Card.Content>
                                                <Card.Header>Calendar</Card.Header>
                                                <Card.Description>
                                                    <a href={removeDoubleSlashes(BACKEND_URL + "/" + c.link)} rel='noreferrer' target='_blank'>{c.title}</a>
                                                    <p>{c.active === 0 ? "Archived" : ""}</p>
                                                </Card.Description>
                                                {
                                                    c.active === 1 && <Button positive onClick={() => archive(c.id)}>Archive</Button>
                                                }
                                                <Button onClick={() => deleteCalendar(c.id)}>Delete</Button>
                                            </Card.Content>
                                        </Card>
                                    ))}
                                </Card.Group>
                            )
                        }
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment>
    )
}

export default Calendar
