import React, { useState, useEffect } from "react";
import {
  Form,
  Grid,
  Input,
  Card,
  Loader,
  Button,
} from "semantic-ui-react";
import { Card as AntCard, Button as AntButton } from "antd";
import { DownSquareOutlined } from "@ant-design/icons";

import moment from "moment";

import client from "../../helpers/Client";

const emptyFormData = {
  assigned_date: "",
  file_title: "",
  last_date: "",
  file: "",
};

export const Tender = () => {
  const [data, setData] = useState(emptyFormData);
  const [publication, setPublication] = useState([]);
  // const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setFetching(true);
    let dest_url = "/api/tender/active";

    client
      .get(dest_url)
      .then((res) => {
        setPublication(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const archivePublication = (publication) => {
    setFetching(true);
    let dest_url = "/user/tender/archive";
    client
      .post(dest_url, publication)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        console.log("DONE");
        setRequest(!request);
      });
  };

  const submit = (data) => {
    // if (!checkError(data)) {
    setLoading(true);

    let dest_url = `/user/tender/add`;

    const body = new FormData();
    body.append("assigned_date", data.assigned_date);
    body.append("file_title", data.file_title);
    body.append("last_date", data.last_date);
    body.append("file", data["file"]);

    client
      .post(dest_url, body)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
        setRequest(!request);
      });

    console.log(data);
    // }
  };

  return (
    <Grid columns={2} stackable divided>
      <Grid.Column padded>
        <h1>Add Tenders</h1>
        <Form onSubmit={() => submit(data)} loading={loading}>
          <Form.Field
            required
            onChange={(e) => {
              setData({ ...data, [e.target.name]: e.target.value });
            }}
          >
            <label>Tender Title</label>
            <Input
              name="file_title"
              value={data.file_title}
              placeholder=""
              required
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
                console.log(e.target.value);
              }}
              name="assigned_date"
              label="Tender Start Date"
              value={data.assigned_date}
              type="datetime-local"
              required
            />

            <Form.Field
              name="last_date"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
              control={Input}
              label="Tender Last Date"
              value={data.last_date}
              min={data.assigned_date}
              type="datetime-local"
              placeholder="Last Date of submission"
              required
            />
          </Form.Group>

          <Form.Field
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.files[0] })
            }
          >
            <label>File</label>
            <Input
              type="file"
              value={data.link}
              name="file"
              placeholder="Tender File"
              required
            />
          </Form.Field>

          <Button positive type="submit" fluid>
            <i className="far fa-upload"></i> Upload Tender
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        {fetching && <Loader active inline="centered" size="massive" />}
        {!fetching && publication && (
          <Card.Group>
            {publication.map((pub) => (
              <AntCard
                key={pub.no}
                title={pub.notification}
                extra={
                  <a href={pub.link} target="_blank" rel="noopener noreferrer">
                    <i className="fal fa-external-link-alt"></i> View Tender
                  </a>
                }
                style={{
                  width: "100%",
                  marginTop: 5,
                  borderColor: "blue",
                  borderStyle: "dotted",
                }}
              >
                <p>
                  <b>Upload Date:</b>{" "}
                  {moment(pub.time).format("MMMM Do YYYY, h:mm:ss a")}
                </p>
                <p>
                  <b>Last Date:</b>{" "}
                  {moment(pub.end_time).format("MMMM Do YYYY, h:mm:ss a")}
                </p>
                <AntButton
                  type="primary"
                  onClick={() => archivePublication(pub)}
                  icon={<DownSquareOutlined />}
                  size={"large"}
                >
                  Archive Tender
                </AntButton>
              </AntCard>
            ))}
          </Card.Group>
        )}
      </Grid.Column>
    </Grid>
  );
};
