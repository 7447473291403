import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Segment,
  Icon,
  Grid,
  Loader,
  Card,
  Button,
  Form,
  Message,
} from "semantic-ui-react";
import {
  Card as AntCard,
  Form as AntForm,
  Input,
  Checkbox,
  Button as AntButton,
  Switch,
} from "antd";
import { Formik, Field, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import client from "../../helpers/Client";

var emptyFormData = {
  id: 0,
  title: "",
  path: "",
  highlight: 0,
  types: [],
  files: [],
};

const options = [
  { key: "1", label: "Student", value: "1" },
  { key: "2", label: "Office Order", value: "2" },
  { key: "3", label: "Tender", value: "3" },
  { key: "4", label: "Recruitment", value: "4" },
  { key: "5", label: "Announcement", value: "5" },
  { key: "6", label: "DeanOffice", value: "6" },
];

const MyToggleCheckBox = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <div className="ui toggle checkbox">
      <input type="checkbox" name="public" {...field} />
      <label>{label}</label>
      <br />
    </div>
  );
};

export const MyErrorMessage = ({ header, message }) => (
  <Message negative>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

export const MySuccessMessage = ({ header, message }) => (
  <Message positive>
    <Message.Header>{header}</Message.Header>
    <p>{message}</p>
  </Message>
);

const Notice = () => {
  const [success, setSuccess] = useState(0);
  const [message, setMessage] = useState("");
  const [attempted, setAttempt] = useState(0);
  const [notices, setNotices] = useState([]);
  const [flag, setFlag] = useState(false); // flag = true then Edit event else Add new event
  const [fetching, setFetching] = useState(false);
  const [request, setRequest] = useState(false);
  // const [open, setOpen] = useState(false)
  const [data, setData] = useState(emptyFormData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFetching(true);
    let dest_url = "/api/notices?admin=1";

    client
      .get(dest_url)
      .then((res) => {
        setNotices(res.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const deleteNotice = (notice) => {
    setFetching(true);
    let dest_url = `/user/notification/delete/${notice.id}`;
    client
      .post(dest_url, notice)
      .then((res) => alert(res.data.message))
      .catch((err) => console.log(err))
      .finally(() => {
        setRequest(!request);
      });
  };

  const changeArchiveStatus = (id, archieved) => {
    let dest_url = `/user/notification/archive/${id}`;
    client
      .post(dest_url, { status: !archieved, id })
      .then((res) => console.log("archive status changed", res))
      .catch((err) => console.log(err));
  };

  const setFieldValues = (currentNotice) => {
    var field_values = {
      id: currentNotice.id,
      title: currentNotice.title,
      path: currentNotice.path,
      highlight: currentNotice.highlight,
      types: [],
    };
    setData(field_values);
  };

  const editNotice = (values) => {
    setLoading(true);
    let dest_url = `/user/notification/edit`;
    if (values["types"][0] === "1" || values["types"][0] === "2") {
      dest_url = `${dest_url}/notices`;
    } else if (values["types"][0] === "3") {
      dest_url = `${dest_url}/tenders`;
    } else {
      dest_url = `${dest_url}/recruitments`;
    }

    const body = new FormData();
    body.append("id", data.id);
    body.append("title", values.title);
    body.append("path", data.path);
    body.append("highlight", values.highlight);
    body.append("notification_for", values["types"].join(","));

    for (let file of data.files) {
      body.append("file", file);
    }

    client
      .post(dest_url, body)
      .then((res) => {
        console.log(res);
        alert(res.data.message);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
        setFlag(false);
        setRequest(!request);
      });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required").max(256, "Max 256 Characters"),
    highlight: Yup.number()
      .required("Required")
      .min(1, "It should be Highlighted For atleast 1 Day")
      .max(25, "Max Highlighted For 25 Days"),
    notices: Yup.array().min(1, "You have to select one of the options.."),
    file: Yup.array().min(1, "You have to upload atleast one file"),
  });

  const getData = async (formData) => {
    let dest_url = `/user/notification/add`;
    if (formData["notices"][0] === "1" || formData["notices"][0] === "2") {
      dest_url = `${dest_url}/notices`;
    } else if (formData["notices"][0] === "3") {
      dest_url = `${dest_url}/tenders`;
    } else {
      dest_url = `${dest_url}/recruitments`;
    }

    const body = new FormData();
    body.append("title", formData.title);
    body.append("highlight", formData.highlight);
    body.append("notification_for", formData["notices"].join(","));
    for (let file of formData["files"]) {
      body.append("file", file);
    }
    const data = await client.post(dest_url, body);
    console.log(data.data);
    setSuccess(data.data.status);
    setMessage(data.data.message);
    setAttempt(1);
  };
  const fileInputRef = React.useRef();

  console.log(notices);
  return (
    <Segment>
      <Grid columns={2} divided>
        <Grid.Column>
          <Container>
            <Segment>
              <Header as="h2">
                <Icon name="settings" />
                <Header.Content>
                  {flag ? "Edit" : "Upload"} Notice
                </Header.Content>
              </Header>
            </Segment>
            <Segment>
              {flag ? (
                <>
                  {loading ? (
                    <Loader active inline="centered" size="massive" />
                  ) : (
                    <AntForm
                      name="Edit Notice"
                      initialValues={{
                        title: data.title,
                        highlight: data.highlight,
                      }}
                      onFinish={editNotice}
                      scrollToFirstError
                    >
                      <Header>Title</Header>
                      <AntForm.Item
                        name="title"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
                          size="large"
                          placeholder="Add Title about Notice...."
                        />
                      </AntForm.Item>
                      <Header>Highlight</Header>
                      <AntForm.Item
                        name="highlight"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
                          size="large"
                          placeholder="5 (Enter Number of days for which New flag will be available)"
                        />
                      </AntForm.Item>

                      <Header>Types</Header>
                      <AntForm.Item
                        name="types"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Checkbox.Group
                          onChange={(checkedValues) => {
                            console.log("values = ", checkedValues);
                          }}
                        >
                          {options.map((type) => (
                            <div style={{ margin: "5px" }} key={type.key}>
                              <Checkbox value={type.value} name="types">
                                <h3>{type.label}</h3>
                              </Checkbox>
                            </div>
                          ))}
                        </Checkbox.Group>
                      </AntForm.Item>

                      <Header>Upload</Header>
                      <Segment placeholder>
                        <Header icon>
                          <Icon name="file pdf outline" />
                          <AntForm.Item
                            // rules={[{ required: true, message: 'Add Document' }]}
                            name="files"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => {
                              console.log("Upload event:", e);

                              if (Array.isArray(e)) {
                                return e;
                              }

                              return e?.fileList;
                            }}
                          >
                            <AntButton
                              onClick={() => fileInputRef.current.click()}
                              size="large"
                            >
                              {" "}
                              Click to upload
                            </AntButton>
                            {data.files?.map((file, i) => (
                              <p key={i}>{file.name}</p>
                            ))}
                            <input
                              type="file"
                              name="file"
                              hidden
                              ref={fileInputRef}
                              placeholder="Click to Upload"
                              onChange={(e) => {
                                console.log([e.currentTarget.files[0]]);
                                setData({
                                  ...data,
                                  files: [e.currentTarget.files[0]],
                                });
                              }}
                              required
                            />
                          </AntForm.Item>
                        </Header>
                      </Segment>

                      <AntForm.Item>
                        <br />
                        <Button fluid positive size="big" type="submit">
                          <Icon name="checkmark" /> Edit
                        </Button>
                      </AntForm.Item>
                    </AntForm>
                  )}
                </>
              ) : (
                <Formik
                  initialValues={{
                    title: "",
                    highlight: 0,
                    notices: [],
                    files: [],
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting }) => {
                    setSubmitting(true);
                    console.log(data);
                    getData(data);
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <label>Details about Notice</label>
                      <br />
                      <Segment>
                        <Field
                          name="title"
                          label="Title"
                          placeholder="Add Title about Notice...."
                          type="input"
                          as={Form.TextArea}
                        />
                        <ErrorMessage
                          name="title"
                          header="Title"
                          message={errors.title}
                          component={MyErrorMessage}
                        />
                        <Field
                          name="highlight"
                          label="highlight"
                          placeholder="5 (Enter Number of days for which New flag will be available)"
                          as={Form.Input}
                        />
                        <ErrorMessage
                          name="highlight"
                          header="Highlight"
                          message={errors.highlight}
                          component={MyErrorMessage}
                        />
                      </Segment>
                      <label>Type of Notice</label>
                      <br />
                      <Segment>
                        {options.map((type) => (
                          <div key={type.key}>
                            <MyToggleCheckBox
                              label={type.label}
                              value={type.value}
                              name="notices"
                              type="checkbox"
                            />
                          </div>
                        ))}
                        <ErrorMessage
                          name="notices"
                          header="Notices"
                          message={errors.notices}
                          component={MyErrorMessage}
                        />
                      </Segment>
                      <label>Upload A File</label>
                      <Segment placeholder>
                        <Header icon>
                          {values?.files < 1 ? (
                            <>
                              <Icon name="pdf file outline" />
                              No documents are listed for this customer.
                            </>
                          ) : (
                            <ul>
                              <li
                                style={{ listStyle: "none", fontSize: "14px" }}
                              >
                                At max 3 files can only be uploaded at a time
                              </li>
                              <hr />
                              <br />
                              {values?.files?.map((file, i) => (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <div>{file.name}</div>
                                  <div
                                    onClick={(e) => {
                                      const files = values.files.filter(
                                        (file, index) => index !== i
                                      );
                                      setFieldValue("files", files);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    &#10060;
                                  </div>
                                </div>
                              ))}
                            </ul>
                          )}
                        </Header>
                        <Form.Button
                          primary
                          disabled={values.files.length === 3}
                          type="button"
                          onClick={() => fileInputRef.current.click()}
                        >
                          {!values?.files ? "Add Document" : "Add More"}
                        </Form.Button>
                        <input
                          ref={fileInputRef}
                          hidden
                          type="file"
                          name="files"
                          id="files"
                          onChange={(event) => {
                            console.log([
                              ...values?.files,
                              event.currentTarget.files[0],
                            ]);
                            setFieldValue("files", [
                              ...values.files,
                              event.currentTarget.files[0],
                            ]);
                          }}
                        />
                      </Segment>
                      <ErrorMessage
                        name="file"
                        header="File"
                        message={errors.files}
                        component={MyErrorMessage}
                      />
                      <Segment>
                        <Form.Button
                          fluid
                          primary
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Form.Button>
                      </Segment>
                      {success ? (
                        <MySuccessMessage header="Notice" message={message} />
                      ) : (
                        attempted && (
                          <MyErrorMessage header="Notice" message={message} />
                        )
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </Segment>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <div style={{ overflowY: "auto", height: "100vh" }}>
            {fetching && <Loader active inline="centered" size="massive" />}
            {!fetching && notices && (
              <Card.Group>
                {notices.map((notice) => (
                  <AntCard
                    key={notice.id}
                    title={notice.id}
                    extra={
                      <a
                        href={notice.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fal fa-external-link-alt"></i> View Notice
                      </a>
                    }
                    style={{
                      width: "100%",
                      marginTop: 5,
                      borderColor: "blue",
                      borderStyle: "dotted",
                    }}
                  >
                    <center>
                      <Header>{notice.title}</Header>
                      <p>
                        <b>Highlight:</b> {notice.highlight}
                      </p>
                      <p>
                        <b>Types:</b>{" "}
                        {notice.notification_for.map((notice_type) => (
                          <span
                            key={notice_type}
                            className=""
                            style={{
                              background: "#274086",
                              color: "white",
                              borderRadius: "0.25rem",
                              marginLeft: "1%",
                              padding: "1%",
                            }}
                          >
                            {notice_type}
                          </span>
                        ))}
                      </p>

                      <div
                        style={{
                          marginBottom: "1rem",
                        }}
                      >
                        <label for="archived" style={{ marginRight: "1rem" }}>
                          Archived
                        </label>
                        <Switch
                          defaultChecked={!notice.status}
                          name="archived"
                          id="archived"
                          onChange={(e) => {
                            changeArchiveStatus(notice.id, e);
                          }}
                        />
                      </div>

                      <Button
                        positive
                        onClick={() => {
                          setFlag(true);
                          setFieldValues(notice);
                        }}
                        size="medium"
                      >
                        <i className="fas fa-edit"></i> Edit
                      </Button>
                      <Button
                        negative
                        size="medium"
                        onClick={() => {
                          if (window.confirm("Are You Sure?")) {
                            deleteNotice(notice);
                          }
                        }}
                      >
                        <i className="fal fa-trash-alt"></i> Delete
                      </Button>
                    </center>
                  </AntCard>
                ))}
              </Card.Group>
            )}
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default Notice;
