import React, { useState } from 'react';
import { Header, Icon, Segment, Form, Message } from 'semantic-ui-react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import client from '../../helpers/Client';

const programs = [
    { key: '1', text: 'UG', value: 'UG' },
    { key: '2', text: 'PG', value: 'PG' },
    { key: '3', text: 'PhD.', value: 'PhD.' },
];

const branches = [
    { key: '1', text: 'mca', value: 'CA' },
    { key: '2', text: 'chem', value: 'CH' },
    { key: '3', text: 'civil', value: 'CE' },
    { key: '4', text: 'cs', value: 'CS' },
    { key: '5', text: 'ece', value: 'EC' },
    { key: '6', text: 'eee', value: 'EE' },
    { key: '7', text: 'humanities', value: 'HU' },
    { key: '8', text: 'maths', value: 'MA' },
    { key: '9', text: 'mech', value: 'ME' },
    { key: '10', text: 'meta', value: 'MM' },
    { key: '11', text: 'phys', value: 'PH' },
    { key: '12', text: 'prod', value: 'PI' },
];

const degrees = [
    { key: '1', text: 'B.Tech', value: 'B.Tech' },
    { key: '2', text: 'M.Tech', value: 'M.Tech' },
    { key: '3', text: 'PhD.', value: 'PhD.' },
];


export const MySuccessMessage = ({ header, message }) => (
    <Message positive>
        <Message.Header>{header}</Message.Header>
        <p>{message}</p>
    </Message>
);

const Loading = () => (
    <Message icon>
        <Icon name='circle notched' loading />
        <Message.Content>
            <Message.Header>Just one second</Message.Header>
    We are Submitting the TimeTable.
    </Message.Content>
    </Message>
)

const MyErrorMessage = ({ header, message }) => (
    <Message negative>
        <Message.Header>{header}</Message.Header>
        <p>{message}</p>
    </Message>
);

export const TimeTable = () => {
    const [result, setResult] = useState({
        success: 0,
        failure: 0
    });
    const [message, setMessage] = useState("");
    const [attempted, setAttempt] = useState(0);

    const validationSchema = Yup.object({
        program: Yup.string().required('Required Field'),
        branch: Yup.string().required('Required Field'),
        degree: Yup.string().required('Required Field'),
        year: Yup.string().required('Required Field'),
        semester: Yup.string().required('Required Field'),
        file: Yup.mixed().required('Required To be Uploaded'),
    });

    const fileInputRef = React.useRef();

    const getData = async (formData) => {
        let dest_url = `/user/time_table`;
        let body = new FormData();

        console.log(formData);

        body.append('program', formData.program);
        body.append('branch', formData.branch);
        body.append('degree', formData.degree);
        body.append('year', formData.year);
        body.append('semester', formData.semester);
        body.append('program_name', formData.program_name);
        body.append('file', formData['file']);

        setAttempt(1);
        const data = await client.post(dest_url, body);
        console.log(data.data.status)
        setAttempt(0);
        if (data.data.status === 0)
            setResult({
                failure: 1,
                success: 0,
            });
        else
            setResult({
                success: 1,
                failur: 0
            });
        setMessage(data.data.message);
    };

    return (
        <Segment>
            <Header as="h2">
                <Icon name="settings" />
                <Header.Content>
                    TimeTable Upload
            <Header.Subheader>Upload a TimeTable...</Header.Subheader>
                </Header.Content>
            </Header>
            <Segment>
                <Formik
                    initialValues={{
                        program: '',
                        branch: '',
                        degree: '',
                        year: '',
                        semester: '',
                        program_name: '',
                        file: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        getData(data);
                        setSubmitting(false);
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <label>Details About Timetable</label>
                            <br />
                            <Segment>
                                <Form.Group widths="equal">
                                    <Form.Select
                                        fluid
                                        label="Program"
                                        name="program"
                                        options={programs}
                                        placeholder="Program"
                                        onChange={(e) => {
                                            setFieldValue('program', e.target.childNodes[0].textContent);
                                        }}
                                    />
                                    <Form.Select
                                        fluid
                                        label="Branch"
                                        name="branch"
                                        options={branches}
                                        placeholder="Branch"
                                        onChange={(e) => {
                                            setFieldValue('branch', e.target.childNodes[0].textContent);
                                        }}
                                    />
                                    <Form.Select
                                        fluid
                                        label="Degree"
                                        name="degree"
                                        options={degrees}
                                        placeholder="Degree"
                                        onChange={(e) => {
                                            setFieldValue('degree', e.target.childNodes[0].textContent);
                                        }}
                                    />
                                </Form.Group>
                                <ErrorMessage
                                    name="program"
                                    header="Program"
                                    message={errors.program}
                                    component={MyErrorMessage}
                                />
                                <ErrorMessage
                                    name="branch"
                                    header="Pre-Branch"
                                    message={errors.branch}
                                    component={MyErrorMessage}
                                />
                                <ErrorMessage
                                    name="degree"
                                    header="Degree"
                                    message={errors.degree}
                                    component={MyErrorMessage}
                                />
                                {(values.program !== "UG") && <Field
                                    fluid
                                    label="Program Name"
                                    name="program_name"
                                    placeholder="M.Tech CSE in computer Security"
                                    as={Form.Input}
                                />}
                                <Field
                                    fluid
                                    label="Year"
                                    name="year"
                                    placeholder="20XX"
                                    as={Form.Input}
                                />
                                <Field
                                    fluid
                                    label="Semester"
                                    name="semester"
                                    placeholder="4"
                                    as={Form.Input}
                                />
                                <ErrorMessage
                                    name="program_name"
                                    header="Program Name Error"
                                    message={errors.program_name}
                                    component={MyErrorMessage}
                                />
                                <ErrorMessage
                                    name="year"
                                    header="Year Error"
                                    message={errors.year}
                                    component={MyErrorMessage}
                                />
                                <ErrorMessage
                                    name="semester"
                                    header="Semester Error"
                                    message={errors.semester}
                                    component={MyErrorMessage}
                                />
                            </Segment>
                            <label>Upload TimeTable</label>
                            <Segment placeholder>
                                <Header icon>
                                    <Icon name="user" />
                                    {!values.file
                                        ? 'No Timetable is listed'
                                        : values.file.name}
                                </Header>
                                <Form.Button
                                    primary
                                    type="button"
                                    onClick={() => fileInputRef.current.click()}
                                >
                                    {!values.file ? 'Upload TimeTable' : 'Replace TimeTable'}
                                </Form.Button>
                                <input
                                    ref={fileInputRef}
                                    hidden
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={(event) => {
                                        setFieldValue('file', event.currentTarget.files[0]);
                                    }}
                                />
                                <ErrorMessage
                                    name="file"
                                    header="File"
                                    message={errors.file}
                                    component={MyErrorMessage}
                                />
                            </Segment>
                            <Segment>
                                <Form.Button
                                    fluid
                                    primary
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Form.Button>
                            </Segment>
                            {
                                attempted ? (<Loading />) : null
                            }
                            {
                                result.success ? (<MySuccessMessage
                                    header="TimeTable"
                                    message={message}
                                />) : null
                            }
                            {
                                result.failure ? (<MyErrorMessage
                                    header="TimeTable"
                                    message={message}
                                />) : null
                            }
                            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                            <pre>
                                {values.file &&
                                    JSON.stringify(
                                        {
                                            fileName: values.file.name,
                                            type: values.file.type,
                                            size: `${values.file.size} bytes`,
                                        },
                                        null,
                                        2,
                                    )}
                            </pre> */}
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Segment>
    );
}