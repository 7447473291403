export const forList = [
    {
        key:'1',
        text:'Gallery',
        value:'GL'
    }
];

export const sizeList = [
    {
        value:'banner',
        key:'1',
        text:'Banner'
    },
    {
        value:'largeBanner',
        key:'2',
        text:'Large Banner'
    },
    {
        value:'square',
        key:'3',
        text:'Square'
    },
    {
        value:'largeSquare',
        key:'4',
        text:'Large Square'
    },
    {
        value:'rectangle',
        key:'5',
        text:'Reactangle'
    },
    {
        value:'largeRectangle',
        key:'6',
        text:'Large Rectangle'
    }
]

export const suggestionTags = [
    {
        id:1,
        text:'independence-day'
    },
    {
        id:2,
        text:'nitjsr'
    },
    {
        id:3,
        text:'culfest'
    },
    {
        id:4,
        text:'ojass'
    },
    {
        id:5,
        text:'convocation'
    },
    {
        id:6,
        text:'summit'
    },
    {
        id:7,
        text:'urja'
    },
    {
        id:8,
        text:'republic-day'
    },
    {
        id:9,
        text:'orientation'
    },
    {
        id:10,
        text:'workshop'
    },
    {
        id:11,
        text:'guest-lecture'
    },
    {
        id:12,
        text:'campus'
    },
    {
        id:13,
        text:'vsg'
    },
    {
        id:14,
        text:'tsg'
    },
    {
        id:15,
        text:'old-building'
    },
    {
        id:16,
        text:'new-building'
    },
    {
        id:17,
        text:'hostel'
    },
    {
        id:18,
        text:'mega-hostel'
    },
    {
        id:19,
        text:'mess'
    },
    {
        id:20,
        text:'mega-mess'
    },
    {
        id:21,
        text:'computer-center'
    },
    {
        id:22,
        text:'cc'
    },
    {
        id:23,
        text:'downs'
    },
    {
        id:24,
        text:'ups'
    },
    {
        id:25,
        text:'library'
    },
    {
        id:26,
        text:'event'
    },
    {
        id:27,
        text:'latex'
    },
    {
        id:28,
        text:'baja-sae'
    },
    {
        id:29,
        text:'bhoomi-pujan'
    },
    {
        id:30,
        text:'industry'
    },
    {
        id:31,
        text:'alumni'
    },
    {
        id:32,
        text:'meet'
    },
    {
        id:33,
        text:'old-day'
    },
    {
        id:34,
        text:'reunion'
    },
    {
        id:34,
        text:'athletics'
    },
    {
        id:35,
        text:'sports'
    },
    {
        id:36,
        text:'clubs'
    },
    {
        id:37,
        text:'society'
    },
    {
        id:38,
        text:'cds'
    },
    {
        id:39,
        text:'ces'
    },
    {
        id:40,
        text:'mes'
    },
    {
        id:41,
        text:'faces'
    },
    {
        id:42,
        text:'nss'
    },
    {
        id:43,
        text:'revanta'
    },
    {
        id:44,
        text:'phoenix'
    },
    {
        id:45,
        text:'sece'
    },
    {
        id:46,
        text:'daksh'
    },
    {
        id:47,
        text:'top-guns'
    },
    {
        id:48,
        text:'values-and-ethics-club'
    },
    {
        id:49,
        text:'csi'
    },
    {
        id:50,
        text:'drift'
    },
    {
        id:51,
        text:'lds'
    },
    {
        id:52,
        text:'qunite'
    },
    {
        id:53,
        text:'academic'
    },
    {
        id:54,
        text:'departments'
    },
    {
        id:55,
        text:'old-photo'
    },
    {
        id:56,
        text:'technica'
    },
    {
        id:57,
        text:'yoga-day'
    }
]