import React, { useState, useEffect } from "react";
import {
  Form,
  Grid,
  Input,
  Button,
  Card,
  Loader,
  Dropdown,
  TextArea,
} from "semantic-ui-react";
import { useAlert } from "react-alert";
import client from "../../helpers/Client";
import { Card as AntCard, Button as AntButton, Result } from "antd";

const ThesisSupervised = () => {
  const emptyForm = {
    research_topic: "",
    student_name: "",
    completion_year: "",
    heading: "",
  };

  const heading = [
    { key: "1", text: "Phd. Completed", value: "1" },
    { key: "2", text: "Phd. Ongoing", value: "0" },
    { key: "3", text: "Masters", value: "2" },
    { key: "4", text: "Bachelors", value: "3" },
  ];

  const [data, setData] = useState(emptyForm);
  const [thesissupervised, setThesissupervised] = useState([]);
  const [request, setRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    let dest_url = "/faculty/thesissupervised/all";

    client
      .post(dest_url)
      .then((res) => {
        console.log(res.data);
        setThesissupervised(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [request]);

  const deleteCourse = (item) => {
    setLoading(true);
    let dest_url = "faculty/thesissupervised/delete";
    client
      .post(dest_url, { research_topic: item.research_topic, id: item.id })
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setRequest(!request);
        setLoading(false);
      });
  };

  const submit = () => {
    console.log(data);
    setLoading(true);
    if (!data.research_topic) {
      alert.error("Missing Required field");
      return;
    }
    let dest_url = "faculty/thesissupervised";
    client
      .post(dest_url, data)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setRequest(!request);
        setLoading(false);
        setData(emptyForm);
      });
  };

  return (
    <Grid columns={2} divided>
      <Grid.Column>
        <h1>Add Thesis Supervised</h1>
        <Form onSubmit={() => submit(data)} loading={loading}>
          <Form.Field required>
            <label>Heading </label>
            <Dropdown
              onChange={(e, val) => {
                setData({ ...data, heading: val.value });
              }}
              name="heading"
              placeholder="Select any one"
              search
              selection
              options={heading}
            />
          </Form.Field>

          <Form.Field
            required
            onChange={(e) => {
              setData({ ...data, [e.target.name]: e.target.value });
            }}
          >
            <label>Research Topic</label>
            <TextArea
              name="research_topic"
              placeholder="Research Topic"
              value={data.research_topic}
              required
            />
          </Form.Field>

          <Form.Field
            onChange={(e) => {
              setData({ ...data, [e.target.name]: e.target.value });
            }}
          >
            <label>Student Name</label>
            <Input
              value={data.student_name}
              name="student_name"
              placeholder="Student Name"
            />
          </Form.Field>

          <Form.Field
            onChange={(e) => {
              setData({ ...data, [e.target.name]: e.target.value });
            }}
          >
            <label>Year of Completion</label>
            <Input
              value={data.completion_year}
              name="completion_year"
              placeholder="Year of Completion"
            />
          </Form.Field>

          <Button positive type="submit" fluid>
            <i className="far fa-add"></i> Add
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        <div style={{ overflowY: "auto", height: "100vh" }}>
          {loading && <Loader active inline="centered" size="massive" />}
          {!loading && (
            <>
              {thesissupervised.length ? (
                <h1 className="text-center mb-5">Thesis Supervised</h1>
              ) : (
                <Result title="Please Add Thesis Supervised" />
              )}
              <Card.Group>
                {thesissupervised.map((item, key) => (
                  <AntCard
                    className="ant-col ant-col-xs-24 ant-col-xl-8"
                    title={item.research_topic}
                    style={{ width: 300 }}
                    key={key}
                    extra={
                      <AntButton
                        type="danger"
                        shape="circle"
                        onClick={() => {
                          if (window.confirm("Are You Sure?")) {
                            deleteCourse(item);
                          }
                        }}
                      >
                        <i className="fal fa-trash-alt"></i>
                      </AntButton>
                    }
                  >
                    <p className="text-center">
                      Name of Student: {item.name_of_student}
                      <br />
                      Completion Year: {item.completion_year}
                      <br />
                    </p>
                  </AntCard>
                ))}
              </Card.Group>
            </>
          )}
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default ThesisSupervised;
