import React, { Component } from "react";
import { Table, Loader, Dimmer, Container } from "semantic-ui-react";
import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const LogCard = (props) => {
  return (
    <Table.Row>
      <Table.Cell>{props.user.ip}</Table.Cell>
      <Table.Cell>{props.user.message}</Table.Cell>
      <Table.Cell>{props.user.timestamp}</Table.Cell>
    </Table.Row>
  );
};
export default class FacultyLog extends Component {
  state = {
    new_log_count: "50",
    new_email: "",
    log_data: "",
    loading: true,
    button_loader: false,
  };

  async fetch_faculty_log() {
    try {
      var new_log_count = this.state.new_log_count;
      var { user } = JwtDecode(cookies.get("token"));

      const response = await client.post("/user/log", {
        email: user,
        log_count: new_log_count,
      });
      console.log(response);

      this.setState({ log_data: response.data.response });
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
      alert("Loading Failed");
    }

    return false;
  }
  render() {
    if (this.state.loading) {
      this.fetch_faculty_log();
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    } else
      return (
        <Container>
          <br />
          <br />
          <Table fixed color="green">
            <Table.Header style={{ maxWidth: "150px" }}>
              <Table.Row>
                <Table.HeaderCell>IP</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.log_data &&
                this.state.log_data.map(function (log_data, i) {
                  return <LogCard user={log_data} key={i} />;
                })}
            </Table.Body>
          </Table>
        </Container>
      );
  }
}
