import React from 'react';
import { Container, Header, Icon, Segment, Form } from 'semantic-ui-react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { MyErrorMessage } from './Notification';

const options = [
  { key: '1', text: 'Dr.', value: '1' },
  { key: '2', text: 'Mr.', value: '2' },
  { key: '3', text: 'Mrs.', value: '3' },
];

const validationSchema = Yup.object({
  pre: Yup.string().required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  contact: Yup.string()
    .matches(
      '\\+91[1-9][0-9]{9}',
      'Number should start with +91 and have next 10 digits as number',
    )
    .max(13)
    .required('Contact Should not be left Empty'),
  email: Yup.string().email('Not valid email').required('Required'),
  department: Yup.string().required('Required Field'),
  designation: Yup.string().required('Required Field'),
  address: Yup.string().required('Address is required'),
});

export const AddPhoneBook = () => {
  return (
    <Container>
      <Segment>
        <Header as="h2">
          <Icon name="address book" />
          <Header.Content>
            Profile Upload
            <Header.Subheader>Upload your Info...</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment>
        <Formik
          initialValues={{
            pre: '',
            firstName: '',
            lastName: '',
            contact: '',
            email: '',
            department: '',
            designation: '',
            address: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            console.log(data);
            setSubmitting(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Segment>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label="Pre"
                    name="pre"
                    options={options}
                    placeholder="Pre-Name"
                    onChange={(e) => {
                      setFieldValue('pre', e.target.childNodes[0].textContent);
                    }}
                  />
                  <Field
                    fluid
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                    as={Form.Input}
                  />
                  <Field
                    fluid
                    label="Last name"
                    placeholder="Last name"
                    name="lastName"
                    as={Form.Input}
                  />
                </Form.Group>
                <ErrorMessage
                  name="pre"
                  header="Pre-Name"
                  message={errors.pre}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="firstName"
                  header="First-Name Error"
                  message={errors.firstName}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="lastName"
                  header="last-Name Error"
                  message={errors.lastName}
                  component={MyErrorMessage}
                />
                <Field
                  fluid
                  label="Contact"
                  name="contact"
                  placeholder="+919192631770"
                  as={Form.Input}
                />
                <Field
                  fluid
                  label="Email"
                  name="email"
                  placeholder="a@g.com"
                  as={Form.Input}
                />
                <ErrorMessage
                  name="contact"
                  header="Contact Error"
                  message={errors.contact}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="email"
                  header="Email Error"
                  message={errors.email}
                  component={MyErrorMessage}
                />
              </Segment>
              <Segment>
                <Field
                  fluid
                  label="Department"
                  name="department"
                  placeholder="Department"
                  as={Form.Input}
                />
                <Field
                  fluid
                  label="Designation"
                  placeholder="Designation"
                  name="designation"
                  as={Form.Input}
                />
                <Field
                  label="Office Address"
                  placeholder="Address"
                  name="address"
                  as={Form.TextArea}
                />
                <ErrorMessage
                  name="department"
                  header="Department Field Error"
                  message={errors.department}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="designation"
                  header="Designation Field Error"
                  message={errors.designation}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="address"
                  header="Addess Field Error"
                  message={errors.address}
                  component={MyErrorMessage}
                />
              </Segment>
              <Segment>
                <Form.Button
                  fluid
                  primary
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Form.Button>
              </Segment>
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  );
};