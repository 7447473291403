import React, { useEffect, useState } from "react";
import {
  Segment,
  Container,
  Grid,
  Form,
  Image,
  Input,
} from "semantic-ui-react";
import client from "../../helpers/Client";
import { BACKEND_URL } from "../../helpers/constants";
import { Card,Skeleton } from "antd";
import { removeDoubleSlashes } from "../../helpers/urlhelper";

export const DashBoard = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let dest_url = "faculty/myProfile";
    client
      .post(dest_url)
      .then((res) => {
        let shown = {};
        if(res.data[0]){
        let {
          prename,
          fname,
          lname,
          email,
          department,
          designation,
          profile,
        } = res.data[0];
        shown["Name"] = `${prename} ${fname} ${lname}`;
        shown["Email"] = `${email}`;
        shown["Department"] = `${department}`;
        shown["Designation"] = `${designation}`;
        shown["Image"] = `${profile}`;
        // shown["Image"] = `https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80`
        setProfile(shown);
        setLoading(false);
      }else{
        setLoading(false);
      }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container>
      {!loading ? (
        <>
          {!profile ? (
            <Segment>Update Profile To See Details</Segment>
          ) : (
            <Segment>
              <Grid columns="two" divided>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Image
                      src={removeDoubleSlashes(BACKEND_URL + "/" + profile["Image"])}
                      size="medium"
                    />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Form>
                      {Object.keys(profile).map((key) => {
                        if (key !== "Image") {
                          return (
                            <Form.Field key={key}>
                              <label>{key}</label>
                              <Input
                                type="text"
                                value={profile[key]}
                                transparent={true}
                                readOnly={true}
                              />
                            </Form.Field>
                          );
                        } else return null;
                      })}
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}
        </>
      ) : (
        <Card style={{ width: '100%', marginTop: 16 }}>
          <Skeleton loading={true} avatar active></Skeleton>
        </Card>
      )}

      <Segment>
        <h1 style={{ marginTop: "50px" }}>Instructions</h1>
        <hr></hr>
        <ul>
          <li>
            A faculty member (Professor/ Associate Professor/ Assistant
            Professor (regular/ on contact/ Ad-hoc)) is solely responsible for
            the information displayed on his profile page of the institute
            website. The institute or the P/I website or coordinator website
            will never be responsible for the said information.
          </li>
          <li>
            Change your password time to time and don't disclose it to anyone.
          </li>
          <li>Keep some strong password which is not possible to guess.</li>
          <li>
            Strictly Avoid using special character in the website (in the file
            name you are uploading or at the time of inserting any text).
          </li>
          <li>Insert a comma between two phone numbers.</li>
          <li>File format of image should be jpg, png, gif, bmp only</li>
          <li>
            File format of course handout, notes and assignment shout be
            pdf,doc,docx,ppt,pptx only
          </li>
          <li>Size of image should be less than 1MB.</li>
          <li>
            The filenames MUST NOT contain the following characters anywhere: #
            , . / \ $
          </li>
          <li style={{ color: "green" }}>
            Please Update Your Profile Before proceeding to other sections
          </li>
          <li style={{ color: "green" }}>
            If you feel any issue kindly refresh the page & try again
          </li>
        </ul>
        <small>
          Kindly mail your valuable comment/ suggestion/ feedback to{" "}
          <a href="mailto:webmaster@nitjsr.ac.in">webmaster@nitjsr.ac.in</a>
        </small>
      </Segment>
    </Container>
  );
};
