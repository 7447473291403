import React from 'react';
import { suggestionTags } from './const';
import { taggify,handleKeyPress,addTag } from './logic';
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem';
import { TagWrapper,SuggestionWrapper } from './styles'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdorment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    formControl:{
        minWidth:128,
    }
}));

const Tags = (props) => {
    const {tags,setTags} = props;
    const init_suggestions = taggify(suggestionTags);
    const [input_tag,setInput_tag] = React.useState('');
    const classes = useStyles();
    // console.log(init_suggestions)
    return ( 
        <TagWrapper>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="tags-edit-txt" >Tags</InputLabel>
                    <Input
                        id="tags-edit-txt"
                        type="text"
                        autoComplete="off"
                        onChange={e=>setInput_tag(e.target.value)}
                        onKeyDown= {handleKeyPress(tags,{id:input_tag,text:input_tag},setTags,setInput_tag)}
                        startAdornment={<InputAdorment>#&nbsp;</InputAdorment>}
                    />
                </FormControl>
                <SuggestionWrapper>
                        <Paper id="suggestion_list_gallery_tags">
                            {
                                (input_tag.trim().length === 0?[]:init_suggestions).filter(suggestion => suggestion.text.trim().includes(input_tag))
                                                .map((suggestion,index) => <MenuItem onClick={addTag(tags,setTags,suggestion.text,setInput_tag)} value={suggestion.text} key={`${suggestion.id}${index}`}>{suggestion.text}</MenuItem>)
                            }
                        </Paper>
                </SuggestionWrapper>
            </div>
        </TagWrapper>
     );
}
 
export default Tags;