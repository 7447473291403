import React, { useState, useEffect } from "react";
import { Grid, Form, Input, Card, Loader, Button } from "semantic-ui-react";
import { BACKEND_URL } from "../../helpers/constants";
import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { useAlert } from "react-alert";
import { Card as AntCard, Button as AntButton, Result } from "antd";
import { removeDoubleSlashes } from "../../helpers/urlhelper";

const emptyFormData = {
  course_id: "",
  year: "",
  course_name: "",
};

const semester_list = [
  { text: "I", value: "I" },
  { text: "II", value: "II" },
  { text: "III", value: "III" },
  { text: "IV", value: "IV" },
  { text: "V", value: "V" },
  { text: "VI", value: "VI" },
  { text: "VII", value: "VII" },
  { text: "VIII", value: "VIII" },
];

const Publication = () => {
  const alert = useAlert();
  const [data, setData] = useState(emptyFormData);
  const [courses, setCourses] = useState([]);

  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setFetching(true);
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { uid } = JwtDecode(token);
    let dest_url = "/api/faculty_course?id=" + uid;
    client
      .get(dest_url)
      .then((res) => {
        console.log(res.data.result);
        setCourses(res.data.result);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);
  const refreshPage = () => {
    window.location.reload();
  };

  const deleteCourse = (courses) => {
    setFetching(true);
    let dest_url = "faculty/delete_course";
    client
      .post(dest_url, courses)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setRequest(!request);
      });
  };

  const submit = (data) => {
    let dest_url = "/faculty/course";

    const formData = new FormData();
    formData.append("course_id", data.course_id);
    formData.append("year", data.year);
    formData.append("course_name", data.course_name);
    formData.append("file", file);

    if (!data.year || !data.course_id || !data.course_name) {
      alert.error("Missing Required field");
      return;
    }
    var format = /[!@#$%^&*()_+\-=[]{};':"\\| ,.<>\/?]+/;

    if (format.test(data.course_id) || data.course_id.includes(" ")) {
      alert.error(
        <div style={{ textTransform: "initial" }}>
          Course Id shouldn't contain any special character or space
        </div>
      );

      return;
    }
    setLoading(true);

    client
      .post(dest_url, formData)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
        setRequest(!request);
        setFile(null);
        refreshPage(); //Added because unable to set dropdown value null after adding
      });
    console.log(data);
    alert.success(
      <div style={{ textTransform: "initial" }}>Course Added Successfully</div>
    );
  };

  return (
    <Grid columns={2} relaxed="very" stackable divided>
      <Grid.Column>
        <h1>Course</h1>
        <Form onSubmit={() => submit(data)} loading={loading}>
          <Form.Field
            required
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          >
            <label>Course Name</label>
            <Input
              value={data.course_name}
              name="course_name"
              placeholder="Course Name"
              required
            />
          </Form.Field>
          <Form.Field
            required
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
          >
            <label>Course Code</label>
            <Input
              value={data.course_id}
              name="course_id"
              placeholder="Course Id"
              required
            />
          </Form.Field>
          <Form.Select
            fluid
            label="Semester"
            name="year"
            options={semester_list}
            placeholder="Semester"
            onChange={(e, selected) => {
              setData({ ...data, year: selected.value });
            }}
            required
          />
          <Form.Field
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          >
            <label>Course Handout</label>
            <Input
              type="file"
              value={data.link}
              name="file"
              placeholder="Course Handout"
              accept=".doc,.docx,.pdf,.ppt,.pptx"
            />
          </Form.Field>
          <Button positive type="submit" fluid>
            Save
          </Button>
        </Form>
      </Grid.Column>
      <Grid.Column>
        {fetching && <Loader active inline="centered" size="massive" />}
        {!fetching && (
          <>
            {courses.length ? (
              <h1 className="text-center mb-5">My Courses</h1>
            ) : (
              <Result title="Please Add Course you teach" />
            )}
            <Card.Group>
              {courses.map((cor, key) => (
                <AntCard
                  className="ant-col ant-col-xs-24 ant-col-xl-8"
                  title={cor.course_name}
                  style={{ width: 300 }}
                  key={key}
                  extra={
                    <AntButton
                      type="danger"
                      shape="circle"
                      onClick={() => {
                        if (window.confirm("Are You Sure?")) {
                          deleteCourse(cor);
                        }
                      }}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </AntButton>
                  }
                >
                  <p className="text-center">
                    Semester: {cor.year}
                    <br />
                    Course ID: {cor.course_id}
                    <br />
                    {cor.course_handout &&
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={removeDoubleSlashes(`${BACKEND_URL}/${cor.course_handout}`)}
                    >
                      <i className="fal fa-file-pdf"></i> Course Handout
                    </a>}
                  </p>
                </AntCard>
              ))}
            </Card.Group>
          </>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Publication;
