import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Modal,
} from "@material-ui/core";
import { useFormik } from "formik";
import { Branches, fetchStats, YEAR } from "./logic";
import React from "react";
import { useStatsStyle } from "./styles";
import StatsModal from "./statModal";

/**
 *
 * @param {*} props
 * @returns
 */
const TapStats = (props) => {
  const classes = useStatsStyle();
  const [tapStats, setTapStats] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const filterFormik = useFormik({
    initialValues: {
      year: 2021,
      branch: "ALL",
    },
    onSubmit: (values) => {
      fetchStats(values.year, values.branch, setTapStats);
    },
  });

  /**
   * @effects
   */

  /**
   * cnsole.logs
   */
  console.log(tapStats);

  /**
   * @methods
   */
  /**
   * @description handles modal close action
   * @param {*} e
   * @returns void
   */
  const handleModalClose = (e) => {
    setModal(false);
    return;
  };

  /**
   * @description handle modal open action
   * @param {*} e
   * @returns void
   */
  const handleModalOpen = (e) => {
    setModal(true);
    return;
  };

  /**
   * @view
   */
  return (
    <>
      <div>
        <form onSubmit={filterFormik.handleSubmit}>
          <Grid container direction="row" justify="space-between">
            <Grid xs={3} item>
              <FormControl fullWidth>
                <InputLabel id="year-id">Year</InputLabel>
                <Select
                  fullWidth
                  value={filterFormik.values.year}
                  name="year"
                  onChange={filterFormik.handleChange}
                  labelId="year-id"
                >
                  {YEAR.map((crs) => (
                    <MenuItem key={crs} value={crs}>
                      {crs}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={4} item>
              <FormControl fullWidth>
                <InputLabel id="branch-id">Branch</InputLabel>
                <Select
                  fullWidth
                  value={filterFormik.values.branch}
                  name="branch"
                  onChange={filterFormik.handleChange}
                  labelId="branch-id"
                >
                  <MenuItem value="ALL">All branch</MenuItem>
                  {Branches.UG.map((crs) => (
                    <MenuItem key={crs.key} value={crs.key}>
                      {crs.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleModalOpen} color="primary">
                Add new stat
              </Button>
            </Grid>
          </Grid>
        </form>
        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sl. No.</TableCell>
                <TableCell align="center">Company</TableCell>
                <TableCell align="center">Selects</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tapStats.map((ts, idx) => (
                <TableRow key={ts.id}>
                  <TableCell scope="row">{idx + 1}</TableCell>
                  <TableCell align="center">{ts?.company}</TableCell>
                  <TableCell align="center">{ts?.final}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        open={modal}
        className={classes.modal}
      >
        <div>
          <StatsModal handleClose={handleModalClose} />
        </div>
      </Modal>
    </>
  );
};

export default TapStats;
