import React, { useState } from "react";
import client from "../../helpers/Client";
import { Button, Form, Grid, Input, Loader, Segment } from "semantic-ui-react";
import {
  MyErrorMessage,
  MySuccessMessage,
} from "../../components/AlertMessage";
import ImageUploader from "react-images-upload";

const emptyFormData = {
  title: "",
  desc: "",
  file: "",
};

const AlumniCorner = () => {
  const [data, setData] = useState(emptyFormData);
  const [allowUpload, setAllowUpload] = useState(false);
  const [selectedPic, setSelectedPic] = useState(null);
  const [pictureError, setPictureError] = useState(null);
  const [result, setResult] = useState({ success: 0, failure: 0 });
  const [loading, setLoading] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setResult({ success: 0, failure: 0 });

    const dest_url = `/user/alumunicorner/add`;
    const body = new FormData();
    body.append("title", data.title);
    body.append("desc", data.desc);
    body.append("file", selectedPic);

    client
      .post(dest_url, body)
      .then((res) => {
        console.log(res);
        setResult({ success: 1, failure: 0 });
      })
      .catch((err) => {
        console.log(err);
        setResult({ success: 0, failure: 1 });
      })
      .finally(() => {
        setLoading(false);
        setSelectedPic(null);
        setAllowUpload(false);
        setData(emptyFormData);
      });
  };

  const onSelect = (picture) => {
    const validExtensions = [".jpg", ".jpeg", ".png"];
    const fileExtension = picture[0].name.split(".").pop().toLowerCase();
    if (validExtensions.includes(`.${fileExtension}`)) {
      setSelectedPic(picture[0]);
      setPictureError(null);
      setAllowUpload(true);
    } else {
      setPictureError("Invalid Image Type");
      setAllowUpload(false);
    }
  };

  return loading ? (
    <Loader active inline="centered" size="massive" />
  ) : (
    <Segment>
      <Segment>
        <Grid columns={2} padded>
          <Grid.Column padded>
            <h1>Add Alumni</h1>
            <Form onSubmit={submit} loading={loading}>
              <Form.Field
                required
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                }}
              >
                <label>Title</label>
                <Input
                  name="title"
                  value={data.title}
                  placeholder=""
                  required
                />
              </Form.Field>
              <Form.Field
                required
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                }}
              >
                <label>Description</label>
                <Input name="desc" value={data.desc} placeholder="" required />
              </Form.Field>
              <Form.Field>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose image"
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={2097152}
                  singleImage={true}
                  onChange={onSelect}
                  withPreview={allowUpload}
                  label="Allowed type is .jpg, .png, .jpeg"
                  fileSizeError="Max. File size is 2 MB"
                  fileTypeError="Invalid Image Type"
                />
              </Form.Field>
              {pictureError ? (
                <MyErrorMessage header="Error" message={pictureError} />
              ) : null}
              <Button positive type="submit" fluid disabled={!allowUpload}>
                <i className="far fa-upload"></i> Add Alumni
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
      </Segment>
      {result.success === 1 && (
        <MySuccessMessage header="Successful" message="Alumni Added" />
      )}
      {result.failure === 1 && (
        <MyErrorMessage header="Failed" message="Failed to add Alumni" />
      )}
    </Segment>
  );
};

export default AlumniCorner;
