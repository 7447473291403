import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ClearCacheProvider } from "react-clear-cache";

ReactDOM.render(
    <ClearCacheProvider auto={true}>      {/*automatically clear the browser cache whenever new build is available*/}
      <App /> 
    </ClearCacheProvider>,
  document.getElementById("root")
);



serviceWorker.unregister();
