import React, { Component } from "react";
import {
  Form,
  Loader,
  Dimmer,
  Container,
  Button,
  Header,
} from "semantic-ui-react";
import { BACKEND_URL } from "../../helpers/constants";
import Cookies from "universal-cookie";
import { withAlert } from "react-alert";
import client from "../../helpers/Client";
import { removeDoubleSlashes } from "../../helpers/urlhelper";

const cookies = new Cookies();
const token = cookies.get("token");
class Users extends Component {
  state = {
    email: "",
    user_data: "",
    loading: true,
    alternate_email: "",
    mobile: "",
    loginid: "",
    uid: "",
    activated: "",
    disabled: false,
    disabled_pass: false,
    disable_user: false,
    update_user: false,
  };
  componentDidMount() {
    this.fetch_user();
  }

  async fetch_user() {
    client
      .post("/user/alluser", {
        uid: this.props.match.params.id,
        page: 1,
      })
      .then((response) => {
        console.log(response.data);
        var result = response.data;
        this.setState({
          loading: false,
          user_data: result.response[0],
          alternate_email: result.response[0].alternate_email,
          mobile: result.response[0].mobile,
          email: result.response[0].email,
        });
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }
  forgot = () => {
    var alert = this.props.alert;
    var myHeaders = new Headers();
    this.setState({ disabled: true, disabled_pass: true });

    myHeaders.append("Authorization", `token ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", this.state.user_data.email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(removeDoubleSlashes(`${BACKEND_URL}/user/reset`), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState({ disabled: false, disabled_pass: false });

        if (result.status) {
          alert.success("Verification Link Sent Over Your Email");
        } else {
          alert.error("Failed ");
          console.log("Failed");
        }
      })
      .catch((error) => console.log("error", error));
  };
  enable = () => {
    var alert = this.props.alert;
    this.setState({ disabled: true, disabled_user: true });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", this.state.user_data.uid);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(removeDoubleSlashes(`${BACKEND_URL}/user/enableuser`), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState({ disabled: false, disabled_user: false });
        if (result.status) {
          alert.success("User Enabled.");
          this.fetch_user();
        } else {
          alert.error(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  disable = () => {
    var alert = this.props.alert;
    this.setState({ disabled: true, disabled_user: true });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", this.state.user_data.uid);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(removeDoubleSlashes(`${BACKEND_URL}/user/disableuser`), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState({ disabled: false, disabled_user: false });
        if (result.status) {
          alert.success("User Disabled.");
          this.fetch_user();
        } else {
          alert.error(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  updateuser = () => {
    var alert = this.props.alert;
    let uid = this.state.user_data.uid;
    let username = this.state.email;
    let email = this.state.email;
    let groupname = "faculty";

    if (!uid || !username || !email || !groupname) {
      alert.error("Missing Required field");
      return;
    }

    this.setState({ disabled: true, update_user: true });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `token ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", uid);
    urlencoded.append("username", username);
    urlencoded.append("email", email);
    urlencoded.append("groupname", groupname);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(removeDoubleSlashes(`${BACKEND_URL}/user/updateuser`), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState({ disabled: false, update_user: false });
        if (result.status) {
          alert.success("Info Updated");
          this.fetch_user();
        } else {
          alert.error(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    return (
      <>
        {this.state.loading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : (
          <>
            {" "}
            <br />
            <br />
            <Container>
              <Header as="h3" block>
                {this.props.match.params.id}
              </Header>
            </Container>
            <br />
            <br />
            <Container>
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Login ID"
                    value={JSON.stringify(this.state.user_data.loginid)}
                    readonly
                    disabled
                  />
                  <Form.Input
                    fluid
                    label="User ID"
                    value={this.state.user_data.uid}
                    readonly
                    disabled
                  />
                  <Form.Input
                    fluid
                    label="User Group"
                    value={this.state.user_data.groupName}
                    readonly
                    disabled
                  />
                </Form.Group>
                <Form.Field>
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                </Form.Field>
                <Button
                  loading={this.state.update_user}
                  primary
                  disabled={this.state.disabled}
                  onClick={this.updateuser}
                >
                  <i className="far fa-user"></i> Update
                </Button>
                <br />
                <br />
                <br />

                <Header as="h2">
                  <Header.Content>
                    Account Settings
                    <Header.Subheader>
                      {this.state.user_data.activated === 0 ? (
                        <Button
                          loading={this.state.disabled_user}
                          primary
                          disabled={this.state.disabled}
                          onClick={this.enable}
                        >
                          <i className="far fa-user"></i> Enable User
                        </Button>
                      ) : (
                        <>
                          <Button
                            loading={this.state.disabled_pass}
                            primary
                            disabled={this.state.disabled}
                            onClick={this.forgot}
                          >
                            <i className="fas fa-key-skeleton"></i> Reset Password
                          </Button>

                          <Button
                            loading={this.state.disabled_user}
                            primary
                            disabled={this.state.disabled}
                            onClick={this.disable}
                          >
                            <i className="far fa-user-slash"></i> Disable User
                          </Button>
                        </>
                      )}
                    </Header.Subheader>
                  </Header.Content>
                </Header>

                {/* </Header> */}
              </Form>
            </Container>
          </>
        )}
      </>
    );
  }
}

export default withAlert()(Users);
