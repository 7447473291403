import React, { useState, useEffect } from "react";
import { List, Grid, Form, Segment, Button, Loader } from "semantic-ui-react";

import client from "../../helpers/Client";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { Tag } from "antd";

const WorkExperience = () => {
  const [topic, setTopic] = useState("");
  const [member, setMember] = useState("");
  const [addingTopic, setAddingTopic] = useState(false);
  const [addingMemberShip, setAddingMemberShip] = useState(false);
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [loadingMemberShip, setLoadingMemberShip] = useState(false);
  const [topics, setTopics] = useState([]);
  const [memberShips, setMemeberShips] = useState([]);
  const [changeTopics, setChangeTopics] = useState(false);
  const [changeMemeberships, setChangeMemeberships] = useState(false);
  useEffect(() => {
    setLoadingTopic(true);
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { uid } = JwtDecode(token);
    let dest_url = "api/research?id=" + uid;

    client
      .get(dest_url)
      .then((res) => {
        console.log(res.data.result);
        setTopics(res.data.result);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingTopic(false);
      });
  }, [changeTopics]);

  useEffect(() => {
    setLoadingMemberShip(true);
    const cookies = new Cookies();
    const token = cookies.get("token");
    const { uid } = JwtDecode(token);
    let dest_url = "api/members?id=" + uid;

    client
      .get(dest_url)
      .then((res) => {
        console.log(res.data.result);
        setMemeberShips(res.data.result);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingMemberShip(false);
      });
  }, [changeMemeberships]);

  const addTopic = (topic) => {
    setAddingTopic(true);
    const dest_url = "/faculty/research_topics";
    client
      .post(dest_url, { topic })
      .then((res) => {
        console.log(res);
        setTopic("");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setChangeTopics(!changeTopics);
        setAddingTopic(false);
      });
  };
  const addMemberShip = (member) => {
    setAddingMemberShip(true);
    const dest_url = "/faculty/members";
    client
      .post(dest_url, { member })
      .then((res) => {
        console.log(res);
        setMember("");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setAddingMemberShip(false);
        setChangeMemeberships(!changeMemeberships);
      });
  };
  const delMemberShip = (member) => {
    setLoadingMemberShip(true);
    const dest_url = "faculty/members/delete";
    client
      .post(dest_url, member)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setChangeMemeberships(!changeMemeberships);
      });
  };
  const delTopic = (topic) => {
    setLoadingTopic(true);
    const dest_url = "faculty/research_topics/delete";
    client
      .post(dest_url, topic)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setChangeTopics(!changeTopics);
      });
  };
  return (
    <Segment className="col-lg-6">
      <Grid columns={2} relaxed="very" style={{ padding: 15 }} stackable  divided>
        <Grid.Column>
          <Form loading={addingTopic} onSubmit={() => addTopic(topic)}>
            <Form.Input
              label="Area of Research/Expertise"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Area of Research"
              required
            />
            <Button type="submit">Add</Button>
          </Form>
          <br />
          <br />
          {loadingTopic && <Loader active inline="centered" size="massive" />}
          {!loadingTopic && (
            <List divided verticalAlign="middle">
              {topics.map((element) => (
                <Tag
                  color="#108ee9"
                  className="mt-2"
                  closable
                  onClose={() => {
                    delTopic(element);
                  }}
                >
                  {element.topic}
                </Tag>
              ))}
            </List>
          )}
        </Grid.Column>
        <Grid.Column>
          <Grid.Column>
            <Form
              loading={addingMemberShip}
              onSubmit={() => addMemberShip(member)}
            >
              <Form.Input
                label="Membership in Professional Bodies"
                value={member}
                onChange={(e) => setMember(e.target.value)}
                placeholder="Membership in Professional Bodies"
                required
              />
              <Button type="submit">Add</Button>
            </Form>
            <br />
            <br />
          </Grid.Column>

          {loadingMemberShip && (
            <Loader active inline="centered" size="massive" />
          )}
          {!loadingMemberShip && (
            <List divided verticalAlign="middle">
              {memberShips.map((element) => (
                <Tag
                  color="#108ee9"
                  closable
                  className="mt-2"
                  onClose={() => {
                    delMemberShip(element);
                  }}
                >
                  {element.member}
                </Tag>
              ))}
            </List>
          )}
        </Grid.Column>
      </Grid>{" "}
    </Segment>
  );
};

export default WorkExperience;
