import React, { useState } from 'react';
import {
    Button,
    Form,
    Grid,
    Header,
    Segment,
    Dimmer,
    Loader,
    // Responsive
} from 'semantic-ui-react';
import { useParams, useHistory } from "react-router-dom";
import { BACKEND_URL } from '../../helpers/constants';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAlert, positions } from 'react-alert'
import JwtDecode from 'jwt-decode';
import { removeDoubleSlashes } from '../../helpers/urlhelper';

const cookies = new Cookies();
function LoginForm() {
    const alert = useAlert()
    let { token } = useParams();
    const history = useHistory();

    // state for login form
    const [form, setState] = useState({
        password2: '',
        password: '',
    });
    // state for loader
    const [loader, isloading] = useState(false);

    // handling form data on submission
    const HandleValues = async (e) => {
        e.preventDefault();

        if (form.password2 && form.password) {
            if (form.password2 === form.password) {
                isloading(true);
                try {
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                    var urlencoded = new URLSearchParams();
                    urlencoded.append("password", form.password);
                    urlencoded.append("token", token);
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                    };
                    fetch(removeDoubleSlashes(`${BACKEND_URL}/user/verification`), requestOptions)
                        .then(response => response.json())
                        .then(response => {
                            console.log(response)
                            if (response.status === 1) {
                                isloading(false)
                                alert.success(response.message, { position: positions.MIDDLE })
                                alert.success("Please Login", { position: positions.BOTTOM_CENTER })
                                history.push("/");
                            } else {
                                isloading(false)
                                alert.error(response.message, { position: positions.MIDDLE })
                            }
                        })
                        .catch(error => console.log('error', error));
                } catch (err) {
                    isloading(false);
                    console.log(err);
                    alert.error('Login Failed')
                }
            } else {
                alert.error('Password Doesn\'t Matches')
            }
        } else {
            alert.error('All fields are Required')
        }
    };

    // update form data
    const updateField = (e) => {
        // console.log(e.target.name + ' ' + e.target.value);
        setState({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    if (loader === true && cookies.get('token') === null)
        return (
            <Dimmer active>
                <Loader />
            </Dimmer>
        );
    else if (loader === false && cookies.get('token') != null) {
        console.log(form);
        var { groupName } = JwtDecode(cookies.get('token'));
        return <Redirect to={{ pathname: `/${groupName}` }} />;
    }

    else {
        return (
            <Grid
                textAlign="center"
                style={{ height: '100vh', padding: '1rem' }}
                verticalAlign="middle"
            >
                <Grid.Column style={{ maxWidth: 400 }}>
                    <Header as="h2" color="teal" textAlign="center">
                        Create a strong password
          </Header>

                    <Form size="large">
                        <Segment stacked>
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Password"
                                value={form.username}
                                name="password2"
                                type="password"
                                onChange={updateField}
                            />

                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Retype Password"
                                type="password"
                                value={form.password}
                                name="password"
                                onChange={updateField}
                            />

                            <Button color="teal" fluid size="large" onClick={HandleValues}>
                                Login
              </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default LoginForm;
