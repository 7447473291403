import JoditEditor from "jodit-react";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonContent,
  Form,
  Icon,
  Input,
  Loader,
  Message,
} from "semantic-ui-react";
import client from "../../helpers/Client";
import { Box } from "@material-ui/core";

const OurAchievers = () => {
  const [loading, setloading] = useState(false);
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [flag, setFlag] = useState(false);
  const [curImage, setCurImage] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(curImage);
  };
  const MySuccessMessage = ({ header, message }) => (
    <Message positive>
      <Message.Header>{header}</Message.Header>
      <p>{message}</p>
    </Message>
  );

  const handleSubmit = async () => {
    //call api to store content in database
    // setloading(true);
    let dest_url = `https://nitjsr.ac.in/backend/api/ourachievers/add`;
    const body = { content };
    // Check if table has data  at id one then update else insert
    // Get data from table at id one else inserts

    if (flag) {
      dest_url = `https://nitjsr.ac.in/backend/api/ourachievers/update`;

      await client
        .put(dest_url, body)
        .then((res) => {
          <MySuccessMessage header="Success" message="Content Updated" />;
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      await client
        .post(dest_url, body)
        .then((res) => {
          console.log(res.data);
          <MySuccessMessage header="Success" message="Content Added" />;
        })
        .catch((err) => console.log(err));
    }
  };
  const handleImageSubmit = async () => {
    //call api to store content in database
    const dest = `/api/ourachievers/image-upload`;
    console.log(image);
    const body = new FormData();
    body.append("file", image);
    const data = await client.post(dest, body);
    console.log(data.data.path);
    setCurImage("https://www.nitjsr.ac.in/backend/" + data.data.path);
    return data.data.path;
  };
  const handleIncomingData = async () => {
    // Check if table has data  at id one then update else insert
    // Get data from table at id one else insert
    await client
      .get("/api/ourachievers/get")
      .then((res) => {
        if (res.data) {
          // console.log(res.data.data[0].content);
          setContent(res.data.data[0].content);
          setFlag(true);
        } else {
          setContent("");
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setloading(true);
    handleIncomingData();
    setloading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader active inline="centered" size="massive" />
      ) : (
        <div>
          <h1>Add Achievers</h1>
          <JoditEditor
            name="content"
            value={content}
            tabIndex={1} // tabIndex of textarea
            onChange={(newContent) => setContent(newContent)}
          />
          <Button fluid primary size="big" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
          <Form onSubmit={handleImageSubmit}>
            <Form.Field
              required
              onChange={(e) => {
                // console.log(e.target.files[0])
                let file_type = e.target.files[0].type;
                if (
                  file_type === "image/jpeg" ||
                  file_type === "image/jpg" ||
                  file_type === "image/png"
                ) {
                  // setData({ ...data, [e.target.name]: e.target.files[0] });
                  setImage(e.target.files[0]);
                  // console.log(e.target.files[0]);
                } else
                  alert.error(
                    "The Event Image should end with jpg/jpeg/png extension"
                  );
              }}
            >
              <label>Upload Image</label>
              <Input
                type="file"
                name="image"
                // value={image}
                placeholder="Event Image"
              />
            </Form.Field>
            <Box display={"flex"} flexDirection={"row"}>
              <Button fluid primary size="big" type="submit">
                Upload
              </Button>
              <Button onClick={handleCopy}>
                <ButtonContent visible>
                  <Icon name="clipboard" />
                </ButtonContent>
              </Button>
            </Box>
          </Form>
        </div>
      )}
    </>
  );
};

export default OurAchievers;
