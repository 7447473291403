import React, { useEffect } from "react";
import MaterialTable from "material-table";
import client from "../../helpers/Client";
import {
  Search,
  Check,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  FilterList,
  Remove,
  Add,
  Edit,
  Delete,
  Clear,
} from "@material-ui/icons";
import { Spin } from "antd";

export const PhoneBook = () => {
  let data = [];
  const [state, setState] = React.useState({
    columns: [
      { title: "user_id", field: "USER_ID" },
      { title: "Prename", field: "prename" },
      { title: "Name", field: "fname" },
      { title: "Surname", field: "lname" },
      { title: "Department", field: "DEPT_ID" },
      { title: "Designation", field: "designation" },
      { title: "Contact", field: "contact" },
      { title: "Email", field: "email" },
    ],
    data: data,
  });

  const [refetch] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    let dest_url = "/user/phonebook";
    client
      .post(dest_url)
      .then((res) => {
        // eslint-disable-next-line
        data = res.data.response;
        setState((prevState) => {
          return { ...prevState, data };
        });
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, [refetch]);

  return loaded ? (
    <MaterialTable
      icons={{
        Check: () => <Check />,
        Export: () => <SaveAlt />,
        Filter: () => <FilterList />,
        FirstPage: () => <FirstPage />,
        LastPage: () => <LastPage />,
        NextPage: () => <ChevronRight />,
        PreviousPage: () => <ChevronLeft />,
        Search: () => <Search />,
        ThirdStateCheck: () => <Remove />,
        ViewColumn: () => <ViewColumn />,
        DetailPanel: () => <ChevronRight />,
        Add: () => <Add />,
        Edit: () => <Edit />,
        Delete: () => <Delete />,
        ResetSearch: () => <Clear />,
        Clear: () => <Clear />,
      }}
      title="PhoneBook"
      options={{
        pageSize: 10,
        exportButton: false,
        exportAllData: false,
      }}
      columns={state.columns}
      data={state.data}
    />
  ) : (
    <center>
      <Spin size="large" centered />
    </center>
  );
};
