import React, { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { Container, Header, Icon, Segment, Form } from "semantic-ui-react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import client from "../../helpers/Client";
import { Skeleton } from "antd";
import JwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { BACKEND_URL } from "../../helpers/constants";
import {
  MySuccessMessage,
  MyErrorMessage,
  Loading,
} from "../../components/AlertMessage";
import { removeDoubleSlashes } from "../../helpers/urlhelper";

const options = [
  { text: "Prof.", value: "Prof." },
  { text: "Dr.", value: "Dr." },
  { text: "Mr.", value: "Mr." },
  { text: "Mrs.", value: "Mrs." },
];

const branch_list = [
  { text: "Select Department", value: "" },
  { text: "Computer Applications (CA)", value: "CA" },
  { text: "Chemistry (CH)", value: "CH" },
  { text: "Civil Engineering (CE)", value: "CE" },
  { text: "Computer Science and Engineering (CS)", value: "CS" },
  { text: "Electronics and Communication Engineering (EC)", value: "EC" },
  { text: "Electrical Engineering (EE)", value: "EE" },
  { text: "Humanities, Social Sciences and Management (HU)", value: "HU" },
  { text: "Mathematics (MA)", value: "MA" },
  { text: "Mechanical Engineering (ME)", value: "ME" },
  { text: "Metallurgical and Materials Engineering (MM)", value: "MM" },
  { text: "Physics (PH)", value: "PH" },
  { text: "Production and Industrial Engineering (PI)", value: "PI" },
];

const designation_list = [
  { text: "Select Department", value: "" },
  { text: "Professor", value: "Professor" },
  { text: "Assistant Professor", value: "Assistant Professor" },
  { text: "HOD", value: "HOD" },
  { text: "Associate Professor", value: "Associate Professor" },
];

// export const MySuccessMessage = ({ header, message }) => (
//   <Message positive>
//     <Message.Header>{header}</Message.Header>
//     <p>{message}</p>
//   </Message>
// );

// export const Loading = () => (
//   <Message icon>
//     <Icon name="circle notched" loading />
//     <Message.Content>
//       <Message.Header>Just one second</Message.Header>
//       We are processing your request.
//     </Message.Content>
//   </Message>
// );

// export const MyErrorMessage = ({ header, message }) => (
//   <Message negative>
//     <Message.Header>{header}</Message.Header>
//     <p>{message}</p>
//   </Message>
// );

export const Profile = () => {
  const [result, setResult] = useState({
    success: 0,
    failure: 0,
  });
  const [message, setMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(
    "https://cdn.sstatic.net/Img/teams/teams-illo-free-sidebar-promo.svg?v=47faa659a05e"
  );
  const [attempted, setAttempt] = useState(0);

  const cookies = new Cookies();
  const token = cookies.get("token");
  const { user } = JwtDecode(token);
  const [loaded, setLoaded] = useState(false);
  const [initial, setInitial] = useState({
    pre: "",
    firstName: "",
    lastName: "",
    contact: "",
    email: user,
    fb_id: "",
    orcid_id: "",
    pw_link: "",
    publon_id: "",
    vidwan_id: "",
    scholar_link: "",
    department: "",
    designation: "",
    bio: "",
    address: "",
    landline: "",
    file: null,
  });

  useEffect(() => {
    let dest_url = "faculty/myProfile";
    client.post(dest_url).then((res) => {
      if (res.data.length > 0) {
        let {
          prename,
          department,
          fb_id,
          pw_link,
          orcid_id,
          publon_id,
          vidwan_id,
          designation,
          email,
          fname,
          landline,
          scopus_id,
          lname,
          office_add,
          phone,
          bio,
          scholar_link,
          profile,
        } = res.data[0];
        let inval = {
          pre: prename,
          firstName: fname,
          lastName: lname,
          contact: phone,
          email: email,
          department: department,
          designation: designation,
          fb_id: fb_id,
          scopus_id: scopus_id,
          publon_id: publon_id,
          vidwan_id: vidwan_id,
          pw_link: pw_link,
          orcid_id: orcid_id,
          scholar_link: scholar_link,
          bio: bio,
          address: office_add,
          landline: landline,
          file: null,
          previous_pic: profile,
        };
        //console.log(BACKEND_URL + "/" + profile);
        setSelectedImage(removeDoubleSlashes(BACKEND_URL + "/" + profile));

        setInitial(inval);
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    });
  }, []);

  const validationSchema = Yup.object({
    pre: Yup.string().required("Required Field"),
    firstName: Yup.string().required("Required Field"),
    lastName: Yup.string().required("Required Field"),
    contact: Yup.string()
      .matches("[1-9][0-9]{9}", "Number should have 10 digits")
      .max(13)
      .required("Contact Should not be left Empty"),
    email: Yup.string().email("Not valid email").required("Required"),
    scholar_link: Yup.string().nullable(),
    fb_id: Yup.string().nullable(),
    scopus_id: Yup.string().required("Required").nullable(),
    orcid_id: Yup.string().required("Required").nullable(),
    bio: Yup.string().required("Required").nullable(),
    publon_id: Yup.string().required("Required").nullable(),
    vidwan_id: Yup.string().required("Required").nullable(),
    department: Yup.string().required("Required Field"),
    designation: Yup.string().required("Required Field"),
    address: Yup.string().required("Address is required"),
  });

  const updateProfile = async (formData) => {
    let dest_url = "/faculty/profile";
    let body = new FormData();
    body.append("prename", formData.pre);
    body.append("fname", formData.firstName);
    body.append("lname", formData.lastName);
    body.append("department", formData.department);
    body.append("designation", formData.designation);
    body.append("contact", formData.contact);
    body.append("email", formData.email);
    body.append("landline", formData.landline);
    body.append("address", formData.address);
    body.append("fb_id", formData.fb_id);
    body.append("scopus_id", formData.scopus_id);
    body.append("orcid_id", formData.orcid_id);
    body.append("publon_id", formData.publon_id);
    body.append("vidwan_id", formData.vidwan_id);
    body.append("pw_link", formData.pw_link);
    body.append("bio", formData.bio);
    body.append("scholar_link", formData.scholar_link);
    body.append("file", formData["file"]);

    setAttempt(1);
    const data = await client.post(dest_url, body);
    // console.log(data.data.status)
    setAttempt(0);
    if (data.data.status === 0)
      setResult({
        failure: 1,
        success: 0,
      });
    else
      setResult({
        success: 1,
        failure: 0,
      });
    setMessage(data.data.message);
  };

  const fileInputRef = React.useRef();
  return (
    <Container>
      <Segment>
        <Header as="h2">
          <Icon name="address book" />
          <Header.Content>
            Profile Upload
            <Header.Subheader>Upload your Info...</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      {loaded ? (
        <Formik
          enableReinitialize={true}
          initialValues={initial}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            updateProfile(data);
            setSubmitting(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Segment>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label="Initials"
                    name="pre"
                    placeholder={initial.pre}
                    // defaultValue={initial.pre}
                    options={options}
                    onChange={(e) => {
                      setFieldValue("pre", e.target.childNodes[0].textContent);
                    }}
                    required
                  />
                  <Field
                    fluid
                    name="firstName"
                    label="First name"
                    as={Form.Input}
                    required
                  />
                  <Field
                    fluid
                    label="Last name"
                    name="lastName"
                    as={Form.Input}
                    required
                  />
                </Form.Group>
                <ErrorMessage
                  name="pre"
                  header="Pre-Name"
                  message={errors.pre}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="firstName"
                  header="First-Name Error"
                  message={errors.firstName}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="lastName"
                  header="last-Name Error"
                  message={errors.lastName}
                  component={MyErrorMessage}
                />
                <Form.Group widths="equal">
                  <Field
                    fluid
                    label="Phone"
                    name="contact"
                    as={Form.Input}
                    required
                  />
                  <Field
                    fluid
                    label="LandLine"
                    name="landline"
                    as={Form.Input}
                  />
                  <Field
                    fluid
                    label="Email"
                    name="email"
                    as={Form.Input}
                    readonly
                    // disabled
                    required
                  />
                </Form.Group>

                <ErrorMessage
                  name="contact"
                  header="Contact Error"
                  message={errors.contact}
                  component={MyErrorMessage}
                />
                {/* <Field.label>
                  <small>
                    Please Note that this email is not for login. It will be
                    visible only on your profile
                  </small>
                </Field.label> */}
                <ErrorMessage
                  name="email"
                  header="Email Error"
                  message={errors.email}
                  component={MyErrorMessage}
                />
                <Form.Group widths="equal">
                  <Field
                    fluid
                    label="Scopus Profile Link"
                    name="scopus_id"
                    as={Form.Input}
                    required
                  />
                  <Field
                    fluid
                    label="Orcid Profile Link"
                    name="orcid_id"
                    as={Form.Input}
                    required
                  />
                  <Field
                    fluid
                    label="Publon Profile Link"
                    name="publon_id"
                    as={Form.Input}
                    required
                  />
                  <Field
                    fluid
                    label="Vidwan ID"
                    name="vidwan_id"
                    as={Form.Input}
                    required
                  />
                </Form.Group>
                <ErrorMessage
                  name="orcid_id"
                  header="Orcid Profile Link is required field"
                  message={errors.orcid_id}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="scopus_id"
                  header="Scopus Profile Link is required field"
                  message={errors.scopus_id}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="publon_id"
                  header="Publon id is required field"
                  message={errors.publon_id}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="vidwan_id"
                  header="Vidwan ID is required field"
                  message={errors.vidwan_id}
                  component={MyErrorMessage}
                />
                <Form.Group widths="equal">
                  <Field
                    fluid
                    label="Personal Website Link"
                    name="pw_link"
                    placeholder="Non-Compulsory"
                    as={Form.Input}
                  />
                  <Field
                    fluid
                    label="Google Scholar Link"
                    name="scholar_link"
                    as={Form.Input}
                  />
                  <Field
                    fluid
                    label="Facebook Profile Link"
                    name="fb_id"
                    as={Form.Input}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  {/* <small>Selected Department: {initial.department}</small> */}
                  <Form.Select
                    fluid
                    label={"Select Department"}
                    name="department"
                    options={branch_list}
                    onChange={(e, selected) => {
                      setFieldValue("department", selected.value);
                    }}
                    defaultValue={initial.department}
                    required
                  />
                  {/* <small>Selected Department: {initial.designation}</small> */}
                  <Form.Select
                    fluid
                    label="Designation"
                    name="designation"
                    options={designation_list}
                    onChange={(e, selected) => {
                      setFieldValue("designation", selected.value);
                    }}
                    defaultValue={initial.designation}
                    required
                  />
                </Form.Group>
                <Field
                  label="Office Address"
                  placeholder="Address"
                  name="address"
                  as={Form.TextArea}
                  required
                />
                <ErrorMessage
                  name="address"
                  header="Office Address Is Required"
                  message={errors.address}
                  component={MyErrorMessage}
                />
                <span>
                  Bio <span style={{ color: "red" }}>*</span>
                </span>
                <JoditEditor
                  name="bio"
                  value={values.bio}
                  tabIndex={1} // tabIndex of textarea
                  onChange={(newContent) => {
                    setFieldValue("bio", newContent);
                  }}
                  required
                />
                <ErrorMessage
                  name="bio"
                  header="Bio Is Required"
                  message={errors.bio}
                  component={MyErrorMessage}
                />
                <br />
                <label>
                  Upload a Photo
                  <small>
                    <b style={{ color: "red" }}> (Must be in Square size)</b>
                  </small>
                </label>
                <Segment placeholder>
                  <Header icon>
                    {initial.previous_pic ? (
                      <img
                        src={selectedImage}
                        alt="Profile Pic"
                        style={{ width: "200px" }}
                      />
                    ) : (
                      <Icon name="user" />
                    )}

                    {values.file || initial.previous_pic
                      ? ""
                      : "No pic is selected."}
                  </Header>
                  <Form.Button
                    primary
                    type="button"
                    onClick={() => fileInputRef.current.click()}
                  >
                    {values.file || initial.previous_pic
                      ? "Change Photo"
                      : "Upload Profile"}
                  </Form.Button>
                  <input
                    ref={fileInputRef}
                    hidden
                    type="file"
                    name="file"
                    id="file"
                    accept="image/*"
                    onChange={(event) => {
                      var selected_files = event.currentTarget.files;
                      if (selected_files.length) {
                        var reader = new FileReader();
                        reader.readAsDataURL(selected_files[0]);

                        reader.onloadend = function (e) {
                          //console.log("READER IS " + reader.result);
                          setSelectedImage(removeDoubleSlashes(reader.result));
                          //console.log("selected image is" + selected_files[0]);
                        }.bind();
                        console.log("selected file is " + selected_files[0]);
                        setFieldValue("file", selected_files[0]);
                      }
                    }}
                  />
                </Segment>

                <ErrorMessage
                  name="department"
                  header="Department Field Error"
                  message={errors.department}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="designation"
                  header="Designation Field Error"
                  message={errors.designation}
                  component={MyErrorMessage}
                />
                <ErrorMessage
                  name="address"
                  header="Addess Field Error"
                  message={errors.address}
                  component={MyErrorMessage}
                />
              </Segment>
              {attempted ? <Loading /> : null}
              {result.success ? (
                <MySuccessMessage header="Profile" message={message} />
              ) : null}
              {result.failure ? (
                <MyErrorMessage header="Profile" message={message} />
              ) : null}
              <Segment>
                <Form.Button
                  fluid
                  primary
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update Profile
                </Form.Button>
              </Segment>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </Container>
  );
};
