import React, { useRef, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Loader, Button } from "semantic-ui-react";
import client from "../../helpers/Client";

export const HighlightedNotice = () => {
  const [content, setContent] = useState("");
  const [loading, setloading] = useState();
  const editor = useRef(null);

  useEffect(() => {
    let dest_url = "/api/highlightednotices";
    setloading(true);
    client
      .get(dest_url)
      .then((res) => {
        setContent(res.data.results);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setloading(false);
      });

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    //call api to store content in database
    setloading(true);
    let dest_url = `/user/highlightednotices`;
    const body = { content };
    await client
      .post(dest_url, body)
      .then((res) => {
        console.log(res);
        alert(res.data.message);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Loader active inline="centered" size="massive" />
      ) : (
        <>
          <JoditEditor
            ref={editor}
            value={content}
            tabIndex={1} // tabIndex of textarea
            onChange={(newContent) => setContent(newContent)}
          />

          <Button fluid primary size="big" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </>
      )}
    </>
  );
};
