import React, { useState } from "react";
import clsx from "clsx";
import { Button, Col, Modal } from "antd";
import moment from "moment";

import CssBaseline from "@material-ui/core/CssBaseline";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import useStyles from "./Style";
import Cookies from "universal-cookie";
import JwtDecode from "jwt-decode";
import { Collapse } from "antd";

import { useLocation, Link } from "react-router-dom";
import client from "../../helpers/Client";
import { Loader } from "semantic-ui-react";

const cookies = new Cookies();
const { Panel } = Collapse;

const Header = ({ drawerOpen, open }) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);

  let header_value = useLocation().pathname.split("/")[2];

  if (header_value === undefined || header_value === "")
    header_value = "DashBoard";

  let header_value_array = header_value.split("");
  if (header_value_array !== undefined) {
    header_value_array[0] = header_value_array[0].toUpperCase();
    header_value = header_value_array.join("");
  }

  let { user, session_id, groupName } = JwtDecode(cookies.get("token"));

  const handleAllLogout = () => {
    let dest_url = `/api/sessions/deleteAll`;
    client
      .delete(dest_url, {
        data: {
          user,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          cookies.remove("token");
          setTimeout(() => {
            window.location = "/";
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setVisible(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    let dest_url = `/api/sessions/deleteOne`;
    client
      .delete(dest_url, {
        data: {
          user,
          session_id,
        },
      })
      .then((res) => {
        console.log(res.data);
        cookies.remove("token");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          window.location = "/";
        }, 200);
        setVisible(false);
      });
  };

  const showModal = () => {
    setVisible(true);
    setLoading(true);

    let dest_url = "/api/sessions/all";
    client
      .post(dest_url, {
        user,
      })
      .then((res) => {
        console.log(res.data.result);
        setSessions(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = (session) => {
    let dest_url = "/api/sessions/deleteOne";
    client
      .delete(dest_url, {
        data: {
          user: user,
          session_id: session.session_id,
        },
      })
      .then(() => {
        showModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const classes = useStyles();


  const AllSessionModel=
  <Modal
    title="Do You really want to logout?"
    zIndex={999999}
    visible={visible}
    okText="Logout From this device"
    onOk={handleOk}
    width={600}
    footer={
      <Button type="primary" onClick={handleOk}>
        Logout From This Device
      </Button>
    }
    maskClosable={true}
    cancelText="Cancel"
    onCancel={handleCancel}
    cancelButtonProps={{ type: "primary" }}
  >
    <strong>Active Session(s)</strong>
    {loading && <Loader active inline="centered" />}
    {!loading && sessions.length && (
      <>
        <Button
          onClick={handleAllLogout}
          type="danger"
          style={{ float: "right" }}
        >
          <i className="fad fa-power-off"></i>&nbsp; Logout From All
        </Button>
        <br />
        <br />
        <Collapse defaultActiveKey={[session_id]}>
          {sessions.map((session) =>
            session.session_id !== session_id ? (
              <>
                <Panel header={session.device} key={session.session_id}>
                  <p>
                    <strong>IP:</strong> {session.ip}
                    <br />
                    <strong>Login Time:</strong>{" "}
                    {moment(session.timestamp).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                  <Col className="gutter-row" span={4}>
                    <Button
                      type="primary"
                      onClick={() => handleLogout(session)}
                    >
                      logout
                    </Button>
                  </Col>
                </Panel>
                <hr />
              </>
            ) : (
              <>
                <Panel
                  header={session.device + " 🟢"}
                  key={session.session_id}
                >
                  <p>
                    <strong>IP:</strong> {session.ip}
                    <br />
                    <strong>Login Time:</strong>{" "}
                    {moment(session.timestamp).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                  <Col className="gutter-row" span={4}>
                    <Button type="primary" onClick={handleOk}>
                      Logout From This Device
                    </Button>
                  </Col>
                </Panel>
                <hr />
              </>
            )
          )}
        </Collapse>
      </>
    )}
  </Modal>
  return (
    <>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={drawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {header_value}
          </Typography>
          {
            groupName === "faculty" &&
             (<Link to="/faculty/changePassword">
                <SettingsIcon />
              </Link>)
          }
          <IconButton
            color="inherit"
            onClick={showModal}
          >
            <Badge color="secondary">
              <i className="fad fa-power-off"></i>
            </Badge>
          </IconButton>
        </Toolbar>
        {AllSessionModel}
      </AppBar>
    </>
  );
};

export default Header;
