import React from "react";
import Box from "@material-ui/core/Box";

import { Redirect } from "react-router-dom";
import AdminHome from "./AdminHome";
import { Switch, Route } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import JwtDecode from "jwt-decode";
import useStyles from "./Style";
import Header from "../../components/Header/Header";
import Copyright from "../../components/Copyright/Copyright";
import Cookies from "universal-cookie";
import Notice from "./Notification";
import Users from "./Users";
import Dean from "./Dean";
import Log from "./Log";
import Hod from "./Hod";
import Gallery from "./Gallery/Gallery";
import Testimonial from "./Testimonial";
import { PhoneBook } from "./PhoneBook";
import { AddPhoneBook } from "./AddPhoneBook";
import AddUser from "./AddUser";
import Viewuser from "./Viewuser";
import { TimeTable } from "./TimeTable";
import { Tender } from "./Tender";
import { Event } from "./Event";
import { DepartmentNotices } from "./Department_Notices";
import { Dean_Office_Notices } from "./Dean_Office_Notices";
import Calendar from "./Calendar";
import Mou from "./Mou";
import { Banner } from "./Banner";
import ResearchProjects from "./ResearchProjects";
import { Manual_Forms } from "./Manuals_Forms";
import Placement from "./tapCell";
import Archives from "./Archives";
import { HighlightedNotice } from "./Highlighted_Notices";
import OurAchievers from "./OurAchievers";

import AlumniPublication from "./AlumniPublication";
import DebarredAgencies from "./DebarredAgencies";
import IKS from "./IKS";

import MediaPublication from "./MediaPublication";

import AlumuniCorner from "./AlumuniCorner";

const cookies = new Cookies();

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!cookies.get("token")) {
    return <Redirect to="/" />;
  }
  var { groupName } = JwtDecode(cookies.get("token"));
  if (groupName === "faculty") {
    return <Redirect to="/faculty" />;
  }

  return (
    <div className={classes.root}>
      <Header drawerOpen={handleDrawerOpen} open={open} />
      <Sidebar close={handleDrawerClose} open={open} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path="/admin/" component={() => <AdminHome />} />
          <Route path="/admin/second/" component={Notice} />
          <Route
            path="/admin/highlighted_Notices/"
            component={HighlightedNotice}
          />
          <Route path="/admin/OurAchievers/" component={OurAchievers} />
          <Route path="/admin/AlumuniCorner/" component={AlumuniCorner} />

          <Route path="/admin/tender/" component={Tender} />
          <Route path="/admin/banner/" component={Banner} />
          <Route exact path="/admin/users/" component={Users} />
          <Route path="/admin/phonebook" component={PhoneBook} />
          <Route path="/admin/addUser" component={AddUser} />
          <Route path="/admin/phonebook/add" component={AddPhoneBook} />
          <Route exact path="/admin/users/:id" component={Viewuser} />
          <Route exact path="/admin/dean" component={Dean} />
          <Route exact path="/admin/hod" component={Hod} />
          <Route exact path="/admin/logs" component={Log} />
          <Route exact path="/admin/uploads" component={TimeTable} />
          <Route exact path="/admin/events" component={Event} />
          <Route
            exact
            path="/admin/department_Notice"
            component={DepartmentNotices}
          />
          <Route
            exact
            path="/admin/dean_office_notice"
            component={Dean_Office_Notices}
          />
          <Route exact path="/admin/gallery" component={Gallery} />
          <Route exact path="/admin/testimonial" component={Testimonial} />
          <Route exact path="/admin/mou" component={Mou} />
          <Route exact path="/admin/calendar" component={Calendar} />
          <Route
            exact
            path="/admin/alumniPublications"
            component={AlumniPublication}
          />
          <Route
            exact
            path="/admin/mediaPublications"
            component={MediaPublication}
          />
          <Route
            exact
            path="/admin/debarredAgencies"
            component={DebarredAgencies}
          />
          <Route exact path="/admin/projects" component={ResearchProjects} />
          <Route exact path="/admin/manuals_forms" component={Manual_Forms} />
          <Route exact path="/admin/tap" component={Placement} />
          <Route exact path="/admin/archives" component={Archives} />
          <Route exact path="/admin/iks" component={IKS} />
        </Switch>

        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}
