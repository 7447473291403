import styled from 'styled-components';

export const Img = styled.img`
    max-height:256px;
    max-width:100%;
    vertical-align: middle;
    border-style: solid;
    border-radius: 2px;
    border-width: 0px;
`;

export const ImageWrapper = styled.div`
    max-width:100%;
    max-height:256px;
    text-align:center;
    padding:8px;
`;