import {
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { useMemberStyles } from "./styles";
import { Cancel } from "@material-ui/icons";
import {
  Branches,
  data,
  memberValidatioSchema,
  TapRoles,
  addNewMember,
  editMember,
} from "./logic";
import { useFormik } from "formik";

/**
 *
 * @param {*} param0
 * @returns
 */
const AddMember = ({ handleClose, dataHandler, initValue, ...props }) => {
  const classes = useMemberStyles();
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: memberValidatioSchema,
    onSubmit: (values) => {
      const { email, phone, role, branch, name, course, id } = values;
      if (id === 0) {
        /**
         * save
         */
        addNewMember(
          { email, phone, role, branch, name, course },
          dataHandler,
          handleClose,
          setSaving
        );
      } else {
        editMember(
          { email, phone, role, branch, name, course, id },
          dataHandler,
          handleClose,
          setSaving
        );
      }
    },
  });
  console.log(formik.errors);
  return (
    <div className={classes.paper}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="row" justify="space-between">
          <Grid xs={9} item>
            <Typography variant="h5">Add new TAP member</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={saving}
              onClick={handleClose}
              color="secondary"
              size="small"
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              label="Name"
              error={formik.errors.name}
              helperText={formik.errors.name}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              label="Email"
              error={formik.errors.email}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
              label="Phone"
              error={formik.errors.phone}
              helperText={formik.errors.phone}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="row">
              <Grid xs={4} item>
                <FormControl fullWidth>
                  <InputLabel id="member-role-id">Role</InputLabel>
                  <Select
                    value={formik.values.role}
                    name="role"
                    onChange={formik.handleChange}
                    labelId="member-role-id"
                    error={formik.errors.role}
                    fullWidth
                  >
                    {data.role.map((c, i) => (
                      <MenuItem key={i} value={c}>
                        {TapRoles[c]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {formik.values.role === "TCC" && (
                <Grid xs={4} item>
                  <FormControl fullWidth>
                    <InputLabel id="member-course-id">Course</InputLabel>
                    <Select
                      value={formik.values.course}
                      name="course"
                      onChange={formik.handleChange}
                      labelId="member-course-id"
                      error={formik.errors.course}
                      fullWidth
                    >
                      {data.course.map((c, i) => (
                        <MenuItem key={i} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {formik.values.course !== "NA" && (
                <Grid xs={4} item>
                  <FormControl fullWidth>
                    <InputLabel id="member-branch-id">Branch</InputLabel>
                    <Select
                      value={formik.values.branch}
                      name="branch"
                      onChange={formik.handleChange}
                      labelId="member-branch-id"
                      error={formik.errors.branch}
                      fullWidth
                    >
                      {Branches[formik.values.course]?.map((c, i) => (
                        <MenuItem key={i} value={c.key}>
                          {c.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="center">
              <Grid item>
                <Button
                  disabled={saving}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {formik.values.id === 0 ? "Save" : "Edit"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddMember;
