import React, { useState } from 'react'
import { Button, Form } from 'semantic-ui-react';
import { useParams, useHistory } from "react-router-dom";
import { BACKEND_URL } from '../../helpers/constants';
import { useAlert, positions } from 'react-alert'
import { removeDoubleSlashes } from '../../helpers/urlhelper';


var Reset = () => {
    const [loader, isloading] = useState(false);
    let { token } = useParams();
    const alert = useAlert()
    const history = useHistory();
    const [pass, setPass] = useState(0);
    const [pass2, setPass2] = useState(0);
    return (<>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="ui cards centered">
            <div className="card">
                <div className="content">
                    <div className="header">
                        NIT Jamshedpur Web Portal
                            </div>
                    <div className="description">
                        <Form.Input fluid icon='key' iconPosition='left' disabled={loader} placeholder='Password' type='password' onChange={(e) => { setPass(e.target.value) }} />
                        <br />
                        <Form.Input fluid icon='key' iconPosition='left' disabled={loader} placeholder='Retype Password' type='password' onChange={(e) => { setPass2(e.target.value) }} />
                    </div>
                </div>
                <div className="extra content">
                    <div className="ui two buttons">
                        <Button positive icon='key'
                            disabled={loader}
                            Loader={loader}
                            onClick={(data, { setSubmitting }) => {
                                if (pass!=="" && pass.length>5 &&pass===pass2) {
                                    isloading(true)
                                    console.log(pass);
                                    console.log(pass2);
                                    var myHeaders = new Headers();
                                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                                    var urlencoded = new URLSearchParams();
                                    urlencoded.append("token", token);
                                    urlencoded.append("password", pass);
                                    var requestOptions = {
                                        method: 'POST',
                                        headers: myHeaders,
                                        body: urlencoded,
                                        redirect: 'follow'
                                    };
                                    fetch(removeDoubleSlashes(`${BACKEND_URL}/user/change`), requestOptions)
                                        .then(response => response.json())
                                        .then(response => {
                                            console.log(response)
                                            if (response.status === 1) {
                                                isloading(false)
                                                alert.success(response.message)
                                                alert.success("Please Login")
                                                history.push("/");
                                            } else {
                                                isloading(false)
                                                alert.error(response.message, { position: positions.MIDDLE })
                                            }
                                        })
                                        .catch(error => console.log('error', error));
                                }
                                }
                            }
                        >Change Password</Button>
                    </div>
                </div>
            </div>
        </div>
    </>)

}

export default Reset