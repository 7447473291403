import React, { useState, useEffect } from "react";
import {
  Segment,
  Form,
  Grid,
  Input,
  Button,
  Card,
  Loader,
} from "semantic-ui-react";

import client from "../../helpers/Client";

const emptyFormData = {
  title: "",
  mou: "",
};
function Mou() {
  const [data, setData] = useState(emptyFormData);
  const [mou, setMou] = useState([]);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setFetching(true);
    let dest_url = `/api/mou`;

    client
      .get(dest_url)
      .then((res) => {
        setMou(res.data.data);
        // console.log("mou data", res.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setFetching(false);
      });
  }, [request]);

  const deleteMou = (id) => {
    let dest_url = `/user/mou/delete`;

    console.log("Mou", data);
    client
      .post(dest_url, { id: id })
      .then((res) => {
        console.log(res);
        setRequest(!request);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
    console.log(data);
  };

  const submit = (data) => {
    setLoading(true);

    let dest_url = `/user/mou`;

    const body = new FormData();

    console.log({ data: data });

    body.append("title", data.title);
    body.append("file", data["mou"]);

    // console.log("on submit", data);
    client
      .post(dest_url, body)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
        setRequest(!request);
      });
  };

  return (
    <Segment>
      <Segment>
        <Grid columns={2} padded>
          <Grid.Column padded>
            <h1>Mou Upload</h1>
            <Form onSubmit={() => submit(data)} loading={loading}>
              <Form.Field
                required
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.value });
                }}
              >
                <label>Title</label>
                <Input
                  name="title"
                  value={data.title}
                  placeholder=""
                  required
                />
              </Form.Field>
              <Form.Field
                onChange={(e) => {
                  setData({ ...data, [e.target.name]: e.target.files[0] });
                  console.log("file mou", e.target.files[0]);
                }}
              >
                <label>File</label>
                <Input type="file" name="mou" placeholder="pdf" required />
              </Form.Field>

              <Button positive type="submit" fluid>
                <i className="far fa-upload"></i> Add Mou
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column>
            {fetching && <Loader active inline="centered" size="massive" />}
            {!fetching && mou && (
              <Card.Group>
                {mou.map((c) => (
                  <Card key={c.id} fluid>
                    <Card.Content>
                      <Card.Header>Mou</Card.Header>
                      <Card.Description style={{ margin: "1rem 0" }}>
                        <a rel="noreferrer" href={c.link} target="_blank">
                          {c.title}
                        </a>
                      </Card.Description>
                      <Button onClick={() => deleteMou(c.id)}>Delete</Button>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  );
}

export default Mou;
