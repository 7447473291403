import React from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import { NavLink } from "react-router-dom";
import useStyles from "./Styles";

//responsive drawer
const showItems = window.innerWidth < 480 ? "temporary" : "permanent";

export const mainListItems = (
  <div>
    <NavLink to="/admin/">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-home"></i>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/second">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-clipboard"></i>
        </ListItemIcon>
        <ListItemText primary="Notice" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/highlighted_Notices/">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-clipboard"></i>
        </ListItemIcon>
        <ListItemText primary="Highlighted_Notices" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/dean_office_notice">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-clipboard"></i>
        </ListItemIcon>
        <ListItemText primary="Dean_Office_Notices" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/department_Notice">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-clipboard"></i>
        </ListItemIcon>
        <ListItemText primary="Department_Notices" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/uploads">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Upload" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/gallery">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-image"></i>
        </ListItemIcon>
        <ListItemText primary="Gallery" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/events">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Events" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/tender">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Tender" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/banner">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Banner" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/alumniPublications">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Alumni Publications" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/mediaPublications">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Media Publications" />
      </ListItem>
    </NavLink>

    <NavLink to="/admin/calendar">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-calendar-alt"></i>
        </ListItemIcon>
        <ListItemText primary="Calendar" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/users">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-users"></i>
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/phonebook">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-phone-office"></i>
        </ListItemIcon>
        <ListItemText primary="Phonebook" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/dean">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-phone-office"></i>
        </ListItemIcon>
        <ListItemText primary="Dean" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/hod">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-phone-office"></i>
        </ListItemIcon>
        <ListItemText primary="Hod" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/testimonial">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-phone-office"></i>
        </ListItemIcon>
        <ListItemText primary="Testimonial" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/projects">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-phone-office"></i>
        </ListItemIcon>
        <ListItemText primary="Research Projects" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/manuals_forms">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-upload"></i>
        </ListItemIcon>
        <ListItemText primary="Manuals & Forms" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/tap">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user-tie"></i>
        </ListItemIcon>
        <ListItemText primary="TAP Cell" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/OurAchievers/">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user-tie"></i>
        </ListItemIcon>
        <ListItemText primary="Our_Achievers" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/debarredAgencies">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user-tie"></i>
        </ListItemIcon>
        <ListItemText primary="Debarred Agencies" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/iks">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user-tie"></i>
        </ListItemIcon>
        <ListItemText primary="IKS" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/AlumuniCorner/">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user-tie"></i>
        </ListItemIcon>
        <ListItemText primary="Alumni_Corner" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/logs">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-th-list"></i>
        </ListItemIcon>
        <ListItemText primary="Logs" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/archives">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-th-list"></i>
        </ListItemIcon>
        <ListItemText primary="Archives" />
      </ListItem>
    </NavLink>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>My Account</ListSubheader>
    {/* <NavLink to="/admin/setting">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-cogs"></i>
        </ListItemIcon>
        <ListItemText primary="Setting" />
      </ListItem>
    </NavLink> */}
    <NavLink to="/admin/profile">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="My Profile" />
      </ListItem>
    </NavLink>
    <NavLink to="/admin/mylogs">
      <ListItem button>
        <ListItemIcon>
          <i className="far fa-2x fa-th-list"></i>
        </ListItemIcon>
        <ListItemText primary="My Logs" />
      </ListItem>
    </NavLink>
  </div>
);

const Sidebar = ({ close, open }) => {
  const classes = useStyles();
  return (
    <Drawer
      variant={showItems}
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={close}>
          <i className="fad fa-arrow-circle-left"></i>
        </IconButton>
      </div>
      <Divider />
      <List>{mainListItems}</List>
      <Divider />
      <List>{secondaryListItems}</List>
    </Drawer>
  );
};

export default Sidebar;
