import * as Yup from "yup";
import Client from "../../../helpers/Client";
export const TapRoles = {
  TPO: "Training and Placement Officer",
  PIC: "Professor in charge",
  TCC: "Placement Co-ordinator",
  OTH: "Other",
};

export const Branches = {
  UG: [
    { key: "NA", value: "None" },
    { key: "UGCSE", value: "Computer Science & engineering" },
    { key: "UGECE", value: "Electronics & Comm. Engineering" },
    { key: "UGEE", value: "Electrical Engineering" },
    { key: "UGME", value: "Mechanical Engineering" },
    { key: "UGCE", value: "Civil Engineering" },
    { key: "UGPI", value: "Production and Industrial Engineering" },
  ],
  PG: [
    { key: "NA", value: "None" },
    { key: "PGMCA", value: "MCA" },
    { key: "PGMSE", value: "Manufacturing and Systems Engineering" },
    { key: "PGCSE", value: "Computer Science and Engineering" },
    { key: "PGCIDM", value: "Computer Integrated Design and Manufacturing" },
    { key: "PGEM", value: "Embedded Systems" },
    { key: "PGMATH", value: "M.Sc. Mathematics" },
    { key: "PGCWRE", value: "Civil Engineering (Water Resource Engineering)" },
    { key: "PGCMS", value: "Communication System" },
    { key: "PGISSE", value: "Information Systems Security Engineering" },
    { key: "PGSE", value: "Structural Engineering" },
    { key: "PGTE", value: "Thermal Engineering" },
    { key: "PGPSE", value: "Power System Engineering" },
    { key: "PGPED", value: "Power Electronics and Drives" },
    { key: "PGGE", value: "Geotechical Engineering" },
  ],
};

export const data = {
  role: ["TPO", "PIC", "TCC", "OTH"],
  branch: [
    "NA",
    "UGCSE",
    "UGECE",
    "UGEE",
    "UGME",
    "UGCE",
    "UGPI",
    "PGMCA",
    "PGMSE",
    "PGCSE",
    "PGCIDM",
    "PGEM",
    "PGMATH",
    "PGCWRE",
    "PGCMS",
    "PGISSE",
    "PGSE",
    "PGTE",
    "PGPSE",
    "PGPED",
    "PGGE",
  ],
  course: ["PG", "UG", "NA"],
};

/**
 *
 */
export const memberValidatioSchema = Yup.object().shape({
  id: Yup.number(),
  email: Yup.string().required("Required").email("Invalid email"),
  phone: Yup.string().required("Required").min(10, "Only 10 digits allowed"),
  role: Yup.string().oneOf(data.role).required("Required"),
  branch: Yup.string().oneOf(data.branch).required("Required"),
  name: Yup.string().required("Required"),
  course: Yup.string().required("Required").oneOf(data.course),
});

/**
 *
 * @param {*} value
 * @returns
 */
export const saveTapMember = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
    } catch (error) {
      reject(error);
    }
  });
};

/**
 *
 * @param {*} handleData
 */
export const fetchTapMember = (handleData) => {
  Client.get("/api/placement/member")
    .then((res) => {
      const response = res.data;
      const { data, error, message } = response;
      if (error?.length > 0) {
        /**
         * show error toast
         */
      }
      if (message?.length > 0) {
        /**
         * show messages
         */
      }
      if (data?.length > 0) {
        handleData(data);
      }
      return;
    })
    .catch((error) => {
      console.log(error);
      return;
    });
};

/**
 *
 * @param {*} course
 * @param {*} branch
 * @returns
 */
export const getBranch = (course, branch) => {
  const brchs = Branches[course];
  const [brch] = brchs?.filter((br) => br.key === branch);
  return `${brch?.value}(${course})`;
};

/**
 *
 * @param {*} id
 * @param {*} handleData
 */
export const disableMember = (id, handleData, setCall) => (e) => {
  setCall(true);
  Client.post("/user/placement/member/disable", { id })
    .then((res) => {
      const response = res.data;
      const { error, data, message } = response;
      if (error?.length > 0) {
        /**
         *
         */
      }
      if (message?.length > 0) {
        /**
         *
         */
      }
      const [verData] = data;
      if (verData?.status) {
        handleData((pre) => {
          let newData = pre?.filter((dd) => dd?.id !== id);
          if (!newData) {
            newData = [];
          }
          return newData;
        });
      }
      return;
    })
    .catch((error) => {
      /**
       *
       */
      console.log(error);
    })
    .finally(() => {
      setCall(false);
    });
};

export const addNewMember = (
  { email, phone, role, branch, name, course, ...rest },
  dataHandler,
  handleClose,
  setSaving
) => {
  setSaving(true);
  Client.post("/user/placement/member/add", {
    email,
    phone,
    role,
    branch,
    name,
    course,
  })
    .then((res) => {
      const response = res.data;
      const { data, error, message } = response;
      if (error?.length > 0) {
        /**
         *
         */
      }
      if (message?.length > 0) {
        /**
         *
         */
      }
      dataHandler((tapData) => {
        const newData = [...tapData, ...data];
        return newData;
      });
    })
    .catch((error) => {
      /**
       *
       */
      console.log(error);
    })
    .finally(() => {
      handleClose();
      setSaving(false);
    });
};

/**
 *
 * @param {*} param0
 * @param {*} dataHandler
 * @param {*} handleClose
 * @param {*} setSaving
 */
export const editMember = (
  { email, phone, role, branch, name, course, id },
  dataHandler,
  handleClose,
  setSaving
) => {
  setSaving(true);
  Client.post("/user/placement/member/update", {
    email,
    phone,
    role,
    branch,
    name,
    course,
    id,
  })
    .then((res) => {
      const response = res.data;
      const { data, error, message } = response;
      if (error?.length > 0) {
        /**
         *
         */
      }
      if (message?.length > 0) {
        /**
         *
         */
      }
      dataHandler((prev) => {
        let newData = prev?.map((tp) => {
          let dd = tp;
          if (tp?.id === id) {
            dd = data[0];
          }
          return dd;
        });
        if (!newData) {
          newData = [];
        }
        return newData;
      });
    })
    .catch((error) => {
      /**
       * handle error
       */
      console.log(error);
    })
    .finally(() => {
      setSaving(false);
      handleClose();
    });
};

/**** STATS LOGIC  */
/**
 * ALL FUNCTION TO HANDLE DATA ON stats.js component
 */

export const YEAR = [2021, 2020, 2019, 2018, 2017];

export const fetchStats = (year, branch, setData) => {
  let url = "/api/placement/stats";
  if (year && branch) {
    url = `${url}?year=${year}`;
    if (branch !== "ALL") {
      url = `${url}&branch=${branch}`;
    }
  }

  Client.get(url)
    .then((res) => {
      const { data, error, message } = res.data;
      if (error?.length > 0) {
        /**
         *
         */
      }
      if (message?.length > 0) {
        /**
         *
         */
      }
      setData(data);
    })
    .catch((error) => {
      /**
       *
       */
      console.log(error);
    });
};

/**
 * @description validation schema for stats form
 */
export const statsValidationSchema = Yup.object().shape({
  company: Yup.array().of(Yup.string().required("Required")),
  branch: Yup.array().of(Yup.string().required("Required")),
  final: Yup.array().of(Yup.number().required("Required")),
  year: Yup.array().of(Yup.number().required("Required")),
});

/**
 *
 * @param {*} param0
 * @param {*} handleClose
 * @param {*} setSaving
 */
export const saveStats = (
  { company, branch, final, year },
  handleClose,
  setSaving,
  alert
) => {
  setSaving(true);

  if (!(company && branch && final && year)) {
    setSaving(false);
    return;
  }

  const data = company?.map((cp, idx) => ({
    company: company[idx],
    branch: branch[idx],
    final: final[idx],
    year: year[idx],
  }));

  Client.post("/user/placement/stats/add", { data })
    .then((res) => {
      const response = res.data;
      const { error, message } = response;
      if (error?.length > 0) {
        error.forEach((element) => {
          alert.error(element);
        });
      }
      if (message?.length > 0) {
        /**
         *
         */
        message.forEach((element) => {
          alert.info(element);
        });
      }
    })
    .catch((error) => {
      /**
       * handle error
       */
      alert.error("Some error occured");
    })
    .finally(() => {
      setSaving(false);
      handleClose();
    });
};
