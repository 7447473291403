import {
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React from "react";
import { useStatsStyle } from "./styles";
import { Cancel } from "@material-ui/icons";
import { Branches, saveStats, statsValidationSchema, YEAR } from "./logic";
import { useFormik } from "formik";
import { useAlert } from "react-alert";


/**
 *
 * @param {*} param0
 * @returns
 */
const StatsModal = ({ handleClose, ...props }) => {
  const classes = useStatsStyle();
  const alert = useAlert();
  const [saving, setSaving] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      company: [""],
      final: [0],
      branch: ["UGCSE"],
      year: [2021],
    },
    validationSchema: statsValidationSchema,
    onSubmit: (vals) => {
      saveStats(vals, handleClose, setSaving,alert);
    },
  });
  const addNewHandler = (e) => {
    const { company, year, final, branch } = formik.values;
    formik.setValues({
      company: [...company, ""],
      year: [...year, 2021],
      branch: [...branch, "UGCSE"],
      final: [...final, 0],
    });
  };
  return (
    <div className={classes.paper}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="row" justify="space-between">
          <Grid xs={9} item>
            <Typography variant="h5">Add new Placement stats</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={saving}
              onClick={handleClose}
              color="secondary"
              size="small"
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>
        <br />
        {formik.values.company.map((plc, idx) => (
          <Grid direction="row" spacing={1} key={idx} container>
            <Grid xs={4} item>
              <TextField
                label="Comapny"
                name={`company[${idx}]`}
                value={formik.values.company[idx]}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={2} item>
              <FormControl fullWidth>
                <InputLabel id="year-id">Year</InputLabel>
                <Select
                  fullWidth
                  value={formik.values.year[idx]}
                  name={`year[${idx}]`}
                  onChange={formik.handleChange}
                  labelId="year-id"
                >
                  {YEAR.map((crs) => (
                    <MenuItem key={crs} value={crs}>
                      {crs}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={4} item>
              <FormControl fullWidth>
                <InputLabel id="yeawr-id">Branch</InputLabel>
                <Select
                  fullWidth
                  value={formik.values.branch[idx]}
                  name={`branch[${idx}]`}
                  onChange={formik.handleChange}
                  labelId="yeawr-id"
                >
                  {Branches.UG.map((crs) => (
                    <MenuItem key={crs.key} value={crs.key}>
                      {crs.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={2}>
              <TextField
                type="number"
                label="Selects"
                name={`final[${idx}]`}
                value={formik.values.final[idx]}
                onChange={formik.handleChange}
                error={formik.errors?.final && formik?.errors?.final[idx]}
              />
            </Grid>
          </Grid>
        ))}
        <br />
        <Grid container direction="row" justify="space-evenly">
          <Grid item>
            <Button onClick={addNewHandler} color="primary" variant="outlined">
              Add new
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" disabled={saving} variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default StatsModal;
