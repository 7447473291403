import client from '../../../helpers/Client';

export const handleUpload = async (image,size,tags,{setSnackbar,setBackdrop}) => {

    setBackdrop(true);

    const dest = `/upload/uploads/${size}`;
    // console.log({image:image,size:size,dest:dest})
    let tag_str = '';
    tags.forEach(element => {
        tag_str = `${tag_str}#${element.text}`
    });
    // console.log(tag_str);
    for(let i = 0 ;i<image.length;i++){
        const body = new FormData();
        body.append('file',image[i]);
        body.append('for','GL');
        body.append('enabled','1');
        body.append('tags',tag_str);

        const data = await client.post(dest,body);

        const up_data = data.data;

        // console.log({data:data});

        
        if(up_data.status === 1){
            setSnackbar({open:true,text:'image uploaded!',type:'success'});
        }else{
            setSnackbar({open:true,text:up_data.message,type:'error'});
        }
    }
setBackdrop(false);
}

export const taggify = (tags) => {
    let newTags = [];
    tags.forEach(element => {
        newTags.push({
            id:`${element.id}`,
            text:element.text
        });
    });

    return newTags;
}

export const addTag = (state,handler,tag,setInput_tag) => (e) => {
    handler([...state,{id:tag,text:tag}]);
    document.getElementById('tags-edit-txt').value = '';
    setInput_tag('')
}

export const handleKeyPress = (state,tag,handler,setInput_tag) => (event) => {

    // console.log(event.key);
    
    if(event.key === 'Enter'){
        // alert('enter pressed')
        handler([...state,tag]);
        document.getElementById('tags-edit-txt').value = '';
        setInput_tag('')
    }

}

export const removeTag = (state,handler,index) => (event) => {
    handler([...state.slice(0,index),...state.slice(index+1)]);

}