import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Table,
  Loader,
  Dimmer,
  Container,
  // Pagination,
} from "semantic-ui-react";
import client from "../../helpers/Client";
import { NavLink, Link } from "react-router-dom";
// import { ContactSupportOutlined } from "@material-ui/icons";

const CourseCard = (props) => {
  if (props.user.activated === 1) {
    return (
      <Table.Row>
        <Table.Cell>{props.user.uid}</Table.Cell>
        <Table.Cell>{props.user.email}</Table.Cell>
        <Table.Cell>{props.user.mobile}</Table.Cell>
        <Table.Cell>
          {new Date(parseInt(props.user.modifiedAt))
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")}
        </Table.Cell>
        <Table.Cell collapsing>
          <Link to={"users/" + props.user.uid}>
            <Button>View</Button>
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  } else {
    return (
      <Table.Row error>
        <Table.Cell>{props.user.uid}</Table.Cell>
        <Table.Cell>{props.user.email}</Table.Cell>
        <Table.Cell>{props.user.mobile}</Table.Cell>
        <Table.Cell>
          {new Date(parseInt(props.user.modifiedAt))
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")}
        </Table.Cell>
        <Table.Cell collapsing>
          <Link to={"users/" + props.user.uid}>
            <Button>View</Button>
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  }
};

const User = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const [course, setCourse] = useState([]);

  useEffect(() => {
    client
      .post("/user/alluser", {
        page: curPage,
        limit: 10,
      })
      .then((res) => {
        setCourse(res.data.response);
        setLoading(false);
        setPage(res.data.totalPage);
        setCurPage(page);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  // const handleNextPage = (e, pageInfo) => {
  //   setLoading(true);
  //   setCurPage(pageInfo.activePage);
  // };

  if (loading) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  } else
    return (
      <Container>
        <br />
        <br />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell colSpan="3">
                <NavLink to="/admin/addUser">
                  <Button
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="large"
                  >
                    <Icon name="user" /> Add User
                  </Button>
                </NavLink>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>UID</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Mobile</Table.HeaderCell>
              <Table.HeaderCell>Last Modified</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {course &&
              course.map(function (Course, i) {
                return <CourseCard user={Course} key={i} />;
              })}
          </Table.Body>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "3rem 0 0 0",
          }}
        >
          {/* <Pagination
            activePage={curPage}
            onPageChange={handleNextPage}
            totalPages={page}
            ellipsisItem={null}
          /> */}
        </div>
      </Container>
    );
};

export default User;
